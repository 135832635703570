import React from 'react';
import classname from 'classnames'

import { Icon } from '../../Icons';
import CustomButton from '../../CustomButton';
import Offer from './components/offer';
import Discount from './components/discount';

import styles from './styles.module.css';

const SpecialOffer = ({ onClick }) => {

  const container = classname(styles.bgHeader, styles.container);
  const logo = classname(styles.logo, 'dn db-ns');
  const logoMobile = classname(styles.logo);
  const buttonClasses = classname("mt24 w-auto-ns w-100 black-pearl", styles.button);
  const imageDesktop = classname("dn db-ns", styles.imageSize);
  const imageMobile = classname("db mr-auto dn-ns mt12 mt0-ns", styles.imageSize);

  return (
    <section className={styles.containerBg}>
      <div className={container}>
        <div className="flex flex-column-reverse flex-row-ns mc center">
          <div className="w-60-ns w-100">
            <h1 className="ma0 pa0 db-ns dn">
              <Icon name="LogoAlgarWhite" className={logo} />
            </h1>
            <Offer />
            <Discount value={30} />
            <CustomButton
              text="Fale com consultor"
              color="yellow-gradient"
              className={buttonClasses}
              data-value="Fale Conosco Principal"
              data-tracking-id="TALK_TO_CONSULTANT"
              gtmClass="gtm-speak-to-consultant"
              onClick={onClick}
            />
          </div>
          <div className="w-40-ns w-100">
            <h1 className="ma0 pa0 dn-ns db">
              <Icon name="LogoAlgarWhite" className={logoMobile} />
            </h1>
            <img src="https://tic-cms-prod.s3.amazonaws.com/originals/lp-empreendedor-digital-header-1.png" className={imageDesktop} alt="" />
            <img src="https://tic-cms-prod.s3.amazonaws.com/originals/lp-empreendedor-digital-header-mobile-1.png" className={imageMobile} alt="" />
          </div>
        </div>
      </div>
    </section>
  )

}

export default SpecialOffer;
import * as React from "react"

function SvgSmallArea(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={120}
      height={110}
      viewBox="0 0 120 110"
      {...props}
    >
      <defs>
        <path id="small-area_svg__a" d="M0 2.463h49.636v104.478H0z" />
        <path id="small-area_svg__c" d="M1.041 2.463h47.234v104.481H1.041z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#004281"
          d="M12.241 63.954s14.28-3.18 20.319 1.223c6.035 4.406 16.575 1.225 19.057-3.67 2.48-4.895 1.858 14.685 1.858 15.418 0 .733.07 25.942.07 25.942l-34.174 2.936-7.13-4.797V63.954z"
        />
        <g transform="translate(7.742 .594)">
          <mask id="small-area_svg__b" fill="#fff">
            <use xlinkHref="#small-area_svg__a" />
          </mask>
          <path
            fill="#FFF"
            d="M41.36 100.412H8.271v-9.796h20.683v-6.53H8.27v-9.793h12.41V67.76H8.271v-9.793h20.683v-6.53H8.27v-9.793h12.41v-6.53H8.271v-9.796h20.683v-6.53H8.27V8.993h33.09v91.42zm4.138-97.95H4.137c-2.284 0-4.138 1.464-4.138 3.267v97.946c0 1.803 1.854 3.266 4.138 3.266h41.361c2.284 0 4.138-1.463 4.138-3.266V5.729c0-1.803-1.854-3.266-4.138-3.266z"
            mask="url(#small-area_svg__b)"
          />
        </g>
        <g transform="translate(65.806 .594)">
          <mask id="small-area_svg__d" fill="#fff">
            <use xlinkHref="#small-area_svg__c" />
          </mask>
          <path
            fill="#FFF"
            d="M42.445 85.065L28.796 95.81V13.596l13.607 10.743 5.833-4.604L27.554 3.409c-1.615-1.265-4.22-1.265-5.834 0L1.041 19.735l5.834 4.604 13.649-10.743v82.213L6.914 85.065 1.08 89.67l20.683 16.326c1.614 1.265 4.219 1.265 5.833 0L48.28 89.67l-5.834-4.605z"
            mask="url(#small-area_svg__d)"
          />
        </g>
        <path d="M0 0h120v110H0z" />
      </g>
    </svg>
  )
}

export default SvgSmallArea

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import _map from "lodash/map"
import { Row, Col } from "antd"

import CustomButton from "src/components/CustomButton"
import Advantage from "src/components/Advantage"
import { Icon } from "src/components/Icons"
import { PRODUCTS_DATA } from "./data"

import styles from "./product.module.css"

const BlockProducts = ({ onClick }) => {
  const listContainerClasses = classnames(
    "relative overflow-auto",
    styles.listContainer
  )
  const listClasses = classnames("pa0 white mb24 flex", styles.list)
  const descriptionClass = classnames(
    "f12 fw4 lh-copy mb16 mb24-ns mb16-l w-70 w-100-m w-100-l",
    styles.description
  )

  return _map(
    PRODUCTS_DATA,
    ({
      id,
      title,
      description,
      benefits,
      cardOptions: { showBorder, showRibbon, color },
    }) => (
      <Row
        key={id}
        gutter={{ md: 8, lg: 14, xl: 24 }}
        align="top"
        className={classnames(
          "pb32-ns bg-white br0 pl0-ns pr0-ns  papa-green relative",
          styles.productsContainer,
          showBorder && "b--solid bw2",
          color === "green" && styles.greenBorder,
          color === "blue" && styles.blueBorder,
          color === "yellow" && styles.yellowBorder
        )}
      >
        <Ribbon showRibbon={showRibbon} ribbonColor={color} />
        <Col span={24} md={{ offset: 2, span: 20 }} lg={{ offset: 2, span: 8 }}>
          <h3
            className={classnames(
              "f24 f32 fw7 color-inherit lh-title mb40 relative",
              showRibbon && "ml48 ml0-ns ml0-m ml0-l",
              styles.productTitle
            )}
          >
            {title}
          </h3>
          <p className={descriptionClass}>{description}</p>
          <Row>
            <Col span={0} lg={24}>
              <CustomButton
                chatdata={title}
                text="Fale com um consultor"
                color="supernova"
                className="mb24 te-papa-green f16 w-80 w-60-l"
                data-value={title}
                data-tracking-id="TALK_TO_CONSULTANT"
                gtmClass="gtm-speak-to-consultant"
                onClick={onClick}
              />
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          md={{ offset: 2, span: 20 }}
          lg={{ offset: 0, span: 14 }}
        >
          <div className={listContainerClasses}>
            <div className={listClasses}>
              <Benefits {...benefits} />
            </div>
          </div>
        </Col>
        <Col span={24} md={{ offset: 2, span: 12 }} lg={0}>
          <CustomButton
            chatdata={title}
            text="Fale com um consultor"
            color="supernova"
            className="mb24 w-80 w-90-m te-papa-green"
            data-value={title}
            data-tracking-id="TALK_TO_CONSULTANT"
            gtmClass="gtm-speak-to-consultant"
            onClick={onClick}
          />
        </Col>
      </Row>
    )
  )
}

const Benefits = data =>
  _map(data, ({ title, description, name, iconStyle }) => (
    <Advantage
      key={title}
      title={title}
      description={description}
      icon={<Icon name={name} style={iconStyle || { width: 52, height: 52 }} />}
    />
  ))

const Ribbon = props => {
  const { showRibbon, ribbonColor } = props
  const ribbonClasses = classnames(
    "te-papa-green",
    styles.ribbon,
    styles.ribbonTopLeft,
    ribbonColor === "green" && styles.greenRibbon,
    ribbonColor === "blue" && styles.blueRibbon,
    ribbonColor === "yellow" && styles.yellowRibbon
  )
  if (!showRibbon) {
    return null
  }
  return (
    <div className={ribbonClasses}>
      <p>
        <span className={classnames("db fw8 f18", styles.lh0, styles.mt13)}>
          NA MEDIDA
        </span>
        <span className={classnames("fw2 f12", styles.lh0)}>PRA SUA</span>
        <span className={classnames("fw8 f12", styles.lh0)}> EMPRESA</span>
      </p>
    </div>
  )
}

BlockProducts.propTypes = {
  onClick: PropTypes.func,
}

export default BlockProducts


export const CLOUD_BACKUP = 'Cloud Backup';
export const CLOUD_PHONE_PRO = 'Cloud Phone Pro';
export const OFFICE_365 = 'Office 365';
export const PROTECAO_WEB = 'Proteção Web';
export const LINK_DEDICADO = 'Link Dedicado';
export const VOZ_TOTAL = 'Voz Total';
export const SMS_SOLUTION = 'SMS Solution';
export const CONTROLE_DE_PONTO = 'Controle de Ponto';
export const OMNI_PRO = 'Omni Pro';
export const SMART_CONNECT = 'Smart Connect';
export const GESTAO_FINANCEIRA = 'Gestão financeira';
export const NOC = 'NOC';
export const ANTI_DDOS = 'Anti-DDoS';
export const CLOUD_PLUS = 'Cloud Plus';
export const SD_WAN = 'SD-WAN';
//export const INTERNET_LINK = 'Internet Link';
//export const GFO = 'Gestão Financeira Online';

//export const BLI = 'Banda Larga Ilimitada';
//export const FAST_SUPPORT = 'Fast Support';
//export const SPECIAL_OFFER = 'OFERTA IMPERDÍVEL';

export const CLOUD_BACKUP_DATA = [
  {
    name: 'SegurancaV2',
    title: 'Proteção Extra',
    description: 'Uma cópia de cada um dos seus arquivos fica criptografada nos nossos data-centers, evitando que você perca dados valiosos.',
  },
  {
    name: 'Backup',
    title: 'Backup versátil',
    description: 'Com o Cloud Backup você pode criar cópias de segurança do seu servidor, smartphones, notebooks e imagens das suas câmeras de segurança.'
  },
  {
    name: 'Economia2',
    title: 'Custos otimizados',
    description: 'O armazenamento contratado é feito de acordo com a necessidade do seu negócio. Pague em moeda local, apenas pelo que utilizar.'
  },
];

export const CLOUD_PHONE_PRO_DATA = [
  {
    name: 'GestaoUnificada',
    title: 'Gestão unificada',
    description: 'Centralize as ligações da sua empresa, gravações, acesso de relatórios e avaliações de desempenho em um só lugar.',
  },
  {
    name: 'FacilImplementacao',
    title: 'Fácil implementação',
    description: 'Da implementação à operação, tudo é simples. Com o armazenamento em nuvem, não é necessário investir em equipamentos.',
  },
  {
    name: 'NoSeuRitmo',
    title: 'No seu ritmo',
    description: 'Aumente ou diminua o número de ramais contratados a qualquer momento, de acordo com a necessidade do seu negócio.',
  }
];

export const OFFICE_365_DATA = [
  {
    name: 'Economia2',
    title: 'Mais economia',
    description: 'Com o Office 365 seu time evita despesas com deslocamentos para reuniões, telefone e viagens. Nos planos anuais, o desconto ainda é maior.',
  },
  {
    name: 'Colaborativo',
    title: 'Trabalho colaborativo',
    description: 'Seu time trabalha junto, mesmo remoto, tudo pode ser feito pela internet com compartilhamento de telas e arquivos em um ambiente totalmente seguro.',
  },
  {
    name: 'FlexibilidadeBarras',
    title: 'Flexibilidade total',
    description: 'Basta uma conexão com a internet e pronto: cada um dos seus colaboradores pode trabalhar onde quiser, com acesso a todos os seus arquivos.',
  }
];

export const PROTECAO_WEB_DATA = [
  {
    name: 'Resultados',
    title: 'Aumento de produtividade',
    description: 'Saiba como a sua equipe utiliza a internet da empresa e faça a gestão desses acessos, bloqueando sites indevidos que possam estar afetando a concentração.',
  },
  {
    name: 'Protecao',
    title: 'Mais segurança',
    description: 'A Proteção Web verifica os acessos a partir da rede da empresa e bloqueia qualquer conteúdo suspeito. Além disso, é constantemente atualizada para manter sua conexão segura.',
  },
  {
    name: 'AdequacaoLgpd',
    title: 'Adequação à LGPD',
    description: 'Com os acessos a partir da sua rede protegidos, sua empresa reduz ameaças virtuais  e garante uma melhor adequação à nova legislação que regula o uso de dados pelas empresas.',
  },
];

export const LINK_DEDICADO_DATA = [
  {
    name: 'AltaDisponibilidade',
    title: 'Alta disponibilidade',
    description: 'No Link Dedicado, 100% da velocidade contratada é entregue, o que garante mais estabilidade e maiores taxas de upload e download de arquivos.',
  },
  {
    name: 'Estabilidade',
    title: 'Maior estabilidade',
    description: 'Com uma conexão exclusiva, sua empresa tem menos chances de sofrer com falhas e instabilidade, o que afeta diretamente a produtividade dos seus funcionários.'
  },
  {
    name: 'ConexaoSegura',
    title: 'Conexão segura',
    description: 'Com um link dedicado apenas à rede da sua empresa, você minimiza ameaças virtuais, que se aproveitam de instabilidades na conexão para captura de dados e instalação de vírus.'
  },
];

export const VOZ_TOTAL_DATA = [
  {
    name: 'EconomiaV2',
    title: 'Economia',
    description: 'Independente do dia ou hora, a tarifa é única e sua empresa não paga nada por ligações entre ramais.',
  },
  {
    name: 'ProdutividadeV2',
    title: 'Aumento de produtividade',
    description: 'O Voz Total permite que seus funcionários usem o ramal de qualquer dispositivo móvel, em qualquer lugar, possibilitando uma redução significativa de chamadas perdidas.',
  },
  {
    name: 'SeuRitmo',
    title: 'No ritmo da sua empresa',
    description: 'O Voz Total acompanha o crescimento do seu negócio, sendo totalmente escalável, e possibilitando que você contrate ou reduza ramais quando precisar.',
  },
];

export const SMS_SOLUTION_DATA = [
  {
    name: 'Possibilidades',
    title: 'Várias possibilidades',
    description: 'Os seus disparos de SMS podem comunicar seus clientes sobre promoções, status de pedidos, lembretes de datas importantes, envio de códigos e também cupons de desconto.',
  },
  {
    name: 'Resultados',
    title: 'Resultados instantâneos',
    description: '98% dos SMS disparados são abertos em até 10 minutos, o que garante que seus clientes sempre vão ler o conteúdo das suas mensagens.',
  },
  {
    name: 'CustoBeneficio',
    title: 'Ótimo custo x benefício',
    description: 'O envio de SMS é a comunicação de menor custo e taxa de abertura em comparação às outras. Outra grande vantagem é que também não depende de internet.',
  }
];
export const CONTROLE_DE_PONTO_DATA = [
  {
    name: 'Mobilidade',
    title: 'Mobilidade',
    description: 'Registro de ponto de qualquer lugar, a partir do computador ou dispositivos móveis, conforme regras estabelecidas pelo gestor.',
  },
  {
    name: 'ProdutividadeSimplicidade',
    title: 'Produtividade e simplicidade',
    description: 'Interface simples e interativa, com acesso em tempo real às informações exatas de cada funcionário por meio do painel de gerenciamento.',
  },
  {
    name: 'ReducaoCustos',
    title: 'Redução de custos',
    description: 'Elimine os custos de aquisição, manutenção e atualização dos equipamentos tradicionais. Impeça atrasos que geram perda de dinheiro e produtividade.',
  }
];
export const OMNI_PRO_DATA = [
  {
    name: 'GestaoUnificada',
    title: 'Gestão unificada',
    description: 'Gerencie facilmente seus atendentes e atendimentos em tempo real de forma rápida, simples e intuitiva.',
  },
  {
    name: 'Chatbot',
    title: 'Chatbot',
    description: 'Tenha em mãos uma ferramenta que possibilita você utilizar inteligência artificial para interagir com seus clientes de forma natural e rápida.',
  },
  {
    name: 'ProdutividadeRelacionamento',
    title: 'Produtividade e relacionamento',
    description: 'Atendimento organizado, mais agilidade e relações mais próximas com seus clientes.',
  }
];
export const SMART_CONNECT_DATA = [
  {
    name: 'Wifi',
    title: 'Wi-Fi para clientes',
    description: 'Rede Wi-Fi com a cara da empresa. Conexão simples e rápida para os clientes.',
  },
  {
    name: 'DadosInsights',
    title: 'Dados e Insights',
    description: 'Análise de dados de clientes que se conectaram à rede com alta precisão. Novos insights sobre o negócio.',
  },
  {
    name: 'SolucaoIntegrada',
    title: 'Solução Integrada',
    description: 'Solução única e integrada: internet, instalação, infraestrutura e software.',
  }
];
export const GESTAO_FINANCEIRA_DATA = [
  {
    name: 'DadosInsights',
    title: 'Produtividade',
    description: 'Economia de tempo nas atividades de administração e controle na gestão da empresa por ser simples, seguro e de baixo custo.',
  },
  {
    name: 'Mobilidade',
    title: 'Mobilidade',
    description: 'Pode ser acessado de qualquer lugar, além de contar com certificado de segurança incluso no site.'
  },
  {
    name: 'SegurancaV3',
    title: 'Segurança',
    description: 'Informações armazenadas nos principais servidores do mundo com interface simples e intuitiva.'
  },
];

export const NOC_DATA = [
  {
    name: 'FlexibilidadeBarras',
    title: 'Eficiência e Estabilidade',
    description: 'Com o monitoramento de redes da Algar Telecom, você tem a tranquilidade para focar todas as suas atenções somente no seu negócio.',
  },
  {
    name: 'DadosInsights',
    title: 'Dados precisos',
    description: 'Estrutura adequada em termos de coletas de dados e apoio na tomada de decisão do cliente.'
  },
  {
    name: 'NoSeuRitmo',
    title: 'Foco na sua empresa',
    description: '“Libera” os profissionais de sua equipe de TI para focar no negócio.'
  },
];

export const ANTI_DDOS_DATA = [
  {
    name: 'SegurancaV2',
    title: 'Infraestrutura segura',
    description: 'Intercepta e mitiga as ocorrências antes que elas atinjam o ambiente protegido, como Plataformas, Sistemas, ou outra infraestrutura sujeita a ataques.',
  },
  {
    name: 'ReducaoCustos',
    title: 'Baixo custo operacional',
    description: 'O custo da solução é menor do que manter uma estrutura de profissionais especializados própria.'
  },
  {
    name: 'LegadoInformacao',
    title: 'Legado de informação',
    description: 'A solução retém os logs de alerta de alto impacto pelo período de 1 mês e histórico de tráfego por 1 ano.'
  },
];
export const CLOUD_PLUS_DATA = [
  {
    name: 'ReducaoCustos',
    title: 'Pague em Real',
    description: 'Pague apenas pelo que utilizou em moeda nacional com impostos inclusos.',
  },
  {
    name: 'Backup',
    title: 'Sem limites',
    description: 'Liberdade de utilizar nuvens distintas sem se sentir preso.',
  },
  {
    name: 'Elastiscidade',
    title: 'Elasticidade',
    description: 'Crescimento escalável de forma horizontal para fornecer disponibilidade conforme a demanda que sua organização necessita.',
  },
];
export const SD_WAN_DATA = [
  {
    name: 'Wifi',
    title: 'Wi-Fi para clientes',
    description: 'Uma cópia de cada um dos seus arquivos fica criptografada nos nossos data-centers, evitando que você perca dados valiosos.',
  },
  {
    name: 'DadosInsights',
    title: 'Dados e Insights',
    description: 'Análise de dados de clientes que se conectaram à rede com alta precisão. Novos insights sobre o negócio.'
  },
  {
    name: 'SolucaoIntegrada',
    title: 'Solução Integrada',
    description: 'Solução única e integrada: internet, instalação, infraestrutura e software.'
  },
];

/*export const SPECIAL_OFFER_DATA = [
  {
    name: 'Globe',
    title: 'Internet Fibra Algar',
    description: 'Velocidade e estabilidade para sua conexão.',
  },
  {
    name: 'Calling',
    title: 'Telefone Fixo',
    description: 'Um plano para você falar em todo Brasil',
  },
  {
    name: 'ShieldFill',
    title: 'Proteção Web',
    description: 'Transações online muito mais seguras',
  }
];*/

/*export const CPL_DATA = [
  { name: 'DadosIlimitados',
    title: 'Dados ilimitados',
    description: 'Conexão ilimitada de dados para acesso à internet na área de concessão.'
  },
  { name: 'VozIlimitada',
    title: 'Voz ilimitada',
    description: 'Ligações ilimitadas para todo o Brasil utilizando o 12.'
  },
  { name: 'WhatsappIlimitado',
    title: 'WhatsApp Ilimitado',
    description: 'Envio de mensagens de texto por WhatsApp sem limite.'
  },
];*/

/*export const CPE_DATA = [
  { name: 'VozIlimitada',
    title: 'Ligações ilimitadas',
    description: 'Você e sua equipe tem ligações ilimitadas para qualquer operadora no mesmo DDD.'
  },
  { name: 'WhatsappIlimitado',
    title: 'WhatsApp Ilimitado',
    description: 'Troque mensagens, faça ligações, envie documentos, baixe fotos e vídeos sem se preocupar com o consumo de dados.'
  },
  { name: 'DadosIlimitados',
    title: 'Internet sem bloqueio',
    description: 'Além de tudo, são 4GB de dados no celular para navegar como quiser, sem bloqueio.'
  },
];*/

/*export const GFO_DATA = [
  {
    name: 'Otimizacao',
    title: 'Otimização de tempo',
    description: 'Economia de tempo nas atividades administrativas e de gestão da sua empresa: simples, seguro e de baixo custo.',
  },
  {
    name: 'AcessoRemoto',
    title: 'Acesso Remoto',
    description: 'Pode ser acessado de qualquer lugar e dispositivos, com uma interface simples e intuitiva.',
  },
  {
    name: 'Seguranca',
    title: 'Segurança de dados',
    description: 'Sistema em nuvem com certificado de segurança, suas informações ficam nos principais servidores do mundo.',
  },
];*/



/*export const BLI_DATA = [
  {
    name: 'Economia',
    title: 'Taxa Instalação Isenta',
    description: 'Contrate a banda larga para sua empresa e tenha isenção da taxa de instalação.',
  },
  {
    name: 'LoadBalancer',
    title: 'Sua empresa com Wi-Fi',
    description: 'Modens homologados garantindo a qualidade da sua conexão Wi-Fi com os melhores aparelhos.',
  },
  {
    name: 'AcessoRemoto',
    title: 'Serviços Extras',
    description: 'Acesso a aplicativos como Livroh, Ubook, Bancah, com livros e jornais virtuais para ter acesso aos melhores conteúdos online.',
  },
];*/

/*export const FAST_SUPPORT_DATA = [
  {
    name: 'Otimizacao',
    title: 'Agilidade',
    description: 'Precisa de vista técnica? Vamos até você em menos de 4 horas. Estaremos prontos quando seu negócio precisar de uma atenção especial.',
  },
  {
    name: 'SegurancaReforcada',
    title: 'Exclusividade',
    description: 'Os canais de atendimento são exclusivos para o seu negócio, com super prioridade para sua demanda.',
  },
  {
    name: 'Suporte',
    title: 'Assistência',
    description: 'Além de uma equipe 100% dedicada à sua empresa, prestamos assistência completa quando você solicita um serviço.',
  },
];*/
import * as React from "react"

function SvgFacebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <path
          id="facebook_svg__a"
          d="M16 2.667C8.635 2.667 2.667 8.635 2.667 16S8.635 29.333 16 29.333 29.333 23.365 29.333 16 23.365 2.667 16 2.667zm4.057 8.109h-1.463c-1.157 0-1.38.547-1.38 1.354v1.776h2.765l-.36 2.792h-2.4v7.13h-2.886v-7.13h-2.396v-2.792h2.396v-2.052c0-2.38 1.453-3.677 3.578-3.677 1.016 0 1.891.078 2.146.11v2.489z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="facebook_svg__b" fill="#fff">
          <use xlinkHref="#facebook_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#facebook_svg__a" />
        <g fill="#505F79" mask="url(#facebook_svg__b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgFacebook

import * as React from "react"

function SvgChatbot(props) {
  return (
    <svg width={52} height={52} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#1E333B" fillRule="evenodd">
        <path d="M32 19c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m-10 2c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2" />
        <path d="M47.753 27.118c0 1.097-.893 1.99-1.99 1.99h-3.978V19.16h3.978c1.097 0 1.99.893 1.99 1.99v5.967zm-7.958 6.963a.995.995 0 01-.994.994H24.876a.993.993 0 00-.195.02c-.03.006-.056.016-.084.025-.034.01-.068.018-.101.031-.033.014-.064.033-.095.05-.026.014-.052.025-.076.042a.956.956 0 00-.152.124l-8.248 8.248V36.07a.994.994 0 00-.995-.995h-1.989a.995.995 0 01-.995-.994V14.19c0-.549.447-.995.995-.995h25.86c.548 0 .994.446.994.995v19.89zM5.98 29.107a1.991 1.991 0 01-1.99-1.989v-5.967c0-1.097.893-1.99 1.99-1.99h3.978v9.946H5.98zM7.967 12.2a4.98 4.98 0 014.974-4.973h25.86a4.98 4.98 0 014.973 4.973v4.974h-1.99V14.19a2.987 2.987 0 00-2.983-2.984H12.94a2.987 2.987 0 00-2.984 2.984v2.984h-1.99V12.2zM29.85 47.011h-1.99a1.992 1.992 0 01-1.988-1.99 1.99 1.99 0 011.989-1.988h1.99a1.99 1.99 0 011.988 1.989 1.992 1.992 0 01-1.989 1.989zm15.914-29.838v-4.974a6.97 6.97 0 00-6.962-6.962H12.94a6.97 6.97 0 00-6.962 6.962v4.974a3.983 3.983 0 00-3.98 3.978v5.967a3.984 3.984 0 003.98 3.98h3.978v2.983a2.987 2.987 0 002.984 2.984h.994v8.951a.995.995 0 001.699.703l9.654-9.654H38.8a2.987 2.987 0 002.984-2.984v-2.984h1.99v8.952a3.983 3.983 0 01-3.98 3.978h-6.098a3.982 3.982 0 00-3.848-2.984h-1.99a3.983 3.983 0 00-3.977 3.979A3.983 3.983 0 0027.86 49h1.99a3.982 3.982 0 003.847-2.984h6.098a5.975 5.975 0 005.968-5.967v-8.952a3.984 3.984 0 003.979-3.979v-5.967a3.983 3.983 0 00-3.979-3.978z" />
        <path d="M31.592 28.194a1 1 0 00-1.398.214C29.002 30.031 27.06 31 25 31s-4.002-.97-5.194-2.592a1 1 0 00-1.612 1.184C19.761 31.726 22.304 33 25 33c2.695 0 5.24-1.274 6.806-3.408a1 1 0 00-.214-1.398" />
      </g>
    </svg>
  )
}

export default SvgChatbot

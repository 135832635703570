import * as React from "react"

function SvgLimitless(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={43}
      viewBox="0 0 48 43"
      {...props}
    >
      <defs>
        <style>
          {
            ".limitless_svg__prefix__cls-2{fill:#fff}.limitless_svg__prefix__cls-3{fill:#00a750}"
          }
        </style>
      </defs>
      <g
        id="limitless_svg__prefix__sem_limite"
        data-name="sem limite"
        transform="translate(0 -24)"
        opacity={0.904}
      >
        <path
          id="limitless_svg__prefix__Path_229"
          d="M42.938 24H11.9a4.848 4.848 0 00-4.887 4.792l-.2 27.121H.758a.785.785 0 00-.758.8v3.731a6.563 6.563 0 006.219 6.508V67h26.137v-.048c.194.01.311.048.447.048h.019a6.541 6.541 0 006.53-6.556V36.726h7.89a.794.794 0 00.758-.81v-6.845A5.074 5.074 0 0042.938 24zM6.559 65.457a5.019 5.019 0 01-5-5.013v-2.989h24.68v2.979a6.479 6.479 0 001.924 4.628 2.59 2.59 0 01.408.4zM37.8 60.444a5 5 0 01-4.975 5.013H32.8a5.029 5.029 0 01-5.014-5.023v-3.721a.8.8 0 00-.777-.8H8.366L8.57 28.8a3.3 3.3 0 013.33-3.257h27.374a5.221 5.221 0 00-1.474 3.548zm8.648-31.363v6.1h-7.096v-6.09a3.594 3.594 0 013.566-3.548h.019a3.529 3.529 0 013.508 3.538z"
          className="limitless_svg__prefix__cls-2"
          data-name="Path 229"
        />
        <path
          id="limitless_svg__prefix__Path_230"
          d="M165.233 91.377l3.993 2.733a.743.743 0 00.863 0l3.984-2.733a6 6 0 002.4-4.594v-5.535a.763.763 0 00-.417-.7l-6.087-3a.76.76 0 00-.678 0l-6.077 2.985a.781.781 0 00-.417.7v5.535a6.1 6.1 0 002.436 4.609zm-.863-9.644l5.283-2.607 5.283 2.617v5.05a4.46 4.46 0 01-1.735 3.315l-3.557 2.433-3.544-2.434a4.449 4.449 0 01-1.725-3.315v-5.06z"
          className="limitless_svg__prefix__cls-3"
          data-name="Path 230"
          transform="translate(-146.966 -48.292)"
        />
        <path
          id="limitless_svg__prefix__Path_231"
          d="M203.5 144.578a.777.777 0 00.553.233.794.794 0 00.552-.233l3.082-3.082a.774.774 0 00-1.1-1.1l-2.53 2.53-1.007-1.026a.775.775 0 00-1.1 1.086z"
          className="limitless_svg__prefix__cls-3"
          data-name="Path 231"
          transform="translate(-182.117 -104.981)"
        />
        <path
          id="limitless_svg__prefix__Path_232"
          d="M152.386 272h-18.61a.775.775 0 100 1.551h18.61a.775.775 0 100-1.551z"
          className="limitless_svg__prefix__cls-2"
          data-name="Path 232"
          transform="translate(-120.054 -224.094)"
        />
        <path
          id="limitless_svg__prefix__Path_233"
          d="M152.386 309h-18.61a.775.775 0 100 1.551h18.61a.775.775 0 100-1.551z"
          className="limitless_svg__prefix__cls-2"
          data-name="Path 233"
          transform="translate(-120.054 -257.528)"
        />
      </g>
    </svg>
  )
}

export default SvgLimitless

import React, { Component } from "react"
import { Helmet } from "react-helmet"
import cx from "classnames"


import TemplateHeader from "../components/TemplateHeader"
import Footer from "../components/Footer/Default"
import BlockProducts from "modules/BlockProducts"

import styles from "./corporativo.module.css"
import ModalForm from "components/ModalForm"
import Header from "components/header"

class LandingCorp extends Component {
  state = {
    opened: false,
    productSelected: "",
    isErrored: false,
  }

  openModal = () => this.setState({ opened: true })
  closeModal = () => this.setState({ opened: false })

  handleClick = () => {
    if (typeof window !== 'undefined') {
      window.open("https://www.algartelecom.com.br/empresas", "_blank");
    }
  }

  setFormErrored = (value) => {
    this.setState({
      ...this.state,
      isErrored: value
    })
  }

  render() {
    const { opened, productSelected } = this.state

    const titleClasses = cx(
      "f32 lh-title fw1 tc mb72 w-50-ns w-40-m w-third-l relative center te-papa-green db-ns db-m db-l",
      this.state.isErrored === true ? 'mt72' : '',
      styles.title
    )

    const containerClasses = cx(
      "mc-2 center dark-gray pt0 pt24-ns pt40",
      styles.containerCard
    )

    return (
      <>
        <Helmet htmlAttributes={{ lang: "pt-BR" }}>

            { /* OneTrust Cookies Consent Notice start for empreendedordigital.algartelecom.com.br */}
            <script 
            type="text/javascript" src="https://cdn.cookielaw.org/consent/90ef974f-793e-432a-b085-8ac59a5e82a2/OtAutoBlock.js"
            >
            </script>
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-language="pt" type="text/javascript" charset="UTF-8" data-domain-script="90ef974f-793e-432a-b085-8ac59a5e82a2" >
            </script>
            <script type="text/javascript">
            function OptanonWrapper() { }
            </script>
            { /* OneTrust Cookies Consent Notice end for empreendedordigital.algartelecom.com.br */ }

          <title>
            Sua empresa digital, fale com consultor agora | Algar Telecom
          </title>
          <meta
            name="description"
            content="Fale com consultor agora, conheça os nossos produtos para fazer a transformação digital da sua empresa. Link dedicado, office 365, proteção contra ataque a site, backup na nuvem, servidor na nuvem"
          />
          <meta charSet="utf-8" />
          <body className="montserrat bg-white-95" />
        </Helmet>
        <div className={styles.bgBody}>
          <TemplateHeader 
            name="default" 
            onClick={this.handleOpenChat} 
            isFormErrored={this.state.isErrored}
            setFormErrored={this.setFormErrored}
          />
        </div>

        <div
          id="Soluções para sua empresa"
          title={`${productSelected}_solução`}
          className={containerClasses}
        >
          <h2 className={titleClasses}>
            Soluções para <span className="fw7">sua empresa</span>
          </h2>
          <BlockProducts onClick={this.openModal} />
          {opened && (
            <ModalForm openModal={opened} closeModal={this.closeModal} />
          )}
        </div>
        <Footer onClick={this.handleClick} />
      </>
    )
  }
}

export default LandingCorp
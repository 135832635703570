import * as React from "react"

function SvgProdutividade(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={42}
      viewBox="0 0 40 42"
      {...props}
    >
      <defs>
        <style>{".produtividade_svg__prefix__cls-2{fill:#00a750}"}</style>
      </defs>
      <g
        id="produtividade_svg__prefix__Grupo_84"
        data-name="Grupo 84"
        transform="translate(-13.601)"
      >
        <g
          id="produtividade_svg__prefix__Grupo_85"
          data-name="Grupo 85"
          transform="translate(13.601 12.286)"
        >
          <g id="produtividade_svg__prefix__Grupo_84-2" data-name="Grupo 84">
            <path
              id="produtividade_svg__prefix__Caminho_226"
              d="M52.776 174.741h-1.81v-25.9a2.2 2.2 0 00-2.218-2.186H44.7a2.2 2.2 0 00-2.218 2.186v25.9h-4.6v-14.166a2.2 2.2 0 00-2.218-2.186h-4.037a2.2 2.2 0 00-2.218 2.186v14.166h-4.6V167.4a2.2 2.2 0 00-2.218-2.186h-4.04a2.2 2.2 0 00-2.218 2.186v7.336h-1.907a.813.813 0 100 1.627h38.35a.813.813 0 100-1.627zm-29.612 0h-5.181V167.4a.564.564 0 01.568-.56H22.6a.564.564 0 01.568.56v7.336zm13.076 0h-5.181v-14.166a.564.564 0 01.568-.56h4.045a.564.564 0 01.568.56zm13.076 0h-5.18v-25.9a.564.564 0 01.568-.56h4.045a.564.564 0 01.568.56v25.9z"
              data-name="Caminho 226"
              transform="translate(-13.601 -146.654)"
              fill="#041a29"
            />
          </g>
        </g>
        <g
          id="produtividade_svg__prefix__Grupo_87"
          data-name="Grupo 87"
          transform="translate(20.169)"
        >
          <g id="produtividade_svg__prefix__Grupo_86" data-name="Grupo 86">
            <path
              id="produtividade_svg__prefix__Caminho_227"
              d="M130.1.881a.835.835 0 00-.878-.881h-5.11a.838.838 0 000 1.676h3.13l-8.282 8.282-3.123-3.123a.838.838 0 00-1.185 0L99.592 21.9a.838.838 0 001.185 1.185l14.468-14.474 3.123 3.123a.838.838 0 001.185 0l8.874-8.874v2.833a.838.838 0 001.676 0V.922C130.1.908 130.1.895 130.1.881z"
              className="produtividade_svg__prefix__cls-2"
              data-name="Caminho 227"
              transform="translate(-99.346)"
            />
          </g>
        </g>
        <g
          id="produtividade_svg__prefix__Grupo_89"
          data-name="Grupo 89"
          transform="translate(17.961 24.04)"
        >
          <g id="produtividade_svg__prefix__Grupo_88" data-name="Grupo 88">
            <circle
              id="produtividade_svg__prefix__Elipse_6"
              cx={0.838}
              cy={0.838}
              r={0.838}
              className="produtividade_svg__prefix__cls-2"
              data-name="Elipse 6"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgProdutividade

import React from "react"
import PropTypes from "prop-types"

const Button = props => {
  const { onClick, children, className, dataValue, dataTracking } = props

  if (!children) {
    return null
  }

  const handlerOnClick = () => {
    onClick && onClick()
  }

  return (
    <button
      className={className}
      onClick={() => handlerOnClick()}
      data-value={dataValue}
      data-tracking-id={dataTracking}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Button

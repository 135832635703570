import * as React from "react"

function SvgLinkedin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <path
          id="linkedin_svg__a"
          d="M16 2.667C8.637 2.667 2.667 8.637 2.667 16S8.637 29.333 16 29.333 29.333 23.363 29.333 16 23.363 2.667 16 2.667zm-3.875 20.156H8.878v-9.77h3.247v9.77zm-1.623-11.104h-.021c-1.09 0-1.795-.75-1.795-1.687 0-.959.727-1.688 1.838-1.688 1.11 0 1.794.73 1.815 1.688 0 .937-.705 1.687-1.837 1.687zm13.332 11.104h-3.246v-5.227c0-1.313-.47-2.209-1.645-2.209-.898 0-1.432.604-1.667 1.188-.085.208-.106.5-.106.792v5.456h-3.247s.042-8.853 0-9.77h3.247v1.384c.431-.666 1.203-1.613 2.926-1.613 2.137 0 3.738 1.396 3.738 4.397v5.602z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="linkedin_svg__b" fill="#fff">
          <use xlinkHref="#linkedin_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#linkedin_svg__a" />
        <g fill="#505F79" mask="url(#linkedin_svg__b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgLinkedin

import * as React from "react"

function SvgArrowMoney(props) {
  return (
    <svg
      id="arrow-money_svg__Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 72.7 75.4"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".arrow-money_svg__st3{fill:none;stroke:#1e333b;stroke-linecap:square;stroke-miterlimit:10}"
        }
      </style>
      <path
        fill="none"
        stroke="#1e333b"
        strokeMiterlimit={10}
        d="M46.9 42.8V14H25.6v28.8h-8l18.6 18.6 18.6-18.6z"
      />
      <path
        d="M34.9 42.2v-1.9c-1.4-.1-2.7-.4-3.5-.9L32 37c.9.5 2.1.9 3.4.9 1.2 0 2-.4 2-1.3 0-.8-.7-1.3-2.2-1.8-2.2-.7-3.7-1.8-3.7-3.7 0-1.8 1.3-3.2 3.4-3.6v-1.9h2v1.8c1.4.1 2.3.3 3 .7l-.6 2.3c-.5-.2-1.5-.7-2.9-.7-1.3 0-1.8.6-1.8 1.1 0 .7.7 1.1 2.4 1.8 2.4.9 3.4 2 3.4 3.8 0 1.8-1.3 3.4-3.6 3.8v2.1h-1.9z"
        fill="#00a750"
      />
      <path className="arrow-money_svg__st3" d="M27.3 16.7h.3" />
      <path
        fill="none"
        stroke="#1e333b"
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeDasharray=".6634,1.8956"
        d="M29.5 16.7h14.4"
      />
      <path className="arrow-money_svg__st3" d="M44.9 16.7h.3" />
    </svg>
  )
}

export default SvgArrowMoney

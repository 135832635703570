import * as React from "react"

function SvgSuporte(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      viewBox="0 0 36 36"
      {...props}
    >
      <g data-name="Grupo 106">
        <g data-name="Grupo 107">
          <g data-name="Grupo 106">
            <path
              d="M31.569 11.227a.987.987 0 00.575-1.263A15.2 15.2 0 0026.7 2.8 14.981 14.981 0 007.3 4.468 15.248 15.248 0 002.884 15.1v.27l-.233.13A5.387 5.387 0 000 20.137v4.952a5.331 5.331 0 005.3 5.353h2.883a.979.979 0 00.973-.983V15.767a.979.979 0 00-.973-.983H4.827l.037-.508a13.3 13.3 0 014.148-8.732 13.026 13.026 0 018.968-3.578 13.213 13.213 0 0112.339 8.68.971.971 0 001.25.581zM5.3 16.75h1.91v11.726H5.3a3.373 3.373 0 01-3.351-3.386v-4.953A3.373 3.373 0 015.3 16.75z"
              data-name="Caminho 260"
              fill="#041a29"
            />
            <path
              d="M30.648 14.443h-2.913a.984.984 0 00-.983.983v15.217a1.932 1.932 0 01-1.93 1.93h-3.655l-.11-.2a2.44 2.44 0 100 2.362l.11-.2h3.655a3.9 3.9 0 003.9-3.9v-.546h1.93a5.359 5.359 0 005.353-5.353v-4.952a5.359 5.359 0 00-5.357-5.341zm3.386 10.305a3.39 3.39 0 01-3.386 3.386h-1.93V16.409h1.93a3.39 3.39 0 013.386 3.386z"
              data-name="Caminho 261"
              fill="#00a750"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgSuporte

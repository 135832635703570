import * as React from "react"

function SvgMarketing(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={78}
      height={72}
      viewBox="0 0 78 72"
      {...props}
    >
      <defs>
        <path
          id="marketing_svg__a"
          d="M40.367 3.397h.028a4.342 4.342 0 013.744 2.213l5.396 9.428 5.627-.34c.55-.033 1.077.25 1.354.735l2.925 5.11c.278.485.26 1.085-.046 1.552l-3.106 4.746 4.861 8.493c1.642 2.867-.306 6.453-3.556 6.631L29.58 47.51l1.902 3.323a1.484 1.484 0 01-.536 2.015l-3.8 2.214 4.388 7.667c1.619 2.824.658 6.43-2.14 8.062-2.828 1.649-6.403.619-7.992-2.161l-7.365-12.87c-4.628 1.87-10.08.092-12.666-4.426C-1.458 46.39.234 40.069 5.12 37.224l12.42-7.234L36.645 5.438a4.34 4.34 0 013.693-2.041h.057zM21.69 51.424l-5.067 2.951 7.312 12.78c.8 1.395 2.59 1.9 3.996 1.079a2.962 2.962 0 001.071-4.03L29 64.198l-.003-.006-.003-.004-.001-.002-7.051-12.323-.251-.439zm-3.71-18.283L6.582 39.78c-2.256 1.314-3.658 3.765-3.658 6.396 0 1.288.34 2.56.981 3.681 2.017 3.523 6.497 4.734 9.99 2.7l11.398-6.639-7.313-12.777zm8.775 15.332l-2.532 1.476 1.462 2.555 2.533-1.475-1.463-2.556zm-13.253-2.502a1.455 1.455 0 011.998.54 1.482 1.482 0 01-.535 2.015l-2.533 1.476c-2.105 1.225-4.79.483-5.993-1.62a1.482 1.482 0 01.535-2.016 1.457 1.457 0 011.998.54 1.457 1.457 0 001.998.54zm5.066-2.951a1.456 1.456 0 011.998.54 1.485 1.485 0 01-.535 2.016c-.7.407-1.594.165-1.997-.54a1.483 1.483 0 01.534-2.016zm18.076-32.802L20.277 31.252l7.758 13.555 25.515-5.049-16.906-29.54zm3.715-3.87a1.426 1.426 0 00-1.268.707c-.075.125-.124.171-.5.661l17.993 31.442c.51-.096.603-.133.802-.137 1.11-.028 1.785-1.244 1.23-2.212L41.607 7.084a1.437 1.437 0 00-1.247-.737zm24.05 15.002a1.462 1.462 0 011.792-1.044l5.65 1.528a1.477 1.477 0 011.036 1.806 1.462 1.462 0 01-1.792 1.043l-5.651-1.527a1.477 1.477 0 01-1.035-1.806zm-9.974-3.652l-3.265.198 3.484 6.087 1.802-2.753-2.02-3.532zM72.706 6.553a1.455 1.455 0 011.989.571c.393.712.14 1.61-.567 2.007l-8.149 4.575a1.456 1.456 0 01-1.99-.572 1.483 1.483 0 01.568-2.006zM57.651 1.192A1.462 1.462 0 0159.442.149a1.478 1.478 0 011.035 1.807l-1.515 5.702a1.463 1.463 0 01-1.792 1.043 1.477 1.477 0 01-1.034-1.807z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse
          cx={50.212}
          cy={27.443}
          fill="#FBBD0C"
          rx={27.203}
          ry={27.443}
        />
        <g transform="translate(1.56)">
          <mask id="marketing_svg__b" fill="#fff">
            <use xlinkHref="#marketing_svg__a" />
          </mask>
          <use fill="#000" xlinkHref="#marketing_svg__a" />
          <g fill="#28384A" mask="url(#marketing_svg__b)">
            <path d="M-1.56-.24h78V72h-78z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgMarketing

import * as React from "react"

function SvgEstabilidade(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="estabilidade_svg__a"
          d="M36.109.625c1.758 0 3.036 1.439 3.036 3.037v9.11h-2.077l-.001-4.955H13.891v33.406h23.176v-4.795h2.078v10.07c0 1.598-1.278 2.877-3.036 2.877H14.85c-1.758 0-3.036-1.439-3.036-3.037V3.822c0-1.758 1.278-3.197 3.036-3.197h21.26zm.958 42.676H13.891v3.197c0 .48.48.959.96.959h21.258c.48 0 .959-.48.959-.959l-.001-3.197zm-10.069.96v2.077h-3.037V44.26h3.037zm14.225-22.218v2.078l-1.918-.001v4.955h1.918v-2.077c0-.48.32-.8.64-.96.32-.159.799 0 1.119.16l5.114 5.116c.32.32.32.959 0 1.438l-5.114 5.115c-.143.284-.41.316-.693.319h-.426c-.32-.16-.64-.479-.64-.959V35.15H26.998v-2.078h15.184c.64 0 .96.48.96.959v.64l2.557-2.558-2.558-2.558v.64c0 .639-.48.959-.959.959H17.887v-2.078l19.34-.001V24.12H23.002v-2.077h18.221zM10.855 33.232v2.077H5.74v-2.077h5.115zM7.178 19.166c.32.16.64.48.64.959v2.078h3.037v2.078H6.699c-.64 0-.96-.48-.96-.96v-.639L3.183 25.24l2.558 2.397v-.639c0-.64.48-.96.959-.96h3.996v2.079H7.658v2.078c0 .48-.32.799-.64.959h-.32c-.319 0-.479-.16-.639-.32L.945 25.719c-.16-.16-.32-.48-.32-.64 0-.319.16-.479.32-.638l5.114-5.115c.32-.32.8-.32 1.12-.16zm36.763-8.152l5.114 5.115c.16.16.32.48.32.64 0 .32-.16.479-.32.639l-5.114 5.115c-.16.32-.48.479-.64.479h-.32c-.479-.16-.799-.64-.799-.959v-2.078H19.805v-2.078h23.336c.64 0 .96.48.96.96v.639l2.557-2.558-2.558-2.557v.48c0 .639-.48.958-.959.958H26.998v-1.918h15.184v-2.077c0-.48.32-.8.64-.96.32-.16.799 0 1.119.16zm-33.246 2.877v2.078H.625v-2.078h10.07zM36.109 2.863H14.85c-.48 0-.959.48-.959.959v1.917h23.176V3.822c0-.48-.479-.96-.958-.96z"
        />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="estabilidade_svg__b" fill="#fff">
          <use xlinkHref="#estabilidade_svg__a" />
        </mask>
        <use
          fill="#505050"
          fillRule="nonzero"
          xlinkHref="#estabilidade_svg__a"
        />
        <g fill="#1E333B" mask="url(#estabilidade_svg__b)">
          <path d="M-1-1h52v52H-1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgEstabilidade

import * as React from "react"

function SvgPerformance(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="performance_svg__prefix__Performance"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <style>{".performance_svg__prefix__cls-1{fill:#fff}"}</style>
      </defs>
      <path
        id="performance_svg__prefix__Path_212"
        d="M42.3 42.3zm0 0z"
        className="performance_svg__prefix__cls-1"
        data-name="Path 212"
      />
      <path
        id="performance_svg__prefix__Path_214"
        d="M206.886 334.1H199.9a1.9 1.9 0 00-1.9 1.9v.474a1.9 1.9 0 001.9 1.9h1.861a.7.7 0 100-1.4H199.9a.5.5 0 01-.5-.5V336a.5.5 0 01.5-.5h6.988a.5.5 0 01.5.5v.474a.5.5 0 01-.5.5h-1.861a.7.7 0 100 1.4h1.861a1.9 1.9 0 001.9-1.9V336a1.9 1.9 0 00-1.902-1.9z"
        className="performance_svg__prefix__cls-1"
        data-name="Path 214"
        transform="translate(-180.23 -299.767)"
      />
      <path
        id="performance_svg__prefix__Path_213"
        d="M244.217 236.367l-12.065-9.332a3.692 3.692 0 00-.855-.686 3.623 3.623 0 00-3.624 6.273.012.012 0 01.012.012c.012 0 .012.012.024.012a3.793 3.793 0 00.963.373l14.124 5.792a1.428 1.428 0 001.421-2.444zm-13.245-5.828a1.819 1.819 0 11-.578-2.625 1.817 1.817 0 01.578 2.625zm.783 1.782a3.634 3.634 0 001.325-2.3l7.8 6.033z"
        data-name="Path 213"
        transform="translate(-206.793 -204.529)"
        fill="#00a750"
      />
      <path
        id="performance_svg__prefix__Path_215"
        d="M24.027 48A24.023 24.023 0 0048 25.688a.705.705 0 00-1.408-.094 22.594 22.594 0 110-3.187.705.705 0 001.408-.094A24.028 24.028 0 1024.027 48z"
        className="performance_svg__prefix__cls-1"
        data-name="Path 215"
      />
    </svg>
  )
}

export default SvgPerformance

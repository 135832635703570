import * as React from "react"

function SvgFlexibility(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={62}
      height={62}
      viewBox="0 0 62 62"
      {...props}
    >
      <g opacity={0.899}>
        <path d="M0 0h62v62H0z" data-name="Path 204" fill="none" />
        <path
          d="M40.015 14.765a14.085 14.085 0 00-25.9-4.662 5.916 5.916 0 00-1.16-.076A7.971 7.971 0 005 17.975a6.916 6.916 0 00.077 1.3A10.863 10.863 0 00.9 27.835c0 5.809 4.715 11.006 10.281 11.312h5.875v-1.223h-5.875c-4.947-.229-9.044-4.892-9.044-10.089a9.5 9.5 0 014.02-7.8.862.862 0 00.232-.611 8.374 8.374 0 01-.155-1.452 6.726 6.726 0 016.8-6.726 7.7 7.7 0 011.314.153.676.676 0 00.7-.306 12.874 12.874 0 0123.886 4.28.658.658 0 00.464.535 11.319 11.319 0 01-1.933 22.166h-14.07V39.3h14.146A12.416 12.416 0 0048.9 26.841a12.758 12.758 0 00-8.885-12.076z"
          data-name="Path 206"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          d="M23.131 46.067h-6a.879.879 0 01-.371-.124.879.879 0 01-.124-.371l.062-9.961h-3.59a.465.465 0 01-.433-.309.6.6 0 01.062-.557l7.115-8.414a.464.464 0 01.742 0l6.93 8.414a.484.484 0 01.062.495.465.465 0 01-.433.309h-3.465l-.062 9.961a.506.506 0 01-.495.557zm-5.506-.99h5.011l.062-9.961a.532.532 0 01.495-.495H26.1l-5.878-7.115-6.062 7.115h3.032a.879.879 0 01.371.124.879.879 0 01.124.371z"
          data-name="Path 205"
          fill="#00a948"
        />
      </g>
    </svg>
  )
}

export default SvgFlexibility

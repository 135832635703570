import * as React from "react"

function SvgPattern3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={634.278}
      height={25.488}
      viewBox="0 0 634.278 25.488"
      {...props}
    >
      <defs>
        <style>{".pattern-3_svg__prefix__cls-2{fill:#041a29}"}</style>
      </defs>
      <g
        id="pattern-3_svg__prefix__Grupo_117"
        data-name="Grupo 117"
        opacity={0.1}
      >
        <circle
          id="pattern-3_svg__prefix__Elipse_4473"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4473"
          transform="translate(0 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4474"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4474"
          transform="translate(16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4475"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4475"
          transform="translate(32.908 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4476"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4476"
          transform="translate(49.361)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4477"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4477"
          transform="translate(65.815 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4478"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4478"
          transform="translate(82.269)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4479"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4479"
          transform="translate(98.723 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4480"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4480"
          transform="translate(115.177)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4481"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4481"
          transform="translate(131.63 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4482"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4482"
          transform="translate(148.084)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4483"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4483"
          transform="translate(164.538 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4484"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4484"
          transform="translate(180.992)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4485"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4485"
          transform="translate(197.446 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4486"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4486"
          transform="translate(213.899)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4487"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4487"
          transform="translate(230.353 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4488"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4488"
          transform="translate(246.807)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4489"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4489"
          transform="translate(263.261 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4490"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4490"
          transform="translate(279.715)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4491"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4491"
          transform="translate(296.168 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4492"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4492"
          transform="translate(312.622)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4493"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4493"
          transform="translate(329.076 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4494"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4494"
          transform="translate(345.53)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4495"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4495"
          transform="translate(361.984 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4496"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4496"
          transform="translate(378.437)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4497"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4497"
          transform="translate(394.891 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4498"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4498"
          transform="translate(411.345)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4499"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4499"
          transform="translate(427.799 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4500"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4500"
          transform="translate(444.253)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4501"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4501"
          transform="translate(460.706 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4502"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4502"
          transform="translate(477.16)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4503"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4503"
          transform="translate(493.614 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4504"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4504"
          transform="translate(510.068)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4505"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4505"
          transform="translate(526.522 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4506"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4506"
          transform="translate(542.975)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4507"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4507"
          transform="translate(559.429 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4508"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4508"
          transform="translate(575.883)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4509"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4509"
          transform="translate(592.337 16.454)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4510"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4510"
          transform="translate(608.791)"
        />
        <circle
          id="pattern-3_svg__prefix__Elipse_4511"
          cx={4.517}
          cy={4.517}
          r={4.517}
          className="pattern-3_svg__prefix__cls-2"
          data-name="Elipse 4511"
          transform="translate(625.244 16.454)"
        />
      </g>
    </svg>
  )
}

export default SvgPattern3

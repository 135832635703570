import * as React from "react"

function SvgFullFlexibility(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <style>
          {
            ".full-flexibility_svg__prefix__cls-4{fill:#00a750;fill-rule:evenodd}"
          }
        </style>
      </defs>
      <g id="full-flexibility_svg__prefix__Flexibilidade" opacity={0.897}>
        <path
          id="full-flexibility_svg__prefix__Path_130"
          d="M0 0h48v48H0z"
          data-name="Path 130"
          fill="none"
        />
        <path
          id="full-flexibility_svg__prefix__Path_131"
          d="M25.523 48.377a21.045 21.045 0 01-5.342-.7A22.034 22.034 0 014 23.166l-2.013-.543a1.235 1.235 0 01-1.004-1.009 1.305 1.305 0 01.387-1.4l7.587-7.447a1.383 1.383 0 011.393-.388 1.535 1.535 0 011.084 1.086l2.555 10.24a1.305 1.305 0 01-.387 1.4 1.383 1.383 0 01-1.393.388l-1.471-.543a14.975 14.975 0 0011.38 16.135c7.9 2.017 16.1-2.948 18.347-11.015l.387-1.474a1.564 1.564 0 011.858-1.086l3.638 1.09a1.665 1.665 0 01.929.7 1.4 1.4 0 01.155 1.086l-.387 1.4a22.567 22.567 0 01-21.522 16.591zm-15.406-34.6l-7.663 7.449L5.086 22a.715.715 0 01.542.853A20.43 20.43 0 0020.646 46.2a20.774 20.774 0 0025-14.739l.387-1.4-3.633-1-.387 1.474c-2.477 8.766-11.457 14.2-20.128 12.024A16.563 16.563 0 019.343 23.941a.786.786 0 01.31-.543.583.583 0 01.619-.078l2.4.7z"
          data-name="Path 131"
          transform="translate(-.207 -2.859)"
          fill="#fff"
        />
        <path
          id="full-flexibility_svg__prefix__Path_132"
          d="M20.9 32.638c0-4.267 5.508-4.965 5.508-7.292a1.629 1.629 0 00-1.784-1.629 2.747 2.747 0 00-2.094 1.4l-1.551-1.086a4.361 4.361 0 013.877-2.331c2.094 0 3.8 1.241 3.8 3.413 0 3.724-5.275 4.422-5.353 6.671h5.508v1.862h-7.833c0-.31-.078-.62-.078-1.008z"
          className="full-flexibility_svg__prefix__cls-4"
          data-name="Path 132"
          transform="translate(-4.726 -4.911)"
        />
        <path
          id="full-flexibility_svg__prefix__Path_133"
          d="M40.28 28.982V22h-2.56l-5.12 7.447v1.241h5.508v3.025H40.2v-3.025h1.474v-1.706zm-2.094-3.568v3.568h-3.259l2.715-3.724a12.042 12.042 0 00.621-1.086 9.761 9.761 0 00-.078 1.241z"
          data-name="Path 133"
          transform="translate(-7.373 -4.979)"
          fill="#00a750"
        />
        <path
          id="full-flexibility_svg__prefix__Path_134"
          d="M50.065 20.418l-3.8.543a.831.831 0 00-.7.853l.155 1.164a.831.831 0 00.853.7l3.8-.543a.831.831 0 00.7-.853l-.155-1.164c0-.467-.388-.7-.853-.7zm-2.948-7.292L43.7 14.988a.785.785 0 00-.31 1.086l.621 1.086a.785.785 0 001.086.31l3.413-1.862a.785.785 0 00.31-1.086l-.62-1.086a.859.859 0 00-1.083-.31zM41.61 7.618l-2.4 3.025a.759.759 0 00.155 1.086l.931.776a.759.759 0 001.086-.155l2.4-3.025a.759.759 0 00-.155-1.086l-.927-.776a.7.7 0 00-1.09.155zM34.4 4.36l-1.168 3.646a.787.787 0 00.543 1.008l1.164.31a.787.787 0 001.008-.543l1.163-3.645a.787.787 0 00-.543-1.008L35.4 3.817a1.211 1.211 0 00-1 .543zm-7.757-.233l.388 3.8a.733.733 0 00.853.7l1.164-.078a.733.733 0 00.7-.853l-.388-3.8a.733.733 0 00-.853-.7l-1.164.078a.909.909 0 00-.705.853zm-7.375 2.716l1.784 3.413a.785.785 0 001.086.31l1.086-.543a.785.785 0 00.31-1.086l-1.783-3.413a.959.959 0 00-1.086-.31l-1.086.543a.8.8 0 00-.311 1.086z"
          className="full-flexibility_svg__prefix__cls-4"
          data-name="Path 134"
          transform="translate(-4.392 -.714)"
        />
      </g>
    </svg>
  )
}

export default SvgFullFlexibility

import * as React from "react"

function SvgBackup(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="backup_svg__a"
          d="M24.072 14.139l9.348 11.8h-5.21v14.405h-1.993V23.947h2.912l-5.057-6.437-5.058 6.437h3.065v16.397h-1.992V25.939h-5.21l9.195-11.8zM17.942.5c4.29 0 8.275 2.452 10.114 6.283 1.38-.766 2.758-1.226 4.29-1.226 4.445 0 8.123 3.678 8.123 8.122h1.073c4.444 0 8.122 3.678 7.815 8.429 0 4.444-3.678 8.122-8.122 8.122H30.048v-1.993h11.187c3.372 0 6.13-2.758 6.13-6.13 0-3.37-2.758-6.13-6.13-6.13-.46 0-.92 0-1.38.154-.152.766-.459 1.38-.765 1.992l-1.686-1.072c.306-.613.613-1.38.766-1.993l.057-.196c.096-.392.096-.767.096-1.03 0-3.371-2.758-6.13-6.13-6.13-1.225 0-2.451.307-3.524 1.073.153.613.306 1.226.306 1.84l-1.992.152c0-.613-.153-1.226-.306-1.838l-.153-.307c-1.38-3.678-4.904-6.13-8.735-6.13-5.058 0-9.195 4.138-9.195 9.195 0 .766.153 1.532.306 2.145.766 0 1.38.154 2.146.307l-.613 1.992c-.613-.306-1.38-.306-2.146-.306l-.234.026c-3.102.408-5.436 2.964-5.436 6.103 0 3.372 2.759 6.13 6.13 6.13h9.195v1.992H8.747c-4.444 0-8.122-3.677-8.122-8.122 0-3.83 2.758-7.202 6.283-7.968 0-.767-.153-1.533-.153-2.3C6.755 5.558 11.812.5 17.942.5z"
        />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="backup_svg__b" fill="#fff">
          <use xlinkHref="#backup_svg__a" />
        </mask>
        <use fill="#505050" fillRule="nonzero" xlinkHref="#backup_svg__a" />
        <g fill="#000" mask="url(#backup_svg__b)">
          <path d="M-1-6h52v52H-1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgBackup

import * as React from "react"

function SvgColaborativo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="colaborativo_svg__a"
          d="M35.219 17.926c1.724 0 3.135 1.37 3.135 3.047v6.246l6.426 1.218c2.507.458 4.075 2.286 4.075 4.57v5.18c0 .762-.314 1.524-.627 1.981-.157.457-.314.762-.314 1.066v1.98h.784v7.161H29.89v-7.16l2.193-.001.001-1.523c-2.037-1.675-2.978-3.656-3.605-5.18l-.47-.913-2.194-4.266v-.305c0-.914.783-1.675 1.88-2.132 1.254-.457 2.352-.305 3.135.457l1.254 1.218v-9.597c0-1.618 1.315-2.952 2.958-3.042l.177-.005zM46.66 45.348H32.084v3.047h14.577v-3.047zm1.097-43.723V26h-2.195V9.699H3.87v36.563h23.981v2.133H1.677V1.625h46.08zm-12.54 18.281c-.626 0-1.096.457-1.096 1.067V33.16h-2.508l-.314-.305-2.037-1.98c-.157-.152-.47-.152-.627-.152-.314 0-.627.152-.784.304l1.881 3.657c.157.304.314.609.47 1.066.627 1.523 1.568 3.352 3.448 4.723l.47.304v2.438h11.442v-1.98c0-.762.314-1.372.627-1.981.157-.457.47-.762.47-1.066V33.16c0-1.98-1.724-2.437-2.35-2.59l-8.15-1.675v-7.922c0-.61-.471-1.067-.941-1.067zm-8.776 19.348v1.98H7.947v-1.98h18.495zm-2.195-4.57v1.98h-16.3v-1.98h16.3zm-.313-4.57v1.98H7.947v-1.98h15.987zM17.35 13.811v1.981h-7.21v8.227h9.404v-3.047h2.038V26H7.947V13.812h9.404zm12.696 9.141v1.98h-4.232v-1.98h4.232zm-9.248-8.836l1.568 1.371-7.054 7.922-3.918-3.808 1.567-1.372 2.351 2.286 5.486-6.399zm9.248 4.723v1.98h-4.232v-1.98h4.232zm12.538-3.961v1.98h-16.77v-1.98h16.77zm2.978-11.274H3.872v4.113h41.69l.001-4.113zM7.947 4.672v1.98H5.909v-1.98h2.038zm4.232 0v1.98H10.14v-1.98h2.038z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="colaborativo_svg__b" fill="#fff">
          <use xlinkHref="#colaborativo_svg__a" />
        </mask>
        <use
          fill="#1E333B"
          fillRule="nonzero"
          xlinkHref="#colaborativo_svg__a"
        />
        <g fill="#000" mask="url(#colaborativo_svg__b)">
          <path d="M0 0h53.677v52H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgColaborativo

import * as React from "react"

function SvgPattern6(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={92.191}
      height={101.201}
      viewBox="0 0 92.191 101.201"
      {...props}
    >
      <path
        d="M34.758 99.591a4.5 4.5 0 01-1.351-3.221 4.517 4.517 0 014.517-4.516 4.517 4.517 0 014.516 4.516 4.519 4.519 0 01-3.183 4.317q-2.273-.445-4.499-1.096zm31.557-3.221a4.517 4.517 0 014.517-4.516 4.517 4.517 0 014.148 2.729 50.659 50.659 0 01-8.153 3.88 4.5 4.5 0 01-.512-2.094zM49.861 79.916a4.516 4.516 0 014.517-4.516 4.516 4.516 0 014.516 4.516 4.517 4.517 0 01-4.516 4.517 4.517 4.517 0 01-4.517-4.517zm-32.908 0A4.516 4.516 0 0121.47 75.4a4.516 4.516 0 014.516 4.516 4.517 4.517 0 01-4.516 4.517 4.517 4.517 0 01-4.517-4.517zm65.815 0a4.517 4.517 0 018.88-1.169q-.3.454-.611.9a51.3 51.3 0 01-3.754 4.784 4.517 4.517 0 01-4.514-4.515zm-16.39-15.691a37.27 37.27 0 008.438 1.365 4.516 4.516 0 01-3.985 2.39 4.518 4.518 0 01-4.452-3.756zm-32.972-.763a4.517 4.517 0 014.517-4.516 4.517 4.517 0 014.516 4.516 4.517 4.517 0 01-4.516 4.517 4.517 4.517 0 01-4.516-4.517zm-32.908 0a4.517 4.517 0 014.517-4.516 4.517 4.517 0 014.516 4.516 4.517 4.517 0 01-4.516 4.517A4.517 4.517 0 01.5 63.462zm16.454-16.454a4.516 4.516 0 014.517-4.516 4.516 4.516 0 014.516 4.516 4.517 4.517 0 01-4.516 4.517 4.517 4.517 0 01-4.516-4.517zm16.454-16.454a4.517 4.517 0 014.517-4.516 4.523 4.523 0 011.168.153v1.939a37.754 37.754 0 00.575 6.592 4.5 4.5 0 01-1.743.349 4.517 4.517 0 01-4.516-4.517zM1.219 33.003l.042-.112a50.787 50.787 0 013.113-6.807 4.566 4.566 0 01.642-.045 4.517 4.517 0 014.516 4.516 4.517 4.517 0 01-4.516 4.517 4.514 4.514 0 01-3.796-2.069zm15.733-18.9a4.516 4.516 0 014.517-4.516 4.516 4.516 0 014.516 4.516 4.517 4.517 0 01-4.516 4.517 4.517 4.517 0 01-4.516-4.52zM35.5 1.462q1.776-.489 3.591-.85v1.4a4.523 4.523 0 01-1.168.153 4.5 4.5 0 01-2.422-.706z"
        data-name="Interse\xE7\xE3o 6"
        fill="#041a29"
        stroke="transparent"
        strokeMiterlimit={10}
        opacity={0.1}
      />
    </svg>
  )
}

export default SvgPattern6

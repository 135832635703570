import * as React from "react"

function SvgLoadBalancer(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      height={40}
      viewBox="0 0 44 40"
      {...props}
    >
      <defs>
        <style>{".load-balancer_svg__prefix__cls-1{fill:#00a750}"}</style>
      </defs>
      <g
        id="load-balancer_svg__prefix__Grupo_94"
        data-name="Grupo 94"
        transform="translate(-6.008 -28.866)"
      >
        <path
          id="load-balancer_svg__prefix__Caminho_238"
          d="M440.158 374.6l-2.63 1.992a.805.805 0 00.972 1.284l4.529-3.43.022-.018.014-.01.021-.019.028-.025.029-.03.028-.03.017-.022.013-.015.011-.016.074-.131.03-.077.009-.032.011-.044c0-.011 0-.021.006-.032l.007-.046v-.194l-.007-.047c0-.012 0-.024-.007-.035l-.011-.04-.011-.036-.018-.047-.008-.021-.075-.135-.019-.027-.011-.014-.018-.023-.017-.019-.054-.055-.022-.019-.02-.016-.025-.021-4.529-3.43a.805.805 0 00-1.128.156.806.806 0 00.156 1.128l2.63 1.992z"
          className="load-balancer_svg__prefix__cls-1"
          data-name="Caminho 238"
          transform="translate(-393.351 -310.84)"
        />
        <path
          id="load-balancer_svg__prefix__Caminho_239"
          d="M211.519 428.3l-2.1-2.1a.805.805 0 00-1.139 1.139l3.379 3.379.024.032a.8.8 0 001.293 0l.023-.032 3.379-3.379a.805.805 0 10-1.139-1.139l-2.1 2.1z"
          className="load-balancer_svg__prefix__cls-1"
          data-name="Caminho 239"
          transform="translate(-184.319 -362.206)"
        />
        <path
          id="load-balancer_svg__prefix__Caminho_240"
          d="M9.209 373l2.63-2a.805.805 0 00-.972-1.284l-4.529 3.43-.017.014-.008.006-.018.014-.021.019-.055.055-.021.024-.011.014-.016.02-.017.024-.078.139-.009.024-.017.045c0 .009-.006.019-.009.03l-.011.043c0 .012 0 .023-.006.033l-.007.047v.198l.007.044c0 .012 0 .024.007.036l.01.037c0 .015.007.025.01.035l.015.042.011.029.075.135.018.025.012.015.019.024.023.025.032.033.027.025.021.019.013.01.021.017 4.53 3.431a.805.805 0 001.128-.156.806.806 0 00-.156-1.128l-2.63-1.992z"
          className="load-balancer_svg__prefix__cls-1"
          data-name="Caminho 240"
          transform="translate(0 -310.845)"
        />
        <path
          id="load-balancer_svg__prefix__Caminho_241"
          d="M70.476 62.225h-9.708v-9.648H65a6.331 6.331 0 00.276-12.655l-.452-.02-.049-.45a11.87 11.87 0 00-22.41-4.038l-.173.345-.381-.061a8.492 8.492 0 10-1.236 16.879h1.984v9.649h-9.711a.791.791 0 00-.791.792.791.791 0 00.791.792h10.5a.792.792 0 00.791-.792V52.576h6.729v13.531a.792.792 0 00.791.792.792.792 0 00.791-.792V52.576h6.737v10.441a.792.792 0 00.791.792h10.5a.792.792 0 00.791-.792.792.792 0 00-.793-.792zM40.464 50.991a6.908 6.908 0 111.947-13.538l.021.006a.867.867 0 00.069.017.789.789 0 00.754-.273l.009-.01.032-.044.013-.019.024-.041.014-.024.016-.034.027-.061a10.291 10.291 0 0119.867 3.783.8.8 0 000 .085.788.788 0 00.057.225l.022.046.014.029.014.022.025.041c.007.012.016.021.024.032l.024.032.022.024.092.088.034.026.02.015.024.014.04.024.027.013.042.021.032.012h.007l.037.013.025.006.05.013a.782.782 0 00.239.012.807.807 0 00.084-.013 2.292 2.292 0 01.564-.037h.217a4.746 4.746 0 110 9.492z"
          data-name="Caminho 241"
          transform="translate(-23.654)"
          fill="#041a29"
        />
      </g>
    </svg>
  )
}

export default SvgLoadBalancer

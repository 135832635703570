import * as React from "react"

function SvgCommunication(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={36}
      viewBox="0 0 48 36"
      {...props}
    >
      <defs>
        <style>{".communication_svg__prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g
        id="communication_svg__prefix__comicados"
        transform="translate(0 -8)"
        opacity={0.9}
      >
        <g
          id="communication_svg__prefix__Group_49"
          data-name="Group 49"
          transform="translate(0 8)"
        >
          <path
            id="communication_svg__prefix__Path_221"
            d="M46.531 44H1.391A1.394 1.394 0 010 42.591V9.409A1.394 1.394 0 011.391 8h45.218A1.394 1.394 0 0148 9.409V42.67A1.509 1.509 0 0146.531 44zM1.391 9.174a.167.167 0 00-.155.157v33.26a.167.167 0 00.155.157h45.218a.167.167 0 00.155-.157V9.409a.167.167 0 00-.155-.157H1.391z"
            className="communication_svg__prefix__cls-2"
            data-name="Path 221"
            transform="translate(0 -8)"
          />
        </g>
        <g
          id="communication_svg__prefix__Group_51"
          data-name="Group 51"
          transform="translate(3.104 11.104)"
        >
          <g id="communication_svg__prefix__Group_50" data-name="Group 50">
            <path
              id="communication_svg__prefix__Path_222"
              d="M26.658 27.519H5.4a1.391 1.391 0 01-1.4-1.4V13.4A1.391 1.391 0 015.4 12h21.336a1.391 1.391 0 011.4 1.4v12.8a1.507 1.507 0 01-1.478 1.319zM5.4 13.164a.167.167 0 00-.155.155v12.8a.167.167 0 00.155.155h21.336a.167.167 0 00.155-.155V13.4a.167.167 0 00-.155-.155H5.4z"
              className="communication_svg__prefix__cls-2"
              data-name="Path 222"
              transform="translate(-4 -12)"
            />
          </g>
        </g>
        <g
          id="communication_svg__prefix__Group_52"
          data-name="Group 52"
          transform="translate(23.923 23.049)"
        >
          <path
            id="communication_svg__prefix__Path_223"
            d="M32.576 27.8h11.252a.733.733 0 01.776.776v14.355a.733.733 0 01-.776.776H32.576a.733.733 0 01-.776-.776V28.576a.733.733 0 01.776-.776z"
            data-name="Path 223"
            transform="translate(-31.179 -27.179)"
            fill="none"
            fillRule="evenodd"
          />
          <path
            id="communication_svg__prefix__Path_224"
            d="M43.571 44.071H32.4a1.391 1.391 0 01-1.4-1.4V28.4a1.391 1.391 0 011.4-1.4h11.248a1.391 1.391 0 011.4 1.4v14.352a1.507 1.507 0 01-1.477 1.319zM32.4 28.164a.167.167 0 00-.155.155v14.356a.167.167 0 00.155.155h11.248a.167.167 0 00.155-.155V28.4a.167.167 0 00-.155-.155H32.4z"
            data-name="Path 224"
            transform="translate(-31 -27)"
            fill="#00a750"
          />
        </g>
        <g
          id="communication_svg__prefix__Group_53"
          data-name="Group 53"
          transform="translate(3.104 28.481)"
        >
          <path
            id="communication_svg__prefix__Path_225"
            d="M15.795 45.64H5.4a1.391 1.391 0 01-1.4-1.4V35.4A1.391 1.391 0 015.4 34h10.472a1.391 1.391 0 011.4 1.4v8.92a1.507 1.507 0 01-1.477 1.32zM5.4 35.164a.167.167 0 00-.155.155v8.924a.167.167 0 00.155.157h10.472a.167.167 0 00.155-.155V35.4a.167.167 0 00-.155-.155H5.4z"
            className="communication_svg__prefix__cls-2"
            data-name="Path 225"
            transform="translate(-4 -34)"
          />
        </g>
        <g
          id="communication_svg__prefix__Group_54"
          data-name="Group 54"
          transform="translate(5.587 16.071)"
        >
          <path
            id="communication_svg__prefix__Path_226"
            d="M33.816 38.375H28a.612.612 0 01-.621-.621.571.571 0 01.621-.62h5.82a.621.621 0 110 1.242zm-21.727 0H7.821a.612.612 0 01-.621-.621.571.571 0 01.621-.621h4.268a.621.621 0 110 1.242zm2.871-3.1H7.821a.612.612 0 01-.621-.621.571.571 0 01.621-.621h7.139a.612.612 0 01.621.621.571.571 0 01-.621.617zm17.3-1.552H28a.612.612 0 01-.621-.621.571.571 0 01.621-.624h4.268a.621.621 0 010 1.242zm2.871-3.88H28a.612.612 0 01-.621-.621A.571.571 0 0128 28.6h7.139a.612.612 0 01.621.621.571.571 0 01-.625.619zM24.5 24.1H7.821a.612.612 0 01-.621-.621.571.571 0 01.621-.621H24.5a.621.621 0 010 1.242zm-4.345-4.656H7.821a.612.612 0 01-.621-.621.571.571 0 01.621-.621h12.338a.621.621 0 110 1.242z"
            className="communication_svg__prefix__cls-2"
            data-name="Path 226"
            transform="translate(-7.2 -18.2)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgCommunication

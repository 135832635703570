import * as React from "react"

function SvgInvestiment(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={111}
      height={129}
      viewBox="0 0 111 129"
      {...props}
    >
      <defs>
        <path id="investiment_svg__a" d="M.002.087h107.582v125.236H.002z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#004281"
          d="M2.595 85.361s13.728-6.302 26.594 4.662c12.861 10.96 46.011 4.988 54.153 0 8.143-4.991 18.287-7.748 17.943 0-.347 7.749 3.316 14.058 3.316 14.058l-5.31 14.673-11.867 6.56-35.283 1.561-28.42-1.25-13.739-5.306-5.621-8.633-1.766-14.788V85.361z"
        />
        <path
          fill="#FFF"
          d="M58.008 82.186H50.85a4.709 4.709 0 01-4.712-4.712 4.712 4.712 0 014.712-4.716h12.604a3.131 3.131 0 003.122-3.122 3.135 3.135 0 00-3.122-3.126h-5.912v-4.347a3.136 3.136 0 00-3.126-3.126 3.135 3.135 0 00-3.122 3.126v4.347h-.444c-6.044 0-10.964 4.92-10.964 10.964 0 6.04 4.92 10.96 10.964 10.96h7.158a4.71 4.71 0 014.712 4.716c0 2.6-2.105 4.709-4.712 4.709H45.175a3.136 3.136 0 00-3.126 3.126 3.135 3.135 0 003.126 3.122h6.144v4.45a3.131 3.131 0 003.123 3.127 3.133 3.133 0 003.125-3.126v-4.451h.598c5.99-.075 10.807-4.974 10.807-10.957 0-6.044-4.924-10.964-10.964-10.964"
        />
        <g transform="translate(0 3.495)">
          <mask id="investiment_svg__b" fill="#fff">
            <use xlinkHref="#investiment_svg__a" />
          </mask>
          <path
            fill="#FFF"
            d="M83.342 119.075H24.24c-9.919 0-17.99-8.074-17.99-17.993 0-16.69 6.12-32.52 18.147-47.05C32 44.846 39.73 39.142 42.078 37.477h23.378c2.37 1.64 10.076 7.369 17.678 16.557 12.056 14.53 18.15 30.335 18.15 47.05.05 9.918-8.02 17.992-17.942 17.992zM29.188 10.657c3.018-2.603 5.908-3.75 9.37-3.75 3.696 0 7.864 1.25 13.41 3.31 1.15.415 2.367.622 3.62.622 3.047 0 5.962-1.196 8.748-2.37 2.682-1.117 5.23-2.184 7.709-2.184 1.196 0 2.886.207 5.023 1.715L64.572 31.228H42.805L29.188 10.657zm72.2 60.95c-3.283-7.549-7.813-14.864-13.538-21.71-6.93-8.332-13.83-13.907-17.345-16.485L83.785 8.78c.652-1.224.445-2.732-.572-3.695C79.779 1.73 76.13.087 72.07.087c-3.72 0-7.136 1.403-10.105 2.656-2.342.96-4.554 1.898-6.352 1.898-.544 0-1.013-.075-1.482-.258C47.933 2.117 43.167.71 38.559.71c-5.833 0-10.803 2.345-15.673 7.344-1.017 1.038-1.17 2.681-.362 3.906l14.32 21.634c-3.545 2.632-10.313 8.15-17.109 16.303-5.7 6.846-10.258 14.161-13.538 21.71C2.087 81.108.002 91.03.002 101.081c0 13.356 10.882 24.241 24.238 24.241h59.102c13.355 0 24.244-10.885 24.244-24.24 0-10.052-2.087-19.974-6.198-29.477z"
            mask="url(#investiment_svg__b)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgInvestiment

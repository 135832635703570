import * as React from "react"

function SvgShield(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={48}
      viewBox="0 0 37 48"
      {...props}
    >
      <g opacity={0.905}>
        <path
          d="M36.517 9.718L18.671.106a.908.908 0 00-.851 0L.457 9.731a.9.9 0 00-.457.783v12.551a26.007 26.007 0 0015.42 23.793l2.413 1.067a.86.86 0 00.711 0l2.718-1.181A25.943 25.943 0 0037 22.825V10.514a.932.932 0 00-.483-.796zm-1.308 13.107a24.149 24.149 0 01-14.658 22.262l-2.363 1.027-2.058-.911a24.189 24.189 0 01-14.353-22.15V11.04l16.475-9.125 16.957 9.125z"
          data-name="Path 143"
          fill="#fff"
        />
        <path
          d="M9.53 22a1.156 1.156 0 00-1.624-.132 1.176 1.176 0 00-.131 1.636l6.071 7.156a1.135 1.135 0 001.592.149l14.128-11.568a1.143 1.143 0 00.164-1.62 1.124 1.124 0 00-1.608-.165L14.864 28.314z"
          data-name="Path 144"
          fill="#00a750"
        />
      </g>
    </svg>
  )
}

export default SvgShield

import * as React from "react"

function SvgCalling(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      {...props}
    >
      <g data-name="Grupo 66">
        <path
          data-name="Caminho 204"
          d="M32.417 37.47c-.3-.246-3.854-3.114-4.727-3.781a3.16 3.16 0 00-2.591-.721c-1.183.224-2.39 1.161-3.689 2.863-1.32 1.73-2.695 2.009-4.595.933a.938.938 0 00-.924 1.631c2.693 1.525 5.107 1.042 6.986-1.4l7.628 6.1a9.187 9.187 0 01-3.989 2.5c-3.046.984-9.365 1.427-17.455-6.663S1.414 24.523 2.398 21.477a9.19 9.19 0 012.5-3.982l6.1 7.627c-2.431 1.877-2.912 4.289-1.389 6.979a.938.938 0 101.632-.924c-1.076-1.9-.8-3.274.933-4.594 1.7-1.3 2.639-2.506 2.863-3.689a3.161 3.161 0 00-.721-2.591c-.667-.873-3.536-4.424-3.781-4.727a3.442 3.442 0 00-3.442-1.274c-2.7.437-5.362 3.151-6.476 6.6a14.259 14.259 0 00.134 8.674 26.932 26.932 0 006.982 10.691 26.932 26.932 0 0010.684 6.987 15.673 15.673 0 004.69.746 12.935 12.935 0 003.984-.612c3.449-1.114 6.163-3.777 6.6-6.476a3.442 3.442 0 00-1.274-3.442zM7.388 16.16a1.571 1.571 0 011.645.552l.026.034c.031.038 3.1 3.831 3.762 4.7a1.3 1.3 0 01.368 1.1 2.9 2.9 0 01-.794 1.331l-5.944-7.431a3.759 3.759 0 01.937-.286zm16.737 19.437a2.888 2.888 0 011.324-.787 1.3 1.3 0 011.1.368c.873.667 4.665 3.732 4.7 3.762l.034.026a1.571 1.571 0 01.552 1.645 3.75 3.75 0 01-.288.926z"
          fill="#1b2f3d"
        />
        <path
          data-name="Caminho 205"
          d="M33 0a15 15 0 00-13 22.481l-1.113 4.151a2.007 2.007 0 00.536 1.968 2.036 2.036 0 001.971.513l4.13-1.108A15 15 0 1033 0zm0 28.127a13.1 13.1 0 01-6.852-1.929.937.937 0 00-.733-.106L20.898 27.3h-.012a.151.151 0 01-.152-.042.141.141 0 01-.041-.148l1.215-4.532a.938.938 0 00-.106-.733 13.127 13.127 0 1111.2 6.275z"
          fill="#1b2f3d"
        />
        <path
          data-name="Caminho 206"
          d="M37.687 9.376h-9.375a.938.938 0 100 1.875h9.376a.938.938 0 000-1.875z"
          fill="#17af60"
        />
        <path
          data-name="Caminho 207"
          d="M37.687 14.063h-9.375a.938.938 0 100 1.875h9.376a.938.938 0 000-1.875z"
          fill="#17af60"
        />
        <path
          data-name="Caminho 208"
          d="M37.687 18.751h-9.375a.938.938 0 100 1.875h9.376a.938.938 0 000-1.875z"
          fill="#17af60"
        />
        <path
          data-name="Caminho 209"
          d="M13.07 33.992a.938.938 0 10.938.938.937.937 0 00-.938-.938z"
        />
      </g>
    </svg>
  )
}

export default SvgCalling

import {
  CLOUD_BACKUP,
  CLOUD_BACKUP_DATA,
  CLOUD_PHONE_PRO,
  CLOUD_PHONE_PRO_DATA,
  OFFICE_365,
  OFFICE_365_DATA,
  LINK_DEDICADO,
  LINK_DEDICADO_DATA,
  OMNI_PRO,
  OMNI_PRO_DATA,
  NOC,
  NOC_DATA,
  ANTI_DDOS,
  ANTI_DDOS_DATA,
  CLOUD_PLUS,
  CLOUD_PLUS_DATA,
  CONTROLE_DE_PONTO,
  CONTROLE_DE_PONTO_DATA,
  SD_WAN,
  SD_WAN_DATA,
} from "../../utils/productsList"

export const PRODUCTS_DATA = [
  {
    id: `product - ${CLOUD_BACKUP}`,
    title: CLOUD_BACKUP,
    description:
      "Hospede todos os arquivos do seu negócio na nuvem, com economia e segurança. Acesse sempre que precisar e evite preocupações com imprevistos, falhas ou problemas técnicos.",
    benefits: CLOUD_BACKUP_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${OFFICE_365}`,
    title: OFFICE_365,
    description:
      "O poder do office que você já conhece com ainda mais ferramentas para aumentar a produtividade da sua empresa. Mantenha o trabalho em equipe em #modoturbo, com seu time integrado a qualquer momento e em qualquer lugar.",
    benefits: OFFICE_365_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${CONTROLE_DE_PONTO}`,
    title: CONTROLE_DE_PONTO,
    description:
      "Uma solução em parceria com a Tangerino que gera mais produtividade e economia de tempo na gestão de jornada de trabalho.",
    benefits: CONTROLE_DE_PONTO_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${CLOUD_PHONE_PRO}`,
    title: CLOUD_PHONE_PRO,
    description:
      "Reduza custos e ganhe agilidade no atendimento do seu negócio minimizando investimento em infraestrutura, manutenções e conta telefônica. ",
    benefits: CLOUD_PHONE_PRO_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${NOC}`,
    title: NOC,
    description:
      "É uma central de gerenciamento que utiliza ferramentas para monitorar e identificar as condições de risco dos equipamentos ligados em rede: computadores, roteadores, gateways e até ERPs.",
    benefits: NOC_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${ANTI_DDOS}`,
    title: ANTI_DDOS,
    description:
      "O Anti-DDoS é um serviço para os clientes que possuem Internet Link de monitoramento de tráfego, que detecta ataques volumétricos na rede da Algar Telecom e atua separando o tráfego legítimo do tráfego malicioso.",
    benefits: ANTI_DDOS_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${OMNI_PRO}`,
    title: OMNI_PRO,
    description:
      "O Omni Pro é uma solução que centraliza os canais digitais de atendimento em uma só ferramenta. Com ela você acelera a transformação digital da sua empresa, melhora a experiência do seu cliente e obtém resultados de eficiência e faturamento superiores.",
    benefits: OMNI_PRO_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${CLOUD_PLUS}`,
    title: CLOUD_PLUS,
    description:
      "Uma solução de infraestrutura como serviço, de forma estável, mensurável e dinâmica e de alto desempenho. Gerencie sua nuvem por intermédio de um painel que otimiza, facilita e simplifica a sua visualização. Atendimento próximo e diferenciado com fatura única em moeda nacional.",
    benefits: CLOUD_PLUS_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${LINK_DEDICADO}`,
    title: LINK_DEDICADO,
    description:
      "Tenha uma conexão exclusiva, dedicada e de alta velocidade à favor da sua empresa.",
    benefits: LINK_DEDICADO_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
  {
    id: `product - ${SD_WAN}`,
    title: SD_WAN,
    description:
      "Entregue o melhor do Wi-Fi, trazendo inteligência digital para o seu negócio e se conectando com seus clientes de maneira fácil, rápida e acessível.",
    benefits: SD_WAN_DATA,
    cardOptions: {
      showBorder: false,
      showRibbon: false,
      color: "transparent",
    },
  },
]

import * as React from "react"

function SvgSegurancaV2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h52v52H0z" />
        <g fill="#1E333B">
          <path d="M45.717 31.39c0 6.64-16.698 16.97-19.508 18.32-3.2-1.417-19.492-11.28-19.492-19.404V10.74c5.358-.589 15.937-5.028 19.508-8.122 3.482 3.06 13.71 7.584 19.492 8.133v20.638zM46.8 8.64c-5.528 0-18.01-5.828-19.686-8.208-.405-.575-1.366-.575-1.772 0-1.726 2.452-14.774 8.207-19.709 8.207A1.083 1.083 0 004.55 9.722v20.584c0 10.594 20.24 21.309 21.387 21.616a1.097 1.097 0 00.56 0c.951-.254 21.386-11.758 21.386-20.533V9.722c0-.599-.485-1.083-1.083-1.083z" />
          <path d="M37.046 16.668l2.802 2.8-17.045 16.88-8.067-8.331 2.814-2.815 4.65 4.651a1.083 1.083 0 001.529.004l13.317-13.19zm-18.73 6.237a1.082 1.082 0 00-1.532 0l-4.334 4.333a1.083 1.083 0 00-.012 1.519l9.57 9.885c.201.21.477.327.766.33h.012c.285 0 .56-.112.763-.312l18.597-18.417a1.087 1.087 0 00.003-1.536l-4.334-4.334a1.083 1.083 0 00-1.527-.004l-13.317 13.19-4.656-4.654z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgSegurancaV2

import React from 'react';
import classnames from 'classnames';

import styles from './discount.module.css';

const Discount = ({ value }) => {

  const defaultClasses = classnames('db pa0 ma0 f20-l f20-m f12 lh-solid fw5 fw6-ns')
  const discountClasses = classnames(defaultClasses, 'harlequin f120-l f120-m f60 fw7', styles.discount)

  const untilClasses = classnames(defaultClasses, styles.until)

  return (
    <h3 className="ma0 h30 white mt24-ns">
      <span className={untilClasses}>COM</span>
      <span className={discountClasses}>{value}<span className="f60-l f60-m f32">%</span></span>
      <span className={defaultClasses}>DE DESCONTO</span>
    </h3>
  )

}

export default Discount;
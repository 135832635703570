import * as React from "react"

function SvgFlexibilidadeBarras(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="flexibilidade-barras_svg__a"
          d="M48.309.86c.609 0 1.066.609 1.066 1.066v41.437c0 .61-.457 1.067-1.066 1.067H1.69c-.609 0-1.066-.457-1.066-1.22V1.927C.624 1.47 1.081.86 1.69.86zm-1.067 2.132H2.758v39.305h44.484V2.992zm-9.597 9.598v8.683c1.828.457 3.351 2.133 3.351 4.114a4.251 4.251 0 01-3.2 4.113v10.816h-2.132V29.5a4.252 4.252 0 01-3.2-4.113 4.253 4.253 0 013.2-4.114V12.59h1.98zm-23.31 0v4.418a4.25 4.25 0 013.2 4.113 4.25 4.25 0 01-3.2 4.113v14.93h-1.98v-14.93a4.25 4.25 0 01-3.199-4.113 4.251 4.251 0 013.2-4.113V12.59h1.98zm11.731 0v14.93a4.25 4.25 0 013.2 4.113 4.251 4.251 0 01-3.2 4.113v4.418h-2.132v-4.418a4.251 4.251 0 01-3.2-4.113 4.251 4.251 0 013.2-4.113V12.59h2.132zM25 29.5c-1.219 0-2.133.914-2.133 2.133 0 1.219.914 2.133 2.133 2.133 1.219 0 2.133-.914 2.133-2.133 0-1.219-.914-2.133-2.133-2.133zm11.73-6.398c-1.218 0-2.132.914-2.132 2.132 0 1.22.914 2.133 2.132 2.133 1.067 0 1.98-.914 2.133-2.133 0-1.218-.914-2.132-2.133-2.132zm-23.46-4.114c-1.22 0-2.133.914-2.133 2.133 0 1.219.914 2.133 2.133 2.133 1.218 0 2.132-1.067 2.132-2.133 0-1.219-.914-2.133-2.132-2.133zm32.906-11.73V9.39H3.824V7.258h42.352zm-39.153-3.2v2.133H4.891V4.06h2.132zm3.2 0v2.133H8.09V4.06h2.133zm3.199 0v2.133h-2.133V4.06h2.133z"
        />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="flexibilidade-barras_svg__b" fill="#fff">
          <use xlinkHref="#flexibilidade-barras_svg__a" />
        </mask>
        <use
          fill="#505050"
          fillRule="nonzero"
          xlinkHref="#flexibilidade-barras_svg__a"
        />
        <g fill="#000" mask="url(#flexibilidade-barras_svg__b)">
          <path d="M-1-3h52v52H-1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgFlexibilidadeBarras

import * as React from "react"

function SvgProtecao(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="protecao_svg__a"
          d="M10.113.5l1.8.654c2.617.982 4.744 3.436 5.889 6.544 1.309 3.763.982 8.016-.818 11.451-1.309 2.29-1.472 4.744-.654 6.217.49.818 1.308 1.145 2.454 1.145 1.145 0 1.963-.327 2.617-1.145.982-1.473.654-3.926 0-5.562l-.982-2.29 2.29.981c4.09 1.636 6.38 5.071 6.38 9.161-.163 3.926-1.144 8.18-8.997 11.288l-.818-1.963c6.217-2.454 7.525-5.562 7.525-9.325 0-2.617-1.145-4.744-3.108-6.216.327 1.8.164 3.599-.818 5.07-.654.982-1.8 2.128-4.253 2.128-1.8 0-3.272-.818-4.09-2.29-1.309-2.127-.981-5.236.655-8.18 1.635-2.945 1.963-6.544.818-9.816-.655-1.8-1.8-3.271-2.945-4.417.654 6.38-2.945 10.143-5.726 13.251-1.309 1.473-2.617 2.781-3.272 4.254-.981 2.126-1.472 3.926-1.472 6.052 0 3.927 2.618 7.526 6.544 9.325l-.818 1.8C3.57 36.49.625 32.073.625 27.492c0-2.453.49-4.744 1.636-7.034.818-1.8 2.29-3.272 3.762-4.744 3.109-3.435 6.38-6.87 4.908-13.578L10.113.5zm39.262 8.834v29.61H26.799V36.98l7.198-.001v-7.361h-2.944v-1.963h9.16v-7.198H27.945v-1.963l6.052-.001v-7.198l-13.25.001V9.334h28.628zM35.96 36.98h11.452l-.001-7.361H35.96v7.361zm11.451-16.522h-5.234v7.198h5.234v-7.198zm0-9.161l-11.451-.001v7.198h11.451v-7.197z"
        />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="protecao_svg__b" fill="#fff">
          <use xlinkHref="#protecao_svg__a" />
        </mask>
        <use fill="#1E333B" fillRule="nonzero" xlinkHref="#protecao_svg__a" />
        <g fill="#000" mask="url(#protecao_svg__b)">
          <path d="M-1-6h52v52H-1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgProtecao

import React from 'react';
import classnames from 'classnames';

const Offer = () => {

  const defaultClasses = classnames('white db pa0 ma0 f32-m f32-l f20 fw8');
  const harlequinClasses = classnames(defaultClasses, 'harlequin');

  return (
    <h2 className="ma0 pa0 mt40-ns mt24">
      <span className={harlequinClasses}>INTERNET FIBRA  <span className="white">+</span></span>
      <span className={defaultClasses}>TELEFONE FIXO</span>
      <span className={harlequinClasses}>E MUITO MAIS</span>
    </h2>
  )
}

export default Offer;
import * as React from "react"

function SvgWhatsappIlimitado(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={37}
      viewBox="0 0 42 37"
      {...props}
    >
      <defs>
        <style>{".whatsapp-ilimitado_svg__prefix__cls-2{fill:#041a29}"}</style>
      </defs>
      <g
        id="whatsapp-ilimitado_svg__prefix__Grupo_93"
        data-name="Grupo 93"
        transform="translate(-1344.343 -58.731)"
      >
        <path
          id="whatsapp-ilimitado_svg__prefix__Caminho_235"
          d="M1551.666 304.5a5.56 5.56 0 00-3.828-1.944 3.845 3.845 0 00-.384-.014c-.511-.021-4.511 0-6.134 5.345-1.269 4.176-4.036 4.1-4.348 4.076h-.208a3.815 3.815 0 113.191-6.146.89.89 0 001.192.2.9.9 0 00.227-1.3 5.606 5.606 0 10-4.688 9.029h.348c.73 0 4.449-.258 6-5.349 1.267-4.171 4.037-4.1 4.349-4.076h.034c.1 0 .192 0 .288.011a3.815 3.815 0 11-3.305 6.14.89.89 0 00-1.193-.205.895.895 0 00-.227 1.3 5.6 5.6 0 108.686-7.074z"
          data-name="Caminho 235"
          transform="translate(-166.679 -218.019)"
          fill="#00a750"
        />
        <path
          id="whatsapp-ilimitado_svg__prefix__Caminho_236"
          d="M1420.334 134.524a12.264 12.264 0 01-6.29-2.345 17.182 17.182 0 01-6.372-7.786 10.665 10.665 0 01-.719-3.038 4.012 4.012 0 01.663-2.654 5.93 5.93 0 012.616-2.276 1.712 1.712 0 012.307.73c.525.843 1.039 1.694 1.557 2.542.04.065.076.133.113.2.7 1.265.6 1.856-.48 2.8-.3.26-.6.515-.917.751a.386.386 0 00-.13.587 24.544 24.544 0 001.43 2.065 10.712 10.712 0 003.019 2.557c.634.352.636.34 1.09-.2.294-.352.585-.708.9-1.04a1.567 1.567 0 011.979-.375c.286.139.57.285.842.449.775.466 1.543.943 2.315 1.414a1.713 1.713 0 01.746 2.478 5.32 5.32 0 01-4.059 3.14 4.366 4.366 0 01-.61.001zm.214-1.5a3.942 3.942 0 003.118-2.38.464.464 0 00-.166-.4c-.916-.577-1.85-1.125-2.77-1.7a.464.464 0 00-.711.129c-.356.449-.734.882-1.116 1.309a1.333 1.333 0 01-1.53.4 7.348 7.348 0 01-1.448-.672 12.83 12.83 0 01-3.509-3.265 13.657 13.657 0 01-1.257-2.168 1.425 1.425 0 01.461-1.814c.36-.305.726-.6 1.085-.909.457-.391.467-.4.166-.909-.507-.855-1.045-1.691-1.539-2.553-.185-.323-.383-.368-.678-.2a4.52 4.52 0 00-1.925 1.961 2.651 2.651 0 00-.241 1.1 7.567 7.567 0 00.655 2.966 15.752 15.752 0 006.824 7.649 9.859 9.859 0 004.581 1.455z"
          className="whatsapp-ilimitado_svg__prefix__cls-2"
          data-name="Caminho 236"
          transform="translate(-55.807 -51.452)"
        />
        <path
          id="whatsapp-ilimitado_svg__prefix__Caminho_237"
          d="M1363.114 88.783a.748.748 0 00-.865-.737 13.716 13.716 0 01-9.457-2.022 1.4 1.4 0 00-1.456-.1c-1.511.673-3.043 1.3-4.568 1.943-.081.034-.164.061-.326.121.638-1.731 1.226-3.4 1.872-5.042a1.5 1.5 0 00-.156-1.526 13.767 13.767 0 013.155-18.433 13.1 13.1 0 019.291-2.684 14.412 14.412 0 0112.8 10.707 13.715 13.715 0 01-1.85 11.232.76.76 0 00.628 1.187.764.764 0 00.631-.336 15.163 15.163 0 002.333-11.265 15.869 15.869 0 00-18.161-12.885 14.3 14.3 0 00-10.981 8.139c-2.468 5.033-2.088 10.037.681 14.877a.8.8 0 01.068.771q-1.169 3.11-2.307 6.231a1.285 1.285 0 00-.107.551c.061.553.582.791 1.165.544q3.059-1.3 6.111-2.607a.65.65 0 01.667.041 15.431 15.431 0 007.818 2.186 18.207 18.207 0 002.368-.16.743.743 0 00.639-.739z"
          className="whatsapp-ilimitado_svg__prefix__cls-2"
          data-name="Caminho 237"
        />
      </g>
    </svg>
  )
}

export default SvgWhatsappIlimitado

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import _get from "lodash/get"

import styles from "./advantage.module.css"
import { Icon } from "components/Icons"

const Advantage = ({ icon, title, description }) => {
  const cardClasses = classnames("bg-white pt16 pl16 pr8 mr32", styles.card)
  const titleClass = classnames("f16 fw7 lh-copy mb0 papa-green", styles.title)
  const descriptionClass = classnames("f12 lh-copy mb0 papa-green", styles.descriptionClass)
  const icons = _get(icon, "props.name")

  return (
    <div className={cardClasses}>
      <Icon className="mb12" name={icons} aria-label="" role="img" />
      <h4 className={titleClass}>{title}</h4>
      <p className={descriptionClass}>{description}</p>
    </div>
  )
}

Advantage.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Advantage

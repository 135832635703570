import React, { Component } from "react"
import PropTypes from "prop-types"
import { Icon } from "../Icons"
import classnames from "classnames"

class Alert extends Component {
  render() {
    const { icon, title, desc, children, secondDesc } = this.props
    const descHtml = { __html: desc }
    const secondDescHtml = { __html: secondDesc }
    const classNames = classnames("", {
      "f14 lh-copy tc": secondDesc,
      "f14 lh-copy tc pb12": !secondDesc,
    })

    return (
      <section>
        <div className="ph32 mt32 pb32 flex flex-column bb b--black-30">
          <div className="tc">
            <Icon name={icon} />
          </div>
          <div>
            <p className="tc fw6 f14">{title}</p>
            <p className={classNames} dangerouslySetInnerHTML={descHtml} />
            <p
              className="f14 lh-copy tc pb12"
              dangerouslySetInnerHTML={secondDescHtml}
            />
          </div>
          {children}
        </div>
      </section>
    )
  }
}

Alert.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  children: PropTypes.node,
  secondDesc: PropTypes.string,
}

export default Alert

import * as React from "react"

function SvgInternetpage(props) {
  return (
    <svg
      id="internet_page_svg__Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 72.7 75.4"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".internet_page_svg__st0{fill:none;stroke:#1e333b;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="internet_page_svg__st0"
        d="M57.6 56.2H15.1c-1.3 0-2.4-1.1-2.4-2.4v-35c0-1.3 1.1-2.4 2.4-2.4h42.5c1.3 0 2.4 1.1 2.4 2.4v35c0 1.3-1.1 2.4-2.4 2.4z"
      />
      <path
        className="internet_page_svg__st0"
        d="M26.7 53.7h-8.8c-1.3 0-2.4-1.1-2.4-2.4V40.2c0-1.3 1.1-2.4 2.4-2.4h8.8c1.3 0 2.4 1.1 2.4 2.4v11.1c0 1.3-1.1 2.4-2.4 2.4zM44.6 35.1H17.8c-1.3 0-2.4-1.1-2.4-2.4V21.5c0-1.3 1.1-2.4 2.4-2.4h26.8c1.3 0 2.4 1.1 2.4 2.4v11.1c.1 1.4-1 2.5-2.4 2.5z"
      />
      <path
        d="M52 53.7H38.8c-1.3 0-2.4-1.1-2.4-2.4V32.7c0-1.3 1.1-2.4 2.4-2.4H52c1.3 0 2.4 1.1 2.4 2.4v18.6c.1 1.3-1 2.4-2.4 2.4z"
        fill="none"
        stroke="#00a750"
        strokeMiterlimit={10}
      />
      <path
        className="internet_page_svg__st0"
        d="M18.4 22.6h24.5M18.4 27.1h15.9M18.4 32.1h10.7M18.4 42h8M18.4 45.7h6.4M19 50.6h6.4M38.3 37.7h13.3M38.3 43h8.8M38.3 47.6h12.3"
      />
    </svg>
  )
}

export default SvgInternetpage

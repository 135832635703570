import ax from "./axios-instance"
import _get from "lodash/get"
import _includes from "lodash/includes"
import { serializePayloadUpdateLead } from "./staticPayloads"
import { prospectTypes } from "../modules/EneBot/staticData/staticVars"

const WppContactTypeSegment = {
  BASE_CORP: "C",
  BASE_MPE: "M",
  PROSPECT_CORP: "C",
  PROSPECT_MPE: "M",
  BASE_VAREJO: "M",
}

const validTypes = [
  "BASE_CORP",
  "BASE_MPE",
  "PROSPECT_CORP",
  "PROSPECT_MPE",
  "BASE_VAREJO",
]
const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

const User = {
  queryCustomerDetails: data =>
    ax.get(`/user/${data.documentNumber}/contact`, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
  queryProspectDetails: documentNumber =>
    ax.get(`/lake/client-report/${documentNumber}`),
  postLeadManager: steps => {
    const userData = _get(steps, "[check-user].value", {})
    const { wppContactType, name, birthDate, email, documentNumber } = userData
    // const contactName = _get(steps, "[await-name].value", "")
    const userPhone = _get(steps, "[await-phone].value", "")
    const contactDate = _get(steps, "[await-when].value", "")
    const momentOptions = _get(steps, "[ask-moment-options].value", "")
    let momentOptionsLabel = ""

    switch (momentOptions) {
      case "now":
        momentOptionsLabel = "Agora via Hangout"
        if (contactDate !== "") {
          momentOptionsLabel += " 2ª vez pq consultor NÃO RESPONDEU"
        }

        break
      case "now-whatsapp":
        momentOptionsLabel = "Agora via WhatsApp"
        break
      default:
        momentOptionsLabel = "Quero agendar"
        break
    }

    if (process.env.NODE_ENV === "development") {
      // return null
    }

    if (!_includes(validTypes, wppContactType)) {
      return null
    }

    const crmLeads = {
      details: {
        origin: "EMPREENDEDOR DIGITAL",
        urlPage: window.location.href,
        // note: `Contato: ${contactName} | Opcao: ${momentOptionsLabel}`,
        note: `Opcao: ${momentOptionsLabel}`,
      },
      customerContact: {
        customerType: WppContactTypeSegment[wppContactType],
        documentNumber: documentNumber,
        name,
        birthDate: "",
        dateFoundation: birthDate,
        identityDocument: "",
        issuingAuthority: "",
        motherName: "",
        email,
        fixedPhone: "",
        cellPhone: userPhone,
      },
    }
    return ax.post("/leads/new-lead-call-me", crmLeads, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  },
  postLeadManagerSimple: (userData, userDocument) => {
    const { wppContactType, prospect } = userData
    let {
      name,
      birthDate,
      email,
      documentNumber,
      agentName,
      agentPhone,
      agentEmail,
    } = userData
    const prospectDetails = _get(prospect, "details", {}) || {}
    const {
      MUNICIPIO,
      UF,
      LOGRADOURO,
      NUMERO,
      BAIRRO,
      CEP,
      TIPO_LOGRADOURO,
      DDD_1,
      TELEFONE_1,
    } = prospectDetails

    if (process.env.NODE_ENV === "development") {
      //return null
    }

    if (!_includes(validTypes, wppContactType)) {
      return null
    }

    let address = [
      {
        type: "CORRESPONDENCIA",
        stateName: UF,
        city: MUNICIPIO,
        streetPostcode: CEP,
        streetName: LOGRADOURO,
        streetNumber: NUMERO,
        neighbourhood: BAIRRO,
        complement: TIPO_LOGRADOURO,
      },
    ]
    //ele é prospect?
    if (_includes(prospectTypes, wppContactType)) {
      //"É PROSPECT"
      name = _get(userData, "prospect.details.RAZAO_SOCIAL", "")
      email = _get(userData, "prospect.details.EMAIL", "")
      birthDate = _get(userData, "prospect.details.DATA_INICIO_ATIV", "")
      documentNumber = userDocument

      if (wppContactType === "PROSPECT_MPE") {
        agentName = ""
        agentEmail = ""
        agentPhone = ""
      }
    }

    const crmLeads = {
      details: {
        origin: "EMPREENDEDOR DIGITAL",
        urlPage: window.location.href,
        //note: `Contato: ${contactName} `,
      },
      customerContact: {
        customerType: WppContactTypeSegment[wppContactType],
        segment: wppContactType,
        documentNumber: documentNumber,
        name,
        birthDate: "",
        dateFoundation: birthDate,
        identityDocument: "",
        issuingAuthority: "",
        motherName: "",
        email,
        fixedPhone: "",
        cellPhone: "", //TODO: melhoria do telefone digitado no chabot.
        cellPhone2: `(${DDD_1}) ${TELEFONE_1}`,
        NomeConsultor: agentName,
        TelefoneConsultor: agentPhone,
        EmailConsultor: agentEmail,
        Interesse: "solução de interesse",
      },
      address,
    }

    return ax.post("/leads/new-lead-call-me", crmLeads, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  },
  postLeadManagerSimpleByForm: ({ payload }) => {
    const fields = [
      {
        fieldName: "URL_PAGE_CURRENT",
        stringFieldValue: window.location.href,
        fieldType: "S",
      },
    ]

    const crmFormLeads = {
      origin: {
        type: "Empresas",
      },
      worklogs: [
        {
          restrictionId: 1,
          text: "Lead gerada pela integração Sensedia AlgarCRM",
        },
      ],
      fields,
      leadType: {
        id: 3,
      },
      requestChannel: {
        id: 5,
      },
      originIntegrationId: "empresa-digital",
      originIntegrationType: "MPE-ON",
      contactEmail: _get(payload, "email", ""),
      contactPhoneNumber: _get(payload, "phoneFix", ""),
      contactName: _get(payload, "name", ""),
      customer: {
        primaryDocumentNumber: _get(payload, "cpfCnpj", ""),
        email: _get(payload, "email", ""),
        name: _get(payload, "name", ""),
      },
      address: {
        state: {
          name: _get(payload, "state", ""),
        },
        localityName: _get(payload, "city", ""),
        countyName: _get(payload, "city", ""),
        streetNumber: 0,
        neighbourhood: {
          name: "Não informado",
        },
      },
    }
    return ax.post("leads", crmFormLeads)
  },

  updateLeadManagerSimple: userData => {
    const { wppContactType, idLead } = userData

    if (process.env.NODE_ENV === "development") {
      //return null
    }

    if (!_includes(validTypes, wppContactType)) {
      return null
    }

    const payload = serializePayloadUpdateLead(userData)

    return ax.patch(`/leads/${idLead}/lead`, payload, {
      headers: { "Content-Type": "application/json" },
    })
  },
  getLead: idLead => {
    return ax.get(`/leads/${idLead}/lead`, {
      headers: { "Content-Type": "application/json" },
    })
  },
}

export default User

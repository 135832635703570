import * as React from "react"

function SvgFacilImplementacao(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="facil-implementacao_svg__a"
          d="M46.75.407a.844.844 0 01.151 1.672l-.151.014-5.899-.001v18.536l5.899.002c.414 0 .758.298.829.69l.014.152a.844.844 0 01-.692.83l-.151.013h-6.74a.844.844 0 01-.83-.691l-.013-.152v-1.686l-4.213.001a.844.844 0 01-.83-.691l-.013-.152v-1.686l-7.583.001a.842.842 0 01-.152-1.672l.152-.013h7.582v-1.686h-4.212a.841.841 0 01-.152-1.671l.152-.013h4.212v-1.686h-9.267a.842.842 0 01-.152-1.672l.152-.013h9.267V7.146l-5.897.001a.843.843 0 01-.151-1.672l.151-.013h5.897l.001-1.685c0-.414.298-.758.691-.83l.152-.013h4.212V1.25c0-.414.299-.758.692-.829l.151-.014h6.741zm-38.76 0c.414 0 .758.298.83.692l.013.151v1.684l4.213.001c.414 0 .758.298.83.691l.013.152v1.684l7.583.001a.842.842 0 01.152 1.672l-.152.013h-7.584v1.684l5.056.001a.843.843 0 01.152 1.672l-.152.014h-5.056v1.684h9.27a.843.843 0 01.151 1.673l-.152.013h-9.269v1.684l6.742.001a.842.842 0 01.152 1.672l-.152.013h-6.742v1.685a.843.843 0 01-.69.83l-.152.013H8.832l.001 1.685a.844.844 0 01-.69.83l-.152.013H1.25a.843.843 0 01-.151-1.672l.151-.013 5.897-.002V2.092H1.25a.841.841 0 01-.829-.69L.407 1.25c0-.414.298-.758.692-.829L1.25.407h6.74zM39.167 4.62l-3.37.001v13.481h3.37V4.62zm-26.963.001h-3.37V18.1h3.37V4.621z"
        />
      </defs>
      <g transform="translate(2)" fill="none" fillRule="evenodd">
        <mask id="facil-implementacao_svg__b" fill="#fff">
          <use xlinkHref="#facil-implementacao_svg__a" />
        </mask>
        <use
          fill="#424242"
          fillRule="nonzero"
          xlinkHref="#facil-implementacao_svg__a"
        />
        <g fill="#000" mask="url(#facil-implementacao_svg__b)">
          <path d="M-2-15h52v52H-2z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgFacilImplementacao

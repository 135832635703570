import _get from 'lodash/get'
import _includes from "lodash/includes"
import { prospectTypes } from "../modules/EneBot/staticData/staticVars"

export function serializePayloadUpdateLead(userData) {
  const { wppContactType, prospect } = userData
  let { name, email, updatedCellphone, updatedEmail } = userData
  const prospectDetails = _get(prospect, 'details', {}) || {}
  const {
    MUNICIPIO,
    UF,
    LOGRADOURO,
    NUMERO,
    BAIRRO,
    CEP,
    TIPO_LOGRADOURO,
    DDD_1,
    TELEFONE_1,
  } = prospectDetails

  if (_includes(prospectTypes, wppContactType)) {
    //"É PROSPECT"
    name = _get(userData, "prospect.details.RAZAO_SOCIAL", "")
    email = _get(userData, "prospect.details.EMAIL", "")
  }

  return {
    customerName: name,
    fixedPhone: '',
    cellPhone: updatedCellphone || `(${DDD_1}) ${TELEFONE_1}`,
    email: updatedEmail || email,
    note: 'Updating lead',
    addresses: [
      {
        type: "CORRESPONDENCIA",
        streetName: LOGRADOURO,
        streetNumber: NUMERO,
        streetPostcode: CEP,
        countryName: '',
        stateName: UF,
        city: MUNICIPIO,
        neighbourhood: BAIRRO,
        localityName: MUNICIPIO,
        complement: TIPO_LOGRADOURO,
      }
    ]
  }
}

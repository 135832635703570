import React from "react"
import cx from "classnames"
import { Row, Col } from "antd"

import CustomButton from "../../CustomButton"
import { Icon } from "../../Icons"
import { dataFooter } from "src/utils/data"
import styles from "./styles.module.css"
import { Link } from "gatsby"

const Footer = props => {
  const { onClick } = props

  const titleClass = cx(
    "white f32 lh-title tc mb40 relative b center",
    styles.title
  )
  const containerFooterClasses = cx(
    "center white",
    styles.containerFooterClasses
  )
  const buttonClass = cx("small ba bw1 see-more black-pearl", styles.button)
  const descriptionClass = cx(
    "f12 lh-copy mb24 tc w-20 center",
    styles.description
  )
  const containerLogoClass = cx("tc flex justify-center", styles.containerLogo)

  return (
    <footer className={styles.footerClass}>
      <div className={containerFooterClasses}>
        <Row justify="center" lg={18}>
          <Col span="24">
            <h2 className={titleClass}>{dataFooter.title}</h2>
            <p className={descriptionClass}>{dataFooter.description}</p>
          </Col>
          <Col span={24} md={12} lg={12}>
            <div className="flex justify-center">
              <CustomButton
                text={dataFooter.textButton}
                color="supernova"
                className={buttonClass}
                data-value={dataFooter.textButton}
                data-tracking-id="ABOUT_SOLUTIONS"
                gtmClass="gtm-speak-to-consultant"
                onClick={onClick}
              />
            </div>
            <div style={{ textAlign: "center", marginBottom: 4 }}>
              <Link
                to="https://algartelecom.com.br/AreaCliente/autenticacao"
                target="_blank"
                className="white f12 link-focus"
              >
                <span tabIndex="-1">{dataFooter.textLoggedArea}</span>
              </Link>
            </div>
            <div style={{ textAlign: "center", marginBottom: 16 }}>
              <Link
                to="https://algartelecom.com.br/empresas/atendimento/central_de_atendimento"
                target="_blank"
                className="white f12 link-focus"
              >
                <span tabIndex="-1">{dataFooter.textSAC}</span>
              </Link>
            </div>
            <div className={containerLogoClass} tabIndex="-1">
              <h1 tabIndex="-1">
                <Link
                  to="https://www.algartelecom.com.br/empresas"
                  target="_blank"
                  aria-label="Algar Telecom Empresas"
                  className="link-focus"
                >
                  <Icon
                    tabIndex="-1"
                    name="LogoAlgarWhite"
                    style={{ width: 120, height: 47 }}
                  />
                </Link>
              </h1>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  )
}

export default Footer

import * as React from "react"

function SvgPriceTag(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={78}
      height={72}
      viewBox="0 0 78 72"
      {...props}
    >
      <defs>
        <path
          id="price-tag_svg__a"
          d="M67.8.618a2.078 2.078 0 012.957 0c.817.824.817 2.16.001 2.983l-6.09 6.144c.178.378.322.776.425 1.192l4.675 19.093a6.395 6.395 0 01-1.65 6.01L34.363 70.15a6.242 6.242 0 01-8.871 0L1.835 46.283c-2.446-2.468-2.446-6.483 0-8.95L35.645 3.28a6.272 6.272 0 015.958-1.664l18.925 4.717c.413.103.808.249 1.182.429zM40.086 5.647c-.549 0-1.088.217-1.483.617L4.792 40.317a2.123 2.123 0 000 2.983l23.656 23.866a2.082 2.082 0 002.958 0l33.755-34.11c.516-.52.727-1.288.55-2.003L61.322 13.12l-5.036 5.08c1.111 2.352.704 5.258-1.224 7.202a6.221 6.221 0 01-4.435 1.85 6.224 6.224 0 01-4.436-1.85c-2.446-2.468-2.446-6.483 0-8.95a6.246 6.246 0 017.14-1.235l5.035-5.08-17.777-4.428a2.098 2.098 0 00-.503-.061zM28.45 46.284a6.23 6.23 0 018.871 0c2.446 2.468 2.446 6.482 0 8.95a6.231 6.231 0 01-8.871 0c-2.447-2.468-2.447-6.483 0-8.95zm5.914 2.983a2.076 2.076 0 00-2.957 0 2.125 2.125 0 000 2.983c.817.825 2.14.824 2.957 0a2.125 2.125 0 000-2.983zm10.12-12.538a2.096 2.096 0 012.308 1.864c.128 1.158-.7 2.2-1.847 2.33L18.33 43.906a2.099 2.099 0 01-2.31-1.864 2.106 2.106 0 011.848-2.33zM25.49 25.4a6.241 6.241 0 018.872 0c2.446 2.468 2.446 6.482 0 8.95a6.243 6.243 0 01-8.872 0c-2.445-2.468-2.445-6.482 0-8.95zm5.915 2.983a2.081 2.081 0 00-2.957 0c-.815.823-.815 2.16 0 2.983s2.142.823 2.957 0a2.125 2.125 0 000-2.983zm19.221-9.566c-.536 0-1.07.205-1.479.617a2.127 2.127 0 000 2.983 2.081 2.081 0 002.957 0 2.125 2.125 0 000-2.983 2.07 2.07 0 00-1.478-.617z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse
          cx={50.212}
          cy={27.443}
          fill="#FBBD0C"
          rx={27.203}
          ry={27.443}
        />
        <mask id="price-tag_svg__b" fill="#fff">
          <use xlinkHref="#price-tag_svg__a" />
        </mask>
        <use fill="#000" xlinkHref="#price-tag_svg__a" />
        <g fill="#28384A" mask="url(#price-tag_svg__b)">
          <path d="M0-.24h78V72H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgPriceTag

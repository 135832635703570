import * as React from "react"

function SvgOtimizacao(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={41}
      viewBox="0 0 34 41"
      {...props}
    >
      <defs>
        <style>{".otimizacao_svg__prefix__cls-1{fill:#041a29}"}</style>
      </defs>
      <g
        id="otimizacao_svg__prefix__ZnWnru_tif"
        transform="translate(-684.315 -490.047)"
      >
        <g
          id="otimizacao_svg__prefix__Grupo_98"
          data-name="Grupo 98"
          transform="translate(684.315 490.047)"
        >
          <g id="otimizacao_svg__prefix__Grupo_97" data-name="Grupo 97">
            <path
              id="otimizacao_svg__prefix__Caminho_247"
              d="M696.287 530.311a17.747 17.747 0 005.037.735 17.166 17.166 0 0014.359-7.725 16.671 16.671 0 00-3.889-22.226l-.172-.133 1.44-2.327.212.126.447.268.03.018c.347.209.665.4.989.585a.791.791 0 001.249-.309c.593-.94 1.2-1.916 1.793-2.9a.75.75 0 00-.3-1.221c-1.576-.949-3.12-1.87-4.589-2.739a.78.78 0 00-1.25.283 165.74 165.74 0 00-1.819 2.936.739.739 0 00.294 1.192c.326.2.657.4.994.6l.019.011.48.285.212.127-1.418 2.286-.211-.127a17.394 17.394 0 00-7.882-2.437l-.231-.015v-2.385h.493a3.778 3.778 0 00.449-.006l.245-.022a4.018 4.018 0 00.85-.13 2.562 2.562 0 001.753-2.712 2.522 2.522 0 00-2.395-2.263 54.474 54.474 0 00-4.28 0 2.559 2.559 0 00-2.427 2.553 2.61 2.61 0 002.531 2.561c.227.01.459.007.683 0h.589v2.39l-.228.018a17.217 17.217 0 00-11.113 4.968 16.093 16.093 0 00-4.905 11.007 16.667 16.667 0 0011.961 16.728zm15.067-34.357l1.236-2 3.676 2.187-1.231 2.01zm-12.112-2.193a1.037 1.037 0 01-.991-1.014 1.071 1.071 0 01.655-1.143 1.74 1.74 0 01.685-.145c1.178-.011 2.356-.012 3.5 0 .671.006 1.08.249 1.215.724a1.351 1.351 0 01-.125 1.1 1.6 1.6 0 01-1.036.5 7.157 7.157 0 01-.757.034c-.176 0-.352 0-.525-.008s-.349-.007-.516-.007h-.243v-.029h-.381c-.482.008-.983.014-1.481-.012zm-9 9.771a15.656 15.656 0 0111.049-4.522h.1a15.509 15.509 0 0110.889 4.4 15.162 15.162 0 01.088 21.662 15.578 15.578 0 01-11.021 4.5h-.031a15.6 15.6 0 01-10.923-4.382 15.205 15.205 0 01-.154-21.662z"
              className="otimizacao_svg__prefix__cls-1"
              data-name="Caminho 247"
              transform="translate(-684.315 -490.047)"
            />
            <path
              id="otimizacao_svg__prefix__Caminho_248"
              d="M730.161 561.122a12.914 12.914 0 00-3.849-9.192 13.133 13.133 0 00-9.319-3.818h-.044a13.03 13.03 0 00-13.191 13.08 12.825 12.825 0 003.874 9.2 13.167 13.167 0 009.294 3.809h.049a13.161 13.161 0 0013.186-13.079zm-4.352 7.687l-.192-.16c-1.188-.989-1.414-.867-1.684-.585-.3.31-.394.509.61 1.628l.17.189-.2.162a11.927 11.927 0 01-6.56 2.686l-.275.029V569.845c-.006-.531-.263-.839-.7-.845-.459 0-.725.3-.742.817-.017.487-.013.985-.01 1.467v1.463l-.273-.027a12.02 12.02 0 01-6.611-2.729l-.225-.184.22-.19c.078-.068.157-.132.233-.2a4.147 4.147 0 00.421-.377.709.709 0 00.011-1.031.7.7 0 00-1.009.014 2.85 2.85 0 00-.348.408c-.065.085-.133.175-.209.269l-.191.234-.194-.231a11.344 11.344 0 01-2.783-6.584l-.023-.266h2.709a1.311 1.311 0 00.921-.266.592.592 0 00.162-.442c0-.472-.372-.713-1.093-.715h-2.703l.023-.266a11.479 11.479 0 012.78-6.58l.2-.237.191.241c.064.08.123.16.18.238a3.032 3.032 0 00.335.405.707.707 0 001.068.043.736.736 0 00-.073-1.069 5.727 5.727 0 00-.366-.34l-.016-.014a7.632 7.632 0 01-.2-.181l-.208-.189.216-.179a11.785 11.785 0 016.58-2.721l.273-.027v2.555a2.907 2.907 0 00.061.632.66.66 0 00.755.562.7.7 0 00.635-.7c.014-.552.013-1.114.012-1.658v-.793c0-.066.006-.132.013-.212l.03-.371.25.022a11.54 11.54 0 016.519 2.664l.172.141-.124.183a2.882 2.882 0 01-.4.451c-.26.251-.465.45-.419.775a.935.935 0 00.216.475c.275.291.413.437 1.672-.623l.192-.161.161.192a11.539 11.539 0 012.706 6.481l.027.269h-2.12c-.361 0-.733 0-1.092.036a.682.682 0 00.033 1.354c.663.019 1.319.016 2.013.011h1.162l-.025.268a11.554 11.554 0 01-2.7 6.506z"
              data-name="Caminho 248"
              transform="translate(-699.939 -537.045)"
              fill="#00a750"
            />
            <path
              id="otimizacao_svg__prefix__Caminho_249"
              d="M757.58 595.483a.675.675 0 00-.6.715.643.643 0 00.638.652c.68.025 1.358.025 2.014 0a.629.629 0 00.645-.649c.024-1.427.025-2.695 0-3.874a.637.637 0 00-.195-.462.757.757 0 00-.534-.19c-.443.013-.69.334-.7.9s-.005 1.155 0 1.765v1.103h-.825a3.658 3.658 0 00-.443.04z"
              className="otimizacao_svg__prefix__cls-1"
              data-name="Caminho 249"
              transform="translate(-742.563 -571.905)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgOtimizacao

import * as React from "react"

function SvgDadosInsights(props) {
  return (
    <svg
      width={52}
      height={52}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M37.475 12.401c.657 0 1.19.533 1.19 1.191v25.152h1.27a1.19 1.19 0 010 2.38H1.69a1.19 1.19 0 010-2.38h1.27v-13.25a1.19 1.19 0 011.19-1.191h4.76c.657 0 1.19.534 1.19 1.191v13.25h2.38v-8.489a1.19 1.19 0 011.19-1.19h4.76a1.19 1.19 0 011.192 1.19v8.49h2.379V20.732c0-.658.535-1.19 1.191-1.19h4.761c.657 0 1.19.532 1.19 1.19v18.011h2.38V13.592a1.19 1.19 0 011.19-1.19zm-10.712 9.523h-2.38v16.822h2.38V21.924zm-19.042 4.76h-2.38v12.06h2.38v-12.06zm9.521 4.76h-2.38v7.3h2.38v-7.3zm19.044-16.662h-2.38v23.962h2.38V14.782zM35.094.5c1.97 0 3.57 1.602 3.57 3.57a3.573 3.573 0 01-5.547 2.972l-4.137 3.102a3.574 3.574 0 01-4.947 4.288l-4.76 4.762c.223.466.35.988.35 1.539 0 1.97-1.602 3.57-3.572 3.57a3.574 3.574 0 01-3.542-4.01L9.007 18.54a3.564 3.564 0 01-2.477 1.002 3.575 3.575 0 01-3.57-3.571 3.575 3.575 0 013.57-3.57 3.575 3.575 0 013.543 4.01l3.502 1.752a3.562 3.562 0 014.015-.652l4.761-4.761a3.563 3.563 0 01-.35-1.539 3.576 3.576 0 013.573-3.572c.73 0 1.41.221 1.977.6l4.136-3.102A3.574 3.574 0 0135.095.5zM16.051 19.543a1.191 1.191 0 000 2.381 1.191 1.191 0 000-2.38zm-9.52-4.76a1.191 1.191 0 000 2.38 1.191 1.191 0 000-2.38zm19.043-4.762a1.191 1.191 0 101.19 1.191c0-.656-.536-1.191-1.19-1.191zm9.52-7.14a1.19 1.19 0 10.002 2.38 1.19 1.19 0 00-.002-2.38z"
          id="dados-insights_svg__a"
        />
      </defs>
      <g transform="translate(6)" fill="none" fillRule="evenodd">
        <mask id="dados-insights_svg__b" fill="#fff">
          <use xlinkHref="#dados-insights_svg__a" />
        </mask>
        <use fill="#000" xlinkHref="#dados-insights_svg__a" />
        <g mask="url(#dados-insights_svg__b)" fill="#000">
          <path d="M-6-6h52v52H-6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgDadosInsights

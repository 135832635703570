import * as React from "react"

function SvgGlobe(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51.6}
      height={48}
      viewBox="0 0 51.6 48"
      {...props}
    >
      <path
        data-name="Caminho 210"
        d="M45.344 16.679a1.61 1.61 0 10-2.277 0 1.618 1.618 0 002.277 0z"
        fill="#00a750"
      />
      <path
        data-name="Caminho 211"
        d="M48.156 11.58c.8.776-.433 2.015-1.219 1.219a3.868 3.868 0 00-5.472 0 .87.87 0 01-1.219 0c-1.402-2.257 5.441-4.308 7.91-1.219z"
        fill="#00a750"
      />
      <path
        data-name="Caminho 212"
        d="M50.745 9.857c-3.638-3.033-8.817-3.97-12.465-.252a.862.862 0 01-1.219-1.219 10.084 10.084 0 0114.279 0 .857.857 0 01-.595 1.471z"
        fill="#00a750"
      />
      <path
        data-name="Caminho 213"
        d="M48.072 20.508a.862.862 0 00-.977-.715.847.847 0 00-.715.977 22.481 22.481 0 01-21.331 25.878V25.063h11.323c.05 11.941-10.087 17.967-6.52 18.431 4.162-3.285 7.759-9.946 8.243-18.431h4.142a.857.857 0 100-1.713h-4.139c-.342-11.131-7.245-18.3-11.034-21.414a22.348 22.348 0 018.092 2.67.86.86 0 00.836-1.5C20.492-6.014-.446 6.129.007 24.206c.917 34.162 52.35 30.313 48.065-3.698zM25.039 2.531a31.19 31.19 0 015.27 5.089 25.757 25.757 0 016.056 15.72H25.039zM1.741 25.063h8.525c.353 11.135 7.255 18.31 11.044 21.414A22.5 22.5 0 011.741 25.063zm21.585 20.819c-5.966-4.162-11.488-13.2-11.327-20.809h11.327zm0-44.117V23.34H11.989a26.4 26.4 0 016.742-16.526.86.86 0 00-.081-1.209c-2.751-1.229-8.838 12.193-8.384 17.746H1.741A22.5 22.5 0 0123.326 1.765z"
        fill="#041a29"
      />
    </svg>
  )
}

export default SvgGlobe

import * as React from "react"

function SvgDadosIlimitados(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={58.911}
      height={39.132}
      viewBox="0 0 58.911 39.132"
      {...props}
    >
      <g data-name="Grupo 90">
        <path
          d="M49.927 29.079a6.041 6.041 0 00-4.159-2.112 4.222 4.222 0 00-.417-.015c-.555-.023-4.9 0-6.663 5.806-1.378 4.537-4.385 4.453-4.723 4.428h-.226a4.144 4.144 0 113.466-6.676.967.967 0 001.295.222.973.973 0 00.246-1.411 6.09 6.09 0 10-5.092 9.809c.066 0 .155.005.222 0h.161c.793 0 4.833-.28 6.513-5.81 1.376-4.531 4.385-4.453 4.724-4.428h.037c.1 0 .209 0 .313.012a4.145 4.145 0 11-3.591 6.67.967.967 0 00-1.3-.223.972.972 0 00-.247 1.41 6.087 6.087 0 109.436-7.685z"
          data-name="Caminho 228"
          fill="#00a750"
        />
        <path
          d="M56.318 19.536a9.249 9.249 0 00-6.3-2.852l-.926-.04-.1-.922a17.665 17.665 0 00-33.339-6l-.355.706-.781-.124a12.674 12.674 0 00-1.987-.159 12.534 12.534 0 000 25.067h12.558a.933.933 0 00.942-.851.912.912 0 00-.91-.973H12.53a10.71 10.71 0 113.033-20.982h.016a.956.956 0 00.085.021.912.912 0 00.875-.313l.013-.016.033-.046.017-.024.017-.03.008-.014.02-.034.054-.12a15.844 15.844 0 0130.575 5.822.923.923 0 00.067.351l.021.043.036.07.038.061.013.016.054.067.114.109.06.045.033.02.017.011.021.013.037.018.016.008.026.013.044.016.028.011.022.008.051.012.048.012a.9.9 0 00.259.011.932.932 0 00.1-.015 3.714 3.714 0 01.914-.062h.3a7.44 7.44 0 014.025 13.731.9.9 0 00-.414.764.911.911 0 001.394.775 9.235 9.235 0 001.719-14.231z"
          data-name="Caminho 229"
          fill="#041a29"
        />
      </g>
    </svg>
  )
}

export default SvgDadosIlimitados

import * as React from "react"

function SvgLowCost(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={48}
      viewBox="0 0 40 48"
      {...props}
    >
      <defs>
        <style>{".low-cost_svg__prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g
        id="low-cost_svg__prefix__Baixo_custo"
        data-name="Baixo custo"
        transform="translate(-39.973)"
        opacity={0.9}
      >
        <g
          id="low-cost_svg__prefix__Group_56"
          data-name="Group 56"
          transform="translate(39.973)"
        >
          <g id="low-cost_svg__prefix__Group_55" data-name="Group 55">
            <path
              id="low-cost_svg__prefix__Path_227"
              d="M79.182 28h-5.6V.8a.8.8 0 00-.8-.8h-25.6a.8.8 0 00-.8.8V28h-5.6a.794.794 0 00-.58.25.8.8 0 00.02 1.13l19.2 18.4a.815.815 0 00.55.22.8.8 0 00.55-.22l19.2-18.4a.8.8 0 00-.54-1.38zm-19.2 18.09L42.766 29.6h4.411a.8.8 0 00.8-.8V1.6h24v27.2a.8.8 0 00.8.8h4.411z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Path 227"
              transform="translate(-39.973)"
            />
            <path
              id="low-cost_svg__prefix__Rectangle_27"
              d="M0 0h1.599v1.599H0z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Rectangle 27"
              transform="translate(9.605 3.198)"
            />
            <path
              id="low-cost_svg__prefix__Rectangle_28"
              d="M0 0h1.599v1.599H0z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Rectangle 28"
              transform="translate(12.805 3.198)"
            />
            <path
              id="low-cost_svg__prefix__Rectangle_29"
              d="M0 0h1.599v1.599H0z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Rectangle 29"
              transform="translate(16.006 3.198)"
            />
            <path
              id="low-cost_svg__prefix__Rectangle_30"
              d="M0 0h1.599v1.599H0z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Rectangle 30"
              transform="translate(19.207 3.198)"
            />
            <path
              id="low-cost_svg__prefix__Rectangle_31"
              d="M0 0h1.599v1.599H0z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Rectangle 31"
              transform="translate(22.408 3.198)"
            />
            <path
              id="low-cost_svg__prefix__Rectangle_32"
              d="M0 0h1.599v1.599H0z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Rectangle 32"
              transform="translate(25.608 3.198)"
            />
            <path
              id="low-cost_svg__prefix__Rectangle_33"
              d="M0 0h1.599v1.599H0z"
              className="low-cost_svg__prefix__cls-2"
              data-name="Rectangle 33"
              transform="translate(28.809 3.198)"
            />
            <path
              id="low-cost_svg__prefix__Path_228"
              d="M204 121.3a1.335 1.335 0 011.329 1.329h2.668a3.99 3.99 0 00-2.668-3.747V117.3h-2.668v1.579a3.987 3.987 0 001.329 7.745 1.329 1.329 0 11-1.329 1.329H200a3.99 3.99 0 002.668 3.747v1.579h2.668V131.7a3.981 3.981 0 00-1.336-7.735 1.334 1.334 0 010-2.668z"
              data-name="Path 228"
              transform="translate(-183.991 -105.566)"
              fill="#00a750"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgLowCost

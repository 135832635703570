import * as React from "react"

function SvgMediumEmployee(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={111}
      height={129}
      viewBox="0 0 111 129"
      {...props}
    >
      <defs>
        <path id="medium-employee_svg__a" d="M0 2.204h106.234v65.827H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h111v128.903H0z" />
        <path
          fill="#004281"
          d="M15.54 71.192s16.854 2 34.71 10.582c17.857 8.583 36.301-.617 40.699-6.953 4.393-6.342 10.544-3.698 11.403 3.803.853 7.502 1.278 40.49 1.278 40.49l-16.46 5.215H32.186l-19.397-5.216L6.7 90.357l8.842-19.165z"
        />
        <path
          fill="#FFF"
          d="M39.486 14.259c4.576-4.573 9.94-6.796 16.407-6.796 6.466 0 11.837 2.223 16.41 6.796 4.572 4.576 6.796 9.943 6.796 16.406 0 6.47-2.224 11.834-6.796 16.41-4.573 4.577-9.944 6.8-16.41 6.8-6.463 0-11.83-2.227-16.407-6.8-4.572-4.572-6.796-9.94-6.796-16.41 0-6.463 2.224-11.83 6.796-16.406m16.407 47.075c8.429 0 15.726-3.019 21.688-8.98 5.962-5.962 8.98-13.26 8.98-21.689 0-8.425-3.018-15.719-8.98-21.684C71.619 3.019 64.32.001 55.893.001 47.468 0 40.17 3.019 34.208 8.98c-5.961 5.965-8.983 13.26-8.983 21.684 0 8.43 3.022 15.727 8.983 21.688 5.966 5.962 13.26 8.98 21.685 8.98"
        />
        <g transform="translate(3.58 59.3)">
          <mask id="medium-employee_svg__b" fill="#fff">
            <use xlinkHref="#medium-employee_svg__a" />
          </mask>
          <path
            fill="#FFF"
            d="M94.808 56.723c-2.718 2.589-6.33 3.846-11.036 3.846H22.461c-4.712 0-8.318-1.257-11.036-3.842-2.667-2.539-3.963-6.005-3.963-10.592 0-2.388.078-4.744.236-7.004.154-2.22.47-4.658.935-7.247.458-2.556 1.042-4.959 1.736-7.132a32.307 32.307 0 012.71-6.138c1.075-1.89 2.314-3.516 3.682-4.823 1.282-1.228 2.893-2.23 4.794-2.979 1.755-.698 3.731-1.078 5.876-1.139.261.14.727.409 1.482.896 1.53 1.002 3.302 2.14 5.253 3.39 2.206 1.404 5.045 2.675 8.436 3.767 3.466 1.125 7.004 1.69 10.51 1.69 3.512 0 7.05-.565 10.516-1.69 3.394-1.092 6.23-2.363 8.436-3.766a625.41 625.41 0 005.253-3.391c.755-.487 1.22-.756 1.482-.896 2.145.061 4.121.44 5.876 1.14 1.901.747 3.512 1.754 4.794 2.978 1.368 1.307 2.603 2.933 3.685 4.827a32.593 32.593 0 012.707 6.134c.694 2.177 1.282 4.576 1.74 7.132.465 2.593.78 5.035.93 7.247v.004c.162 2.252.24 4.605.24 7 0 4.587-1.295 8.053-3.963 10.588m11.168-18.114c-.172-2.478-.52-5.185-1.031-8.046-.516-2.875-1.182-5.597-1.98-8.085a39.911 39.911 0 00-3.323-7.545c-1.436-2.527-3.122-4.726-5.017-6.534-1.98-1.894-4.4-3.416-7.204-4.53-2.79-1.103-5.883-1.665-9.195-1.665-1.3 0-2.557.537-4.98 2.116a615.09 615.09 0 01-5.196 3.348c-1.665 1.064-3.928 2.06-6.721 2.962-2.718.88-5.486 1.328-8.218 1.328-2.725 0-5.492-.448-8.214-1.328-2.789-.903-5.052-1.898-6.713-2.958a752.804 752.804 0 01-5.2-3.352c-2.424-1.579-3.684-2.116-4.98-2.116-3.312 0-6.406.562-9.199 1.669-2.8 1.11-5.224 2.632-7.2 4.526-1.898 1.812-3.581 4.01-5.017 6.534a40.184 40.184 0 00-3.323 7.545c-.795 2.488-1.464 5.21-1.98 8.085-.512 2.857-.86 5.564-1.028 8.05A107.74 107.74 0 000 46.134C0 52.781 2.112 58.16 6.277 62.13c4.117 3.917 9.56 5.9 16.184 5.9h61.311c6.625 0 12.067-1.983 16.181-5.9 4.168-3.967 6.28-9.35 6.28-15.998 0-2.564-.085-5.096-.257-7.523"
            mask="url(#medium-employee_svg__b)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgMediumEmployee

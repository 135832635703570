import * as React from "react"

function SvgIlimitado(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={54}
      height={27}
      viewBox="0 0 54 27"
      {...props}
    >
      <g data-name="Grupo 79">
        <g data-name="Grupo 81">
          <path
            d="M40.649 25.854a1.083 1.083 0 001.166 1.089 13.506 13.506 0 00-.161-26.9c-.33-.025-.66-.038-.909-.034-.1-.012-10.582-.787-14.778 13.172-3.668 12.2-12.166 11.66-12.615 11.627-.168 0-.336 0-.505-.012a11.315 11.315 0 01.333-22.608 11.11 11.11 0 019.021 4.383 1.073 1.073 0 001.44.252 1.1 1.1 0 00.265-1.592A13.25 13.25 0 0013.352.004a13.5 13.5 0 00-.6 26.973c.2.009.4.013.506.01.011 0 .137.011.359.011 1.885 0 10.664-.691 14.42-13.183 3.668-12.2 12.168-11.66 12.615-11.628.284 0 .566.011.845.032a11.321 11.321 0 01.14 22.546 1.086 1.086 0 00-.985 1.087z"
            data-name="Caminho 213"
            fill="#041a29"
          />
          <path
            d="M36.776 24.164a11.1 11.1 0 01-4.966-3.644 1.072 1.072 0 00-1.428-.242 1.074 1.074 0 00-.273 1.557 13.236 13.236 0 005.93 4.35 1.073 1.073 0 001.4-.737 1.073 1.073 0 00-.663-1.284z"
            data-name="Caminho 214"
            fill="#00a750"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgIlimitado

import * as React from "react"

function SvgEnhancedSecurity(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={33}
      height={41}
      viewBox="0 0 33 41"
      {...props}
    >
      <g data-name="seguranc\u0327a reforc\u0327ada">
        <path
          d="M28.563 15.289V11.03C28.563 4.944 23.151 0 16.537 0S4.51 4.944 4.51 11.03v4.26A5.461 5.461 0 000 20.69v14.833A5.422 5.422 0 005.412 41h22.174A5.422 5.422 0 0033 35.523V20.69a5.385 5.385 0 00-4.437-5.401zm-22.55-4.26c0-5.249 4.735-9.508 10.523-9.508S27.06 5.781 27.06 11.03v4.184H6.013zM31.57 35.6a3.905 3.905 0 01-3.909 3.955H5.412A3.905 3.905 0 011.5 35.6V20.69a3.905 3.905 0 013.909-3.955h22.177a3.905 3.905 0 013.909 3.955V35.6z"
          data-name="Path 202"
          fill="#fff"
        />
        <path
          d="M16.534 18.947a4.012 4.012 0 00-4 4v6a4 4 0 008 0v-6a4.012 4.012 0 00-4-4zm2 10a2 2 0 01-4 0v-6a2 2 0 014 0z"
          data-name="Path 203"
          fill="#00a750"
        />
      </g>
    </svg>
  )
}

export default SvgEnhancedSecurity

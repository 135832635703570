import * as React from "react"

function SvgCustosOtimizados(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={42}
      viewBox="0 0 42 42"
      {...props}
    >
      <defs>
        <style>
          {
            ".custos-otimizados_svg__prefix__cls-1{fill:#041a29}.custos-otimizados_svg__prefix__cls-2{fill:#00a750}"
          }
        </style>
      </defs>
      <g
        id="custos-otimizados_svg__prefix__Grupo_108"
        data-name="Grupo 108"
        transform="translate(-.011 .5)"
      >
        <path
          id="custos-otimizados_svg__prefix__Caminho_266"
          d="M219.1 329.7a.871.871 0 00-.616 1.487.871.871 0 10.616-1.487z"
          className="custos-otimizados_svg__prefix__cls-1"
          data-name="Caminho 266"
          transform="translate(-200.357 -303.179)"
        />
        <path
          id="custos-otimizados_svg__prefix__Caminho_267"
          d="M197.114 17.831a9.166 9.166 0 10-9.165-9.166 9.176 9.176 0 009.165 9.166zm0-16.589a7.424 7.424 0 11-7.424 7.423 7.432 7.432 0 017.424-7.423z"
          className="custos-otimizados_svg__prefix__cls-2"
          data-name="Caminho 267"
          transform="translate(-172.698)"
        />
        <path
          id="custos-otimizados_svg__prefix__Caminho_268"
          d="M40.836 212.63a3.3 3.3 0 00-4.648.37l-4.8 5a3.293 3.293 0 00-2.548-1.214h-9.124a10.489 10.489 0 00-4.108-.829 10.125 10.125 0 00-9.255 5.3 2.464 2.464 0 00-2.2.7l-3.892 3.96a.881.881 0 000 1.229l9.193 9.384a.853.853 0 001.213.007l3.971-3.963a2.577 2.577 0 00.6-2.591l.835-.816a2.385 2.385 0 011.676-.686h10.884a7.318 7.318 0 005.163-2.122l.026-.026c.039-.044.225-.27 1.344-1.626l.091-.11 5.972-7.233a3.4 3.4 0 00-.393-4.734zm-27.411 18.709l-3.355 3.349-7.988-8.154 3.29-3.343a.773.773 0 01.969-.107l.011.006c.057.032.186.164 1.424 1.421l.036.037c1.006 1.021 2.69 2.73 5.607 5.682a.793.793 0 01.006 1.109zm19.147-6.2a5.625 5.625 0 01-3.939 1.6H17.749a4.083 4.083 0 00-2.87 1.174l-.667.652-6.364-6.447a8.437 8.437 0 017.761-4.42 8.8 8.8 0 013.588.757.845.845 0 00.345.073h9.3a1.618 1.618 0 010 3.235h-6.68a.871.871 0 000 1.742h6.68a3.342 3.342 0 003.316-3.359 3.41 3.41 0 00-.027-.419l3.523-3.669c1.518-1.581 1.792-1.866 1.827-1.905a1.587 1.587 0 012.25-.192 1.637 1.637 0 01.187 2.284z"
          className="custos-otimizados_svg__prefix__cls-1"
          data-name="Caminho 268"
          transform="translate(0 -195.287)"
        />
        <path
          id="custos-otimizados_svg__prefix__Caminho_269"
          d="M267.874 47.783a1.461 1.461 0 01-1.045-.613.871.871 0 00-1.333 1.121A3.511 3.511 0 00267 49.374v.938a.871.871 0 101.742 0v-.942a2.53 2.53 0 00-.871-4.9.788.788 0 010-1.576 1.292 1.292 0 01.868.422.871.871 0 101.211-1.252 3.272 3.272 0 00-1.209-.763v-.936a.871.871 0 10-1.742 0v.935a2.53 2.53 0 00.871 4.9.788.788 0 110 1.576z"
          className="custos-otimizados_svg__prefix__cls-2"
          data-name="Caminho 269"
          transform="translate(-243.593 -36.67)"
        />
      </g>
    </svg>
  )
}

export default SvgCustosOtimizados

import * as React from "react"

function SvgClose(props) {
  return (
    <svg
      width={32}
      height={32}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M2.327 1.326l28.448 28.447c.3.3.3.801 0 1.102-.1.1-.3.2-.501.2-.2 0-.4-.1-.601-.2L1.225 2.427c-.3-.3-.3-.801 0-1.101a.785.785 0 011.102 0zm28.448-.1c.3.3.3.8 0 1.101L2.427 30.875c-.2.1-.4.2-.6.2-.201 0-.401-.1-.602-.2-.3-.3-.3-.802 0-1.102L29.673 1.225c.3-.3.801-.3 1.102 0z"
          id="close_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="close_svg__b" fill="#fff">
          <use xlinkHref="#close_svg__a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#close_svg__a" />
        <g mask="url(#close_svg__b)" fill="#000">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgClose

import * as React from "react"

function SvgWifi(props) {
  return (
    <svg
      width={52}
      height={52}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M25.762 4.734a5.173 5.173 0 014.382 4.191l.036.227c.304 1.676-.305 3.352-1.524 4.57a5.572 5.572 0 01-.517.458h.06v14.015h19.196c1.066 0 1.98.762 1.98 1.828v12.188c0 .914-.914 1.828-1.98 1.828H2.758c-1.067 0-1.98-.762-1.98-1.828V30.023c0-1.066.913-1.828 1.98-1.828H21.8l.001-13.852a5.162 5.162 0 01-2.133-3.515c-.305-1.676.305-3.351 1.523-4.57 1.22-1.219 2.895-1.828 4.57-1.524zM47.547 29.72H2.605v12.34l44.79-.153.152-12.187zm-7.008 3.808a2.591 2.591 0 012.285 2.285 2.81 2.81 0 01-.761 2.438c-.61.457-1.22.762-1.981.762h-.457a2.591 2.591 0 01-2.285-2.285 2.81 2.81 0 01.762-2.438 2.81 2.81 0 012.437-.762zm-.305 1.22h-.152c-.457 0-.762.151-1.066.456-.305.305-.457.762-.457 1.219.152.61.609 1.066 1.218 1.219.457.152.914 0 1.22-.457.266-.267.416-.65.449-1.048l.007-.171a1.653 1.653 0 00-1.219-1.219zm-28.488.608c.457 0 .914.458.914.915s-.457.914-1.066.914H8.242c-.457 0-.914-.457-.914-.914 0-.458.457-.915.914-.915h3.504zm7.465 0c.457 0 .914.458.914.915s-.305.914-.914.914h-3.504c-.457 0-.914-.457-.914-.914 0-.458.457-.915.914-.915h3.504zm7.617 0c.457 0 .914.458.914.915s-.457.914-.914.914h-3.504c-.457 0-.914-.457-.914-.914 0-.458.457-.915.914-.915h3.504zM25 15.246h-.914l-.153-.024v12.973h2.133V15.14a5.316 5.316 0 01-1.066.106zM12.355.316L13.88 1.84c-2.438 2.133-3.656 5.18-3.656 8.379s1.218 6.246 3.504 8.531l-1.372 1.676C9.613 17.684 8.242 14.18 8.242 10.37c0-3.809 1.524-7.312 4.113-10.055zM37.797.164c5.484 5.637 5.484 14.625 0 20.262l-1.524-1.524c4.723-4.722 4.723-12.34 0-17.215L37.797.165zM16.773 2.602l1.524 1.523c-3.2 3.2-3.2 8.531 0 11.883l-1.524 1.523c-4.113-4.113-4.113-10.816 0-14.93zm16.606 0c4.113 4.265 4.113 10.968 0 14.93l-1.524-1.524c3.2-3.2 3.2-8.531 0-11.883l1.524-1.523zM25 6.715c-.914 0-1.676.305-2.285.914-.61.762-.914 1.828-.762 2.742.152 1.371 1.371 2.438 2.59 2.59 1.066.152 1.98-.152 2.742-.914.711-.569 1.024-1.536.939-2.405l-.025-.185c-.152-1.371-1.37-2.437-2.59-2.59-.152-.152-.457-.152-.609-.152z"
          id="wifi_svg__a"
        />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="wifi_svg__b" fill="#fff">
          <use xlinkHref="#wifi_svg__a" />
        </mask>
        <use fill="#505050" fillRule="nonzero" xlinkHref="#wifi_svg__a" />
        <g mask="url(#wifi_svg__b)" fill="#000">
          <path d="M-1-4h52v52H-1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgWifi

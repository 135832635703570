import React from 'react';
import Default from './Default';
import SpecialOffer from './SpecialOffer';

const TemplateHeader = (props) => {
  const { name } = props;
  const Component = {
    default: Default,
    specialOffer: SpecialOffer
  }

  const HeaderComponent = Component[name];

  return <HeaderComponent {...props} />;
}

export default TemplateHeader;
import * as React from "react"

function SvgConexaoSegura(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="conexao-segura_svg__a"
          d="M21.205.625c.33 0 .64.16.83.43 1.571 2.233 13.276 7.697 18.46 7.697.563 0 1.017.454 1.017 1.016v20.319c0 8.227-19.163 19.015-20.056 19.253a1.016 1.016 0 01-.525 0C19.856 49.054.875 39.017.875 29.07V9.769c0-.562.454-1.016 1.016-1.016 4.627 0 16.863-5.396 18.483-7.697.19-.27.5-.43.83-.43zm-.003 2.48c-3.35 2.901-13.271 7.066-18.295 7.617v18.349c0 7.621 15.28 16.868 18.278 18.195C23.822 46 39.48 36.312 39.48 30.086V10.733c-5.422-.514-15.012-4.758-18.278-7.626zm-.009 11.577a4.959 4.959 0 014.953 4.952v3.302h.825c.911 0 1.65.74 1.65 1.651v9.08a1.65 1.65 0 01-1.65 1.65H15.416a1.651 1.651 0 01-1.651-1.65v-9.08c0-.91.74-1.65 1.65-1.65h9.08v-3.303a3.305 3.305 0 00-3.302-3.301 3.304 3.304 0 00-3.301 3.301v.826H16.24v-.826a4.958 4.958 0 014.952-4.952zm-4.128 9.904h-1.65v9.08h11.557v-9.08h-.826v.001h-9.08l-.001-.001z"
        />
      </defs>
      <g transform="translate(4)" fill="none" fillRule="evenodd">
        <mask id="conexao-segura_svg__b" fill="#fff">
          <use xlinkHref="#conexao-segura_svg__a" />
        </mask>
        <use fill="#505050" xlinkHref="#conexao-segura_svg__a" />
        <g fill="#000" mask="url(#conexao-segura_svg__b)">
          <path d="M-4-1h52v52H-4z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgConexaoSegura

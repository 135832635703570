import _get from "lodash/get"
import _replace from "lodash/replace"
import _includes from "lodash/includes"
import _isEmpty from "lodash/isEmpty"

export function getStepDataToContact(steps) {
  const contactDate = _get(steps, "[await-when].value", "")
  const contactDateStep = _get(steps, "[await-when]", {})

  return {
    companyName: _get(steps, "[check-user].value.name", ""),
    documentNumber: _get(steps, "[await-cnpj-cpf].value", ""),
    userPhone: _get(steps, "[await-phone].value", ""),
    momentOptions: _get(steps, "[ask-moment-options].value", ""),
    timeframe: contactDate === "" ? "agora" : formatDate(contactDateStep),
  }
}

export function checkTelephoneNotMock(telephone) {
  switch (telephone) {
    case "00000000000":
    case "0000000000":
    case "1111111111":
    case "11111111111":
    case "22222222222":
    case "2222222222":
    case "3333333333":
    case "33333333333":
    case "44444444444":
    case "4444444444":
    case "5555555555":
    case "55555555555":
    case "66666666666":
    case "6666666666":
    case "7777777777":
    case "77777777777":
    case "88888888888":
    case "8888888888":
    case "9999999999":
    case "99999999999":
      return true
    default:
      return false
  }
}

export function formatDocument(documentNumber) {
  return documentNumber
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
}

export function formatDocumentCPF(cpf) {
  return cpf
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
}

export function getWhatsLink(agentPhoneFormatted, productSelected, steps) {
  const { wppContactType } = _get(steps, "[check-user].value", {})

  const typeClientSegmentMessage = _includes(wppContactType, "CORP")
    ? "corporativo"
    : "MPE"
  const complementMessage = productSelected
    ? ` em ${productSelected}`
    : " nas ofertas"
  return `https://wa.me/55${agentPhoneFormatted}?text=Estou com interesse${complementMessage} para ${typeClientSegmentMessage} da Algar Telecom`
}

export function formatAgentPhone(agentPhone) {
  return _replace(agentPhone, /[^\d]+/g, "")
}

export function payloadToSendWhatsapp(data) {
  const {
    agentPhoneWhats,
    agentName,
    formattedDocNumber,
    companyName,
    userPhone,
    timeframe,
    productSelected,
  } = data
  const complementMessage = productSelected ? `sobre ${productSelected} em` : ""
  return {
    to: `${agentPhoneWhats}@c.us`,
    msg: `${agentName}, o cliente de CNPJ ${formattedDocNumber}, razão social ${companyName}, telefone ${userPhone} deseja falar com você ${complementMessage} *${timeframe} no WhatsApp*. Caso ele não entre em contato direto com você, sugerimos contactá-lo o quanto antes. `,
  }
}

export function validateCPFCNPJ(document) {
  if (!document) return false

  document = document.replace(/\D/g, "")

  if (document.length === 11) {
    return validarCPF(document)
  }

  return validarCNPJ(document)
}

export function validarCNPJ(cnpj) {
  if (cnpj !== undefined && cnpj !== '') {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    // LINHA 10 - Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false; // LINHA 21

    // Valida DVs LINHA 23 -
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (`${resultado}` !== digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (`${resultado}` !== digitos.charAt(1)) return false; // LINHA 49

    return true; // LINHA 51
  }
  return;
}

export function validarCPF(cpf) {
  if (!cpf) return false;
  cpf = cpf.replace(/\D/g, '');
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  let add = 0;
  for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9), 10)) return false;
  add = 0;
  for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10), 10)) return false;

  return true;
};


export function maskCPFCNPJ(number) {
  if (!number) return '';
  number = number.replace(/\D/g, '');
  if (number.length === 14) {
    return maskCNPJ(number);
  }
  return maskCPF(number);
}

export function maskCPF(cpf) {
  if (!_isEmpty(cpf)) {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
  }
  return ""
}

export function maskCNPJ(cnpj) {
  if (!_isEmpty(cnpj)) {
    cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")
    return cnpj
  }
  return ""
}

export function maskCellPhone(number) {
  if (!number) return '';
  return number
    .replace(/\D/g, '')
    .replace(/^(\d{12})(\d+)/g, '$1')
    .replace(/^0/, '')
    .replace(/^(\d{2})(\d+)$/, '$1 $2')
    .replace(/^(\d{2})\s(\d{4})(\d{4})$/, '($1) $2-$3')
    .replace(/^(\d{2})\s(\d)(\d{4})(\d{4})$/, '($1) $2 $3-$4')
    .replace(/^(\d{2})(.+)$/, '($1)$2');
}

export function commercialTime() {
  //verifica se o horário permite o usuário falar "AGORA"
  const agora = new Date()
  const diaSemana = agora.getDay()
  const hora = agora.getHours()
  // é dia da SEMANA?
  if (diaSemana > 0 && diaSemana < 7) {
    // ESTA DENTRO DO HORARIO COMERCIAL
    return hora > 7 && hora < 18
  }

  return false
}

export function getManualDate(dateString) {
  if (!dateString) {
    return ""
  }

  const [day, month, yearAndHour] = dateString.split("/")
  const [year, hours] = yearAndHour.split("-")
  const [hour, minute] = hours.split(":")
  return new Date(year, +month - 1, day, hour, minute)
}

export function formatDate(stringDate) {
  const stepDateValid = stringDate.value && stringDate.value.includes("T")

  const [year, month, dayAndHour] =
    stringDate.value && stringDate.value.split(stepDateValid ? "-" : "/")
  const [day, hour] = dayAndHour && dayAndHour.split(stepDateValid ? "T" : "-")

  return stepDateValid
    ? `${day}/${month}/${year} - ${hour}`
    : `${year}/${month}/${day} - ${hour}`
}

export function isDevelopmentMode() {
  return process.env.NODE_ENV === "development"
}

export function onlyNumbers(string) {
  return string ? string.replace(/\D/g, "") : ""
}

export const States = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espirito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];
import * as React from "react"

function SvgCloud(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 72.7 75.4"
      {...props}
    >
      <defs>
        <path
          id="cloud_svg__a"
          d="M59 45.5H46.6v-5.1c0-1.7-1.4-3.1-3.1-3.1h-1v-3.1c0-3.4-2.8-6.2-6.2-6.2s-6.2 2.8-6.2 6.2v3.1h-1c-1.7 0-3.1 1.4-3.1 3.1v5.1H15c-4 0-7.3-3.2-7.4-7.2 0-6 4.7-11 10.8-11.3.6 0 1-.5 1-1.1v-.2c-.7-2.2-.5-4.6.4-6.7 1.5-3.5 4.8-6 8.6-6.4 5.2-.5 9.9 3 11 8.1.1.6.7.9 1.2.8.1 0 .2-.1.3-.1 2.3-1.4 5-1.9 7.7-1.5 4 .7 7.4 3.5 8.8 7.3.6 1.6.8 3.3.6 5-.1.5.3 1 .8 1.1 4.6 1 6.4 2.7 6.4 6-.1 3.4-2.8 6.2-6.2 6.2zm-26.8-8.2v-3.1c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1v3.1h-8.2zm12.4 15.4c0 .6-.5 1-1 1H29.2c-.6 0-1-.5-1-1V40.4c0-.6.5-1 1-1h14.4c.6 0 1 .5 1 1v12.3zM60 31.6c.1-1.7-.2-3.4-.8-5-2.5-6.9-10.1-10.5-17-8-.4.2-.9.3-1.3.5-1.8-5.5-7.1-9-12.8-8.5-4.6.5-8.5 3.4-10.3 7.6-.9 2.2-1.2 4.5-.8 6.9-6.6.9-11.5 6.6-11.5 13.3.1 5.1 4.3 9.2 9.5 9.1h11.1v5.1c0 1.7 1.4 3.1 3.1 3.1h14.4c1.7 0 3.1-1.4 3.1-3.1v-5.1H59c4.5 0 8.2-3.7 8.2-8.2 0-5-3.7-6.8-7.2-7.7z"
        />
      </defs>
      <use xlinkHref="#cloud_svg__a" overflow="visible" fill="#1e333b" />
      <clipPath id="cloud_svg__b">
        <use xlinkHref="#cloud_svg__a" overflow="visible" />
      </clipPath>
      <g clipPath="url(#cloud_svg__b)">
        <defs>
          <path id="cloud_svg__c" d="M5.1 10.6h62.1v56.5H5.1z" />
        </defs>
        <use xlinkHref="#cloud_svg__c" overflow="visible" fill="#1e333b" />
        <clipPath id="cloud_svg__d">
          <use xlinkHref="#cloud_svg__c" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cloud_svg__d)"
          fill="#1e333b"
          d="M3.2 8.2h66.3v49.9H3.2z"
        />
      </g>
      <g>
        <defs>
          <path
            id="cloud_svg__e"
            d="M37.7 46c-.2.2-.3.5-.3.8v3.9h-2.1v-3.9c0-.3-.1-.6-.3-.8-.4-.4-.7-.9-.7-1.5 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1-.1.6-.3 1.1-.8 1.5zm1.4-4.6c-.8-.7-1.7-1-2.7-1-2.3 0-4.1 1.8-4.1 4.1 0 1 .4 2 1 2.7v4.5c0 .6.5 1 1 1h4.1c.6 0 1-.5 1-1v-4.5c1.5-1.7 1.4-4.3-.3-5.8z"
          />
        </defs>
        <use xlinkHref="#cloud_svg__e" overflow="visible" fill="#00a750" />
        <clipPath id="cloud_svg__f">
          <use xlinkHref="#cloud_svg__e" overflow="visible" />
        </clipPath>
        <g clipPath="url(#cloud_svg__f)">
          <defs>
            <path id="cloud_svg__g" d="M5.1 10.6h62.1v56.5H5.1z" />
          </defs>
          <use xlinkHref="#cloud_svg__g" overflow="visible" fill="#00a750" />
          <clipPath id="cloud_svg__h">
            <use xlinkHref="#cloud_svg__g" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cloud_svg__h)"
            fill="#00a750"
            d="M29.9 38h12.9v17H29.9z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgCloud

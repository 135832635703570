import React from 'react';
import CustomModal from '../../../CustomModal';
import AlertModal from '../../../AlertModal';
import PropTypes from 'prop-types';

const ConfirmationLeadModal = ({ isOpen, onClose, content }) => {
  const { iconModal, titleModal, messageModal, secondMessageModal } = content;
  return (
    <CustomModal
      type="button"
      isOpen={isOpen}
      onClose={onClose}
      className="pb24 w-90 w-auto-m w-auto-l"
    >
      <AlertModal
        icon={iconModal}
        title={titleModal}
        desc={messageModal}
        secondDesc={secondMessageModal}
      />
    </CustomModal>
  );
};

ConfirmationLeadModal.defaultProps = {
  isOpen: false,
  onClose: () => { },
  content: {
    iconModal: '',
    titleModal: '',
    messageModal: '',
    secondMessageModal: '',
  },
};

ConfirmationLeadModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.object,
};

export default ConfirmationLeadModal;

import * as React from "react"

function SvgAltaEscalabilidade(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={61}
      height={32}
      viewBox="0 0 61 32"
      {...props}
    >
      <defs>
        <style>{".alta-escalabilidade_svg__prefix__cls-1{fill:#041a29}"}</style>
      </defs>
      <g
        id="alta-escalabilidade_svg__prefix__Grupo_96"
        data-name="Grupo 96"
        transform="translate(-498 -827)"
      >
        <path
          id="alta-escalabilidade_svg__prefix__Caminho_243"
          d="M555.664 845.026l-1.833-.927a7.977 7.977 0 00-.773-.228l-.109-.026-.015-.11a12.292 12.292 0 00-1.047-3.54 12.491 12.491 0 00-2.985-4.1 12.676 12.676 0 00-10.722-3.014 10.929 10.929 0 00-2.887.852l-.122.053-.078-.107a16.675 16.675 0 00-30.167 9.613v.212l-.144.018a7.666 7.666 0 00.967 15.278h45.5a7.654 7.654 0 004.412-13.974zm-3.581 12.279l-2.345.181h-.008l-7.794-.119h-26.875l-7.791.119-2.343-.181a6.033 6.033 0 01.822-12.019 6.155 6.155 0 014.451 1.914.835.835 0 001.073.113.824.824 0 00.353-.59.777.777 0 00-.209-.625 7.8 7.8 0 00-4.7-2.384l-.062-.008-.082-.092v-.065a14.682 14.682 0 014.378-10.539 15.068 15.068 0 0123.241 2.461l.136.21a.552.552 0 00.715.188l.224-.115a11.071 11.071 0 0115.964 7.716l.035.188h-.193a7.7 7.7 0 00-7.491 6.562.824.824 0 001.631.238 6.1 6.1 0 116.869 6.843z"
          className="alta-escalabilidade_svg__prefix__cls-1"
          data-name="Caminho 243"
        />
        <path
          id="alta-escalabilidade_svg__prefix__Caminho_244"
          d="M624.533 939.175l2.354-2.354a.7.7 0 000-.981l-2.354-2.354a.822.822 0 00-1.163 1.163l.859.859h-21.691l.859-.859a.822.822 0 00-1.163-1.163l-2.354 2.354a.694.694 0 000 .981l2.354 2.354a.822.822 0 101.163-1.163l-.859-.859h21.691l-.859.859a.822.822 0 001.163 1.163z"
          data-name="Caminho 244"
          transform="translate(-84.884 -88.93)"
          fill="#00a750"
        />
        <path
          id="alta-escalabilidade_svg__prefix__Caminho_245"
          d="M655.536 854.334a.83.83 0 00-.049-.664.8.8 0 00-.507-.4l-.09-.023a.8.8 0 00-.639.107.824.824 0 00.254 1.492l.036.01a.824.824 0 00.995-.522z"
          className="alta-escalabilidade_svg__prefix__cls-1"
          data-name="Caminho 245"
          transform="translate(-130.175 -21.905)"
        />
        <path
          id="alta-escalabilidade_svg__prefix__Caminho_246"
          d="M577.475 851.243a12.63 12.63 0 00-11.229 9.488.8.8 0 00.109.643.823.823 0 001.49-.253 10.991 10.991 0 019.75-8.237.823.823 0 00.5-1.427.806.806 0 00-.62-.214z"
          className="alta-escalabilidade_svg__prefix__cls-1"
          data-name="Caminho 246"
          transform="translate(-56.968 -20.253)"
        />
      </g>
    </svg>
  )
}

export default SvgAltaEscalabilidade

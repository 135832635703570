import {
  Logo,
  Menu,
  Delivery,
  Cleansing,
  LogoWhite,
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
  Calendar,
  Chart,
  Cash,
  Money,
  Courses,
  Marketing,
  PriceTag,
  SmallStore,
  MediumStore,
  LargeStore,
  Investiment,
  LargeArea,
  LargeEmployee,
  MediumArea,
  MediumEmployee,
  SmallArea,
  SmallEmployee,
  Support,
  CompleteAdvisory,
  EnhancedSecurity,
  TotalSecurity,
  Shield,
  Productivity,
  Economy,
  Communication,
  CollaborativeWork,
  Protection,
  Performance,
  FlexibleConsumption,
  Flexibility,
  EasyAccess,
  ChipElectronics,
  LowCost,
  Limitless,
  FullFlexibility,
  LogoAlgarWhite,
  AcessoRemoto,
  AltaEscalabilidade,
  Confiabilidade,
  CustosOtimizados,
  DadosIlimitados,
  Economia,
  Flexibilidade,
  Ilimitado,
  LoadBalancer,
  Message,
  Msg,
  Otimizacao,
  Pattern2,
  Pattern3,
  Pattern4,
  Pattern5,
  Pattern6,
  Produtividade,
  ProdutividadeEngrenagem,
  Seguranca,
  SegurancaReforcada,
  Suporte,
  VozIlimitada,
  WhatsappIlimitado,
  Chat,
  Globe,
  Calling,
  ShieldFill,
  ArrowMoney,
  Cloud,
  ConeAcessoRapido,
  ConeComunicacaoEscala,
  ConeEficiencia,
  InternetPage,
  Backup,
  Colaborativo,
  CustoBeneficio,
  Economia2,
  FacilImplementacao,
  FlexibilidadeBarras,
  GestaoUnificada,
  Possibilidades,
  Protecao,
  Resultados,
  SegurancaV2,
  NoSeuRitmo,
  AltaDisponibilidade,
  Estabilidade,
  ConexaoSegura,
  Mobilidade,
  ProdutividadeSimplicidade,
  ReducaoCustos,
  Chatbot,
  ProdutividadeRelacionamento,
  Wifi,
  DadosInsights,
  SolucaoIntegrada,
  Close,
  SegurancaV3,
  LegadoInformacao,
  Elastiscidade,
  AdequacaoLgpd,
  Sucess,
  Error,
  CloseModal,
} from './components'

export const Icons = {
  Logo,
  Menu,
  Delivery,
  Cleansing,
  LogoWhite,
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
  Calendar,
  Chart,
  Cash,
  Money,
  Courses,
  Marketing,
  PriceTag,
  SmallStore,
  MediumStore,
  LargeStore,
  Investiment,
  LargeArea,
  LargeEmployee,
  MediumArea,
  MediumEmployee,
  SmallArea,
  SmallEmployee,
  Support,
  CompleteAdvisory,
  EnhancedSecurity,
  TotalSecurity,
  Shield,
  Productivity,
  Economy,
  Communication,
  CollaborativeWork,
  Protection,
  Performance,
  FlexibleConsumption,
  Flexibility,
  EasyAccess,
  ChipElectronics,
  LowCost,
  Limitless,
  FullFlexibility,
  LogoAlgarWhite,
  AcessoRemoto,
  AltaEscalabilidade,
  Confiabilidade,
  CustosOtimizados,
  DadosIlimitados,
  Economia,
  Flexibilidade,
  Ilimitado,
  LoadBalancer,
  Message,
  Msg,
  Otimizacao,
  Pattern2,
  Pattern3,
  Pattern4,
  Pattern5,
  Pattern6,
  Produtividade,
  ProdutividadeEngrenagem,
  Seguranca,
  SegurancaReforcada,
  Suporte,
  VozIlimitada,
  WhatsappIlimitado,
  Chat,
  Globe,
  Calling,
  ShieldFill,
  ArrowMoney,
  Cloud,
  ConeAcessoRapido,
  ConeComunicacaoEscala,
  ConeEficiencia,
  InternetPage,
  Backup,
  Colaborativo,
  CustoBeneficio,
  Economia2,
  FacilImplementacao,
  FlexibilidadeBarras,
  GestaoUnificada,
  Possibilidades,
  Protecao,
  Resultados,
  SegurancaV2,
  NoSeuRitmo,
  AltaDisponibilidade,
  Estabilidade,
  ConexaoSegura,
  Mobilidade,
  ProdutividadeSimplicidade,
  ReducaoCustos,
  Chatbot,
  ProdutividadeRelacionamento,
  Wifi,
  DadosInsights,
  SolucaoIntegrada,
  Close,
  SegurancaV3,
  LegadoInformacao,
  Elastiscidade,
  AdequacaoLgpd,
  Sucess,
  Error,
  CloseModal,
};

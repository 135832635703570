import * as React from "react"

function SvgChart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={43}
      height={40}
      viewBox="0 0 43 40"
      {...props}
    >
      <defs>
        <path
          id="chart_svg__a"
          d="M37.12 14.815c.424 0 .768.349.768.78v22.768h.613c.424 0 .767.349.767.78 0 .43-.343.78-.767.78H.767a.774.774 0 01-.767-.78c0-.431.343-.78.767-.78h.614v-7.798c0-.43.343-.78.766-.78h6.136c.424 0 .767.35.767.78v7.798h1.84V25.575c0-.43.344-.78.768-.78h6.135c.424 0 .767.35.767.78v12.788h2.148V20.74c0-.43.343-.78.767-.78h6.136c.423 0 .766.35.766.78v17.622h1.841v-6.785c0-.43.344-.78.767-.78.424 0 .767.35.767.78v6.785h4.602V16.374h-4.602v7.564c0 .43-.343.78-.767.78a.774.774 0 01-.767-.78v-8.343c0-.431.344-.78.767-.78zM7.517 31.345H2.914v7.018h4.602v-7.018zm9.51-4.99h-4.601v12.008h4.601V26.355zm9.818-4.834h-4.602v16.842h4.602V21.52zm4.141 5.294c.202 0 .4.083.542.228a.791.791 0 010 1.102c-.142.146-.34.23-.542.23s-.4-.084-.542-.23a.788.788 0 010-1.102.766.766 0 01.542-.228zm-28.777-.788c.202 0 .4.083.542.228a.79.79 0 010 1.103c-.142.145-.34.228-.542.228s-.4-.083-.542-.228a.786.786 0 010-1.102.762.762 0 01.542-.23zm25.096-15.94c.423 0 .767.35.767.78v3.052c0 .431-.344.78-.767.78a.773.773 0 01-.767-.78v-1.17l-8.743 8.888a.758.758 0 01-1.085 0l-4.166-4.235-7.799 7.928a.756.756 0 01-1.084 0 .787.787 0 010-1.102l8.34-8.48a.758.758 0 011.085 0l4.167 4.235 8.2-8.336H24.3a.774.774 0 01-.767-.78c0-.43.343-.78.767-.78zM33.439 0c3.215 0 5.83 2.658 5.83 5.926 0 3.268-2.615 5.926-5.83 5.926-3.214 0-5.828-2.658-5.828-5.926C27.61 2.658 30.225 0 33.439 0zm-.766 1.63c-2.004.37-3.528 2.154-3.528 4.296 0 2.141 1.524 3.926 3.528 4.295v-.587c-.95-.306-1.637-1.163-1.637-2.17 0-.43.343-.78.767-.78.423 0 .767.35.767.78 0 .403.39.73.87.73s.87-.327.87-.73c0-.403-.39-.73-.87-.73-1.326 0-2.404-1.028-2.404-2.29 0-1.008.686-1.864 1.637-2.17zm1.533 0v.644c.95.306 1.637 1.162 1.637 2.17 0 .43-.343.78-.767.78a.774.774 0 01-.767-.78c0-.403-.39-.73-.87-.73s-.87.327-.87.73c0 .402.39.73.87.73 1.326 0 2.404 1.027 2.404 2.29 0 1.007-.686 1.864-1.637 2.17v.587c2.004-.369 3.528-2.154 3.528-4.295 0-2.142-1.524-3.927-3.528-4.295z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 .672)">
        <ellipse
          cx={27.681}
          cy={15.574}
          fill="#FBBD0C"
          rx={14.996}
          ry={15.246}
        />
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#chart_svg__a" />
      </g>
    </svg>
  )
}

export default SvgChart

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Formik, Form, Field } from "formik"
import _map from "lodash/map"
import _get from "lodash/get"
import _filter from "lodash/filter"
import _find from "lodash/find"
import _forEach from "lodash/forEach"
import _orderBy from "lodash/orderBy"
import FloatLabel from "./components/FloatLabel"
import Loader from "components/Loader"
import UserService from "../../services/UserService"
import ConfirmationLeadModal from "./components/MessageModal/ConfirmLeadModal"
import Button from "components/Button"
import styles from "./styles.module.css"
import ProspectService from "../../services/ProspectService"
import { States } from "src/utils/utils"
import Leads from "src/services/Leads"
import FloatSelect from "./components/FloatSelect"

const LeadForm = ({
  fields = ["name", "email", "cpfCnpj", "phoneFix"],
  title,
  textButton,
  initialValues,
  Preset,
  validateSchema,
  isFormModal,
  closeModal,
  openModal,
  setFormErrored
}) => {
  const [showRequestModal, setShowRequestModasl] = useState(false)
  const [contentModal, setContentModal] = useState({
    iconModal: "",
    titleModal: "",
    messageModal: "",
    secondMessageModal: "",
  })
  const [loading, setLoading] = useState(false)
  const [counties, setCounties] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  
  initialValues = {
    ...initialValues,
    state: '',
    city: '',
  }

  const formClasses = "flex flex-column tc"
  const formClass = classnames(
    "bg-white relative",
    styles.formClass,
    isFormModal && styles.formClassModal
  )
  const titleClass = classnames(
    "f24 f20-m tc te-papa-green mt0 mh0 mb24 fw7",
    isFormModal && "mt40"
  )
  const buttonClass = classnames(
    "btn-kelley-green ant-btn-primary mt8 f16 fw6",
    styles.button
  )

  const toggleRequestModal = () => {
    setShowRequestModasl(!showRequestModal)
    if (showRequestModal && openModal) {
      closeModal(false)
    }
  }

  const sendFormSubmit = async (value, setSubmitting, resetForm) => {
    setLoading(true)
    const onRequestForm = (
      iconModal,
      titleModal,
      messageModal,
      secondMessageModal
    ) => {
      const content = {
        iconModal,
        titleModal,
        messageModal,
        secondMessageModal,
      }
      setContentModal(content)
      toggleRequestModal()
    }
    const payload = {
      name: value.name,
      email: value.email,
      cpfCnpj: value.cpfCnpj.replace(/\D/g, ""),
      phoneFix: value.phoneFix.replace(/\D/g, ""),
      city: selectedCity,
      state: selectedState,
    }
    try {
      const status = await UserService.postLeadManagerSimpleByForm({ payload })

      if (status.status === 201) {
        setLoading(false)
        setSubmitting(false)
        onRequestForm(
          "Sucess",
          "Tudo certo!",
          "Agora é só aguardar.",
          "Em breve a gente entra em contato com você. : )"
        )
        resetForm(initialValues)
        setSelectedState('')
        setSelectedCity('')
      }
    } catch (error) {
      setLoading(false)
      onRequestForm(
        "Error",
        "Ops!",
        "Tivemos um problema : (",
        "Tente novamente"
      )
      resetForm(initialValues)
      setSelectedState('')
      setSelectedCity('')
    }
  }

  const listCounties = _map(counties, (item) => {
    return  { 
      value: item.city,
      label: item.city,
    }
  });

  const fetchCounties = async () => {
    try {
      const result = await Leads.getStates(selectedState);
      const normalizedCounties = normalizeCountiesData(result.data)
      setCounties(normalizedCounties);
    } catch(error) {
      setCounties('');
    }
  }

  const normalizeCountiesData = (data) => {
    let output = [];
    _forEach(data, obj => {
      const hasCounties = _find(output, o => o.city === obj.city);
      if (!hasCounties) output.push(obj)
    })
    return _orderBy(output, 'city', 'asc')
  }

  useEffect(() => {
    if(selectedState !== '') fetchCounties();
  }, [selectedState]);

  const CustomSelectComponent = ({ wrapperClasses, field, ...props }) => {
    const normalizeFieldData = {
      name: field.name,
      onBlur: field.onBlur,
      onInput: field.onChange,
      hasError: !!props.form.errors[field.name] && props.form.touched[field.name],
      errorMessage: props.form.errors[field.name],
      value: field.value,
    }

    if (setFormErrored) {
      if (normalizeFieldData.hasError) {
        setFormErrored(true)
      }
    }

    return (
      <div className={wrapperClasses}>
        <FloatSelect
          {...normalizeFieldData}
          {...props}
          classNamePrefix="select"
          ariaRequired="true"
          noOptionsMessage={'Sem opções!'}
        />
      </div>
    )
  }

  const validateState = () => {
    let error;
    if(!selectedState) {
      error = 'Campo obrigatório!'
    };
    return error;
  }

  const validateCity = () => {
    let error;
    if(!selectedCity) {
      error = 'Campo obrigatório!'
    };
    return error;
  }

  return (
    <div className={formClass}>
      {loading && (
        <Loader isLoading={loading} autoSize className={styles.loader} />
      )}
      <h3 className={titleClass}>{title}</h3>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validateSchema}
        validateOnChange={false}
        onSubmit={(value, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          sendFormSubmit(value, setSubmitting, resetForm)
        }}
      >
        <Form noValidate className={formClasses}>
          {renderField(fields, Preset, setFormErrored)}

          <Field
            component={CustomSelectComponent}
            placeholder='*Estado'
            id='state'
            name='state'
            options={States}
            validate={validateState}
            setSelectedValue={setSelectedState}
            selectedValue={selectedState}
          />

          <Field
            component={CustomSelectComponent}
            placeholder='*Cidade'
            id='city'
            name='city'
            options={listCounties}
            validate={validateCity}
            setSelectedValue={setSelectedCity}
            selectedValue={selectedCity}
            isDisabled={!selectedState}
            autoFocus={true}
          />

          <Button
            className={buttonClass}
            type="submit"
            dataValue={textButton}
            dataTracking="TALK_TO_CONSULTANT"
          >
            {textButton}
          </Button>
        </Form>
      </Formik>
      <ConfirmationLeadModal
        isOpen={showRequestModal}
        onClose={toggleRequestModal}
        content={contentModal}
      />
    </div>
  )
}
LeadForm.propTypes = {
  fields: PropTypes.array,
  title: PropTypes.string,
  isFormModal: PropTypes.bool,
  closeModal: PropTypes.func,
  openModal: PropTypes.bool,
  setFormErrored: PropTypes.func,
}

const CustomInputComponent = ({ wrapperClasses, field, ...props }) => {
  const normalizeFieldData = {
    name: field.name,
    onBlur: field.onBlur,
    onInput: field.onChange,
    hasError: !!props.form.errors[field.name] && props.form.touched[field.name],
    errorMessage: props.form.errors[field.name],
    value: field.value,
  }

  if (props.setFormErrored) {
    if(normalizeFieldData.hasError) {
      props.setFormErrored(true)
    } else {
      props.setFormErrored(false)
    }
  }

  return (
    <div className={wrapperClasses}>
      <FloatLabel {...normalizeFieldData} {...props} />
    </div>
  )
}

const renderField = (fields, Preset, setFormErrored) =>
  _map(fields, fieldName => {
    const fieldProps = Preset[fieldName]
    return (
      <Field
        {...fieldProps} 
        type="text"
        component={CustomInputComponent}
        divClass="bg-white"
        setFormErrored={setFormErrored}
      />
    )
  })

async function fetchData(payload) {
  let documentNumber = _get(payload, "cpfCnpj", 0)
  let customerDetailsResponse = {}
  let WPP_CONTACT_TYPE = "BASE_CORP"

  try {
    customerDetailsResponse = await UserService.queryCustomerDetails({
      documentNumber: documentNumber,
      targetCRM: "ALGAR CRM",
    })
  } catch (err) {
    if (err && err.response && err.response.status === 404) {
      const segmentResponse = await fetchSegment(documentNumber)
      const segment = _get(segmentResponse, "data.segmento", "")

      WPP_CONTACT_TYPE =
        segment === "CORPORATIVO" ? "PROSPECT_CORP" : "PROSPECT_MPE"
    }
  }

  const userData = _get(customerDetailsResponse, "data", {})
  const { customerSegment } = userData

  if (customerSegment !== "CORPORATIVO") {
    WPP_CONTACT_TYPE = "BASE_MPE"

    if (customerSegment === "RESIDENCIAL") {
      WPP_CONTACT_TYPE = "BASE_VAREJO"
    }
  }
  return WPP_CONTACT_TYPE
}
  
const fetchSegment = async documentNumber => {
  try {
    const segmentResponse = await ProspectService.getSegment(documentNumber)

    return segmentResponse
  } catch (err) {
    console.error("error fetchSegment", err)
    return {}
  }
}

export default LeadForm
import * as React from "react"

function SvgConeAcessoRapido(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={80}
      height={80}
      viewBox="0 0 80 80"
      {...props}
    >
      <defs>
        <path id="cone-acesso-rapido_svg__a" d="M0 80h80V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <path
          fill="#1E333B"
          d="M58.333 76.667h-35A1.668 1.668 0 0121.667 75v-5H60v5c0 .92-.748 1.667-1.667 1.667zm-35-73.334h35C59.252 3.333 60 4.08 60 5v3.333H21.667V5c0-.92.748-1.667 1.666-1.667zM60 66.667H21.667v-55H60V20h3.333V5c0-2.757-2.243-5-5-5h-35c-2.756 0-5 2.243-5 5v70c0 2.757 2.244 5 5 5h35c2.757 0 5-2.243 5-5V58.333H60v8.334z"
        />
        <mask id="cone-acesso-rapido_svg__b" fill="#fff">
          <use xlinkHref="#cone-acesso-rapido_svg__a" />
        </mask>
        <path
          fill="#1E333B"
          d="M38.333 75h5v-3.333h-5zm41.179-49.512l-8.334-8.333a1.66 1.66 0 00-1.816-.362 1.67 1.67 0 00-1.029 1.54v3.334h-25V25H70c.92 0 1.667-.745 1.667-1.667v-.976l4.31 4.31-4.31 4.31V30c0-.922-.747-1.667-1.667-1.667H31.667v3.334h36.666V35A1.668 1.668 0 0070 36.667c.433 0 .86-.17 1.178-.489l8.334-8.333a1.666 1.666 0 000-2.357"
          mask="url(#cone-acesso-rapido_svg__b)"
        />
        <path
          fill="#1E333B"
          d="M69.512 42.155a1.66 1.66 0 00-1.817-.362 1.67 1.67 0 00-1.028 1.54v3.334h-3.334v-8.334h3.334V35h-30v3.333H60v8.334H28.333V50h40c.92 0 1.667-.745 1.667-1.667v-.976l4.31 4.31-4.31 4.31V55c0-.922-.747-1.667-1.667-1.667h-25v3.334h23.334V60a1.668 1.668 0 002.845 1.178l8.333-8.333a1.665 1.665 0 000-2.357l-8.333-8.333zM0 25h16.667v-3.333H0zm8.333 31.667h8.334v-3.334H8.333zm3.334-25c0-.674-.407-1.284-1.029-1.54a1.655 1.655 0 00-1.816.361L.488 38.822a1.665 1.665 0 000 2.356l8.334 8.334a1.668 1.668 0 002.845-1.179V45h5v-3.333H10c-.92 0-1.667.745-1.667 1.666v.977L4.023 40l4.31-4.31v.977c0 .921.747 1.666 1.667 1.666h6.667V35h-5v-3.333z"
          mask="url(#cone-acesso-rapido_svg__b)"
        />
      </g>
    </svg>
  )
}

export default SvgConeAcessoRapido

import React from "react"
import cx from "classnames"
import Lottie from "react-lottie"

import animationData from "./loaderLoop.json"

const LoaderDefault = ({ isLoading, autoSize, className }) => {
  const OPTIONS = {
    loop: true,
    autoplay: true,
    animationData,
  }

  const classname = cx("bg-black o-70 aspect-ratio--object", className)

  if (autoSize) {
    return (
      isLoading && (
        <div className={classname}>
          <Lottie
            isClickToPauseDisabled
            options={OPTIONS}
            isActive={isLoading}
            width={25}
          />
        </div>
      )
    )
  }

  return (
    <Lottie
      isClickToPauseDisabled
      options={OPTIONS}
      isActive={isLoading}
      width={25}
    />
  )
}

export default LoaderDefault

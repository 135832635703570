import * as React from "react"

function SvgGestaoUnificada(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="gestao-unificada_svg__a"
          d="M41.943 16.365c1.019 0 1.853.772 1.927 1.754l.005.141v1.896c0 1-.788 1.819-1.788 1.89l-.144.006c0 3.283-4.918 7.818-11.855 12.376l-.706.459v7.545l-4.51 3.318h-2.254V34.888l-.332-.215c-7.006-4.558-12.046-9.113-12.224-12.44l-.005-.18c-1.019 0-1.853-.774-1.927-1.755l-.005-.142V18.26c0-1 .788-1.818 1.788-1.89l.144-.005h31.886zm0 1.895H10.057v1.896h7.73v1.896H11.99c0 2.431 5.114 7.013 11.763 11.299l.798.51v9.756l2.898-2.133v-7.623l.44-.28c6.703-4.275 11.935-8.89 12.116-11.397l.005-.132H26.483v-1.896h15.46V18.26zm-5.798-7.583c1.02 0 1.854.773 1.927 1.754l.006.142v2.844h-1.933v-2.844h-1.932v2.844H32.28v-2.844c0-1 .788-1.818 1.789-1.89l.144-.006h1.932zm-10.628.948v3.792h-1.933v-3.792h1.933zm-6.764-5.688c1.068 0 1.933.849 1.933 1.896v3.792c0 1.048-.865 1.896-1.933 1.896h-1.932c-1.068 0-1.932-.848-1.932-1.896V7.833c0-1.047.864-1.896 1.932-1.896zm0 1.896h-1.932v3.792h1.932V7.833zm23.19-5.687v7.583H40.01V2.146h1.933zM30.348.25c1.068 0 1.932.848 1.932 1.896v3.792c0 1.047-.864 1.895-1.932 1.895h-1.932c-1.068 0-1.933-.848-1.933-1.896v-3.79c0-1.048.865-1.896 1.933-1.896zM11.99.25v7.583h-1.933V.25h1.933zm18.358 1.896h-1.932v3.792h1.932V2.146z"
        />
        <path id="gestao-unificada_svg__c" d="M0 80h75V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="gestao-unificada_svg__b" fill="#fff">
          <use xlinkHref="#gestao-unificada_svg__a" />
        </mask>
        <use
          fill="#505050"
          fillRule="nonzero"
          xlinkHref="#gestao-unificada_svg__a"
        />
        <g mask="url(#gestao-unificada_svg__b)">
          <path d="M0-3h52v52H0z" />
          <g transform="translate(-11.175 -17)">
            <path
              fill="#1E333B"
              d="M51.667 68.333v-4.146l-.642-.502c-3.007-2.342-4.345-5.338-5.422-7.747a45.046 45.046 0 00-.778-1.683l-3.003-6.005a2.51 2.51 0 011.16-.573c.31-.059.731-.079.991.176 1.694 1.67 3.21 3.282 3.217 3.29l.493.524h3.984v-20c0-.897.788-1.655 1.728-1.667.902.012 1.605.738 1.605 1.667V44.69l12.925 2.677c.878.175 3.742 1.005 3.742 4.3V60c0 .547-.31 1.142-.669 1.83C70.53 62.727 70 63.742 70 65v3.333H51.667zm-3.334 8.334h23.334v-5H48.333v5zm25.62-13.297C74.42 62.477 75 61.363 75 60v-8.333c0-3.817-2.52-6.787-6.41-7.567l-10.257-2.123v-10.31c0-2.744-2.203-4.977-4.918-5h-.042c-2.783.021-5.04 2.256-5.04 5v15.851c-.57-.586-1.28-1.306-2.021-2.04-1.259-1.236-3.21-1.516-5.099-.726-1.75.731-2.88 2.138-2.88 3.581v.394l3.509 7.018c.236.472.47.995.718 1.553 1.092 2.444 2.562 5.735 5.773 8.492v2.543H45V80h30V68.333h-1.667V65c0-.442.287-.992.62-1.63z"
            />
            <mask id="gestao-unificada_svg__d" fill="#fff">
              <use xlinkHref="#gestao-unificada_svg__c" />
            </mask>
            <path
              fill="#1E333B"
              d="M3.333 10H70V3.333H3.333V10zm0 3.333H70V40h3.333V0H0v76.667h41.667v-3.334H3.333v-60z"
              mask="url(#gestao-unificada_svg__d)"
            />
            <path
              fill="#1E333B"
              d="M31.667 31.667h-3.334v5h-15V23.333H25V20H10v20h21.667zM13.333 63.333h15V50h-15v13.333zM10 66.667h21.667v-20H10v20z"
              mask="url(#gestao-unificada_svg__d)"
            />
            <path
              fill="#1E333B"
              d="M30.401 20.583l-8.828 10.3-3.728-3.729-2.357 2.357 6.273 6.273 11.172-13.033zM38.333 25H65v-3.333H38.333zm0 6.667H45v-3.334h-6.667zm0 6.666H45V35h-6.667z"
              mask="url(#gestao-unificada_svg__d)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgGestaoUnificada

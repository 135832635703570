import * as React from "react"

function SvgVozIlimitada(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51}
      height={39}
      viewBox="0 0 51 39"
      {...props}
    >
      <defs>
        <style>{".voz-ilimitada_svg__prefix__cls-2{fill:#041a29}"}</style>
      </defs>
      <g
        id="voz-ilimitada_svg__prefix__Grupo_95"
        data-name="Grupo 95"
        transform="translate(-727.773 -44.39)"
      >
        <path
          id="voz-ilimitada_svg__prefix__Caminho_230"
          d="M1024.166 307.306a5.6 5.6 0 00-3.856-1.958 3.902 3.902 0 00-.387-.014c-.515-.021-4.543 0-6.178 5.383-1.278 4.207-4.066 4.129-4.379 4.106h-.209a3.843 3.843 0 113.214-6.191.9.9 0 001.2.206.9.9 0 00.228-1.308 5.647 5.647 0 10-4.722 9.1c.061 0 .144.005.206 0h.149c.735 0 4.481-.26 6.039-5.387 1.276-4.2 4.066-4.129 4.38-4.106h.034c.1 0 .194 0 .291.011a3.843 3.843 0 11-3.329 6.184.9.9 0 00-1.2-.207.9.9 0 00-.229 1.307 5.644 5.644 0 108.749-7.125z"
          data-name="Caminho 230"
          transform="translate(-246.759 -233.239)"
          fill="#00a750"
        />
        <g
          id="voz-ilimitada_svg__prefix__Grupo_92"
          data-name="Grupo 92"
          transform="translate(744.949 45.874)"
        >
          <g id="voz-ilimitada_svg__prefix__Grupo_91" data-name="Grupo 91">
            <path
              id="voz-ilimitada_svg__prefix__Caminho_231"
              d="M906.194 73.486a15.215 15.215 0 00-15.24-15.315h-.076a1.031 1.031 0 00.005 2.061h.07a13.153 13.153 0 0113.179 13.244 1.031 1.031 0 102.061.01z"
              className="voz-ilimitada_svg__prefix__cls-2"
              data-name="Caminho 231"
              transform="translate(-889.853 -58.171)"
            />
            <path
              id="voz-ilimitada_svg__prefix__Caminho_233"
              d="M918.766 118.3a1.03 1.03 0 00.719 1.268 5.657 5.657 0 014.043 4.008l.02.069a1.036 1.036 0 00.987.738 1.031 1.031 0 00.988-1.323l-.019-.065a7.4 7.4 0 00-1.982-3.407 7.387 7.387 0 00-3.489-2.006 1.036 1.036 0 00-.277-.038 1.032 1.032 0 00-.992.756z"
              className="voz-ilimitada_svg__prefix__cls-2"
              data-name="Caminho 233"
              transform="translate(-915.619 -111.149)"
            />
          </g>
        </g>
        <path
          id="voz-ilimitada_svg__prefix__Caminho_234"
          d="M756 78.2a1.026 1.026 0 00-1.133-1.016 11.412 11.412 0 01-1.179.048h-.066a18.8 18.8 0 01-7.093-1.656c-1.455-.67-3.827-1.673-8.9-6.05l-.013-.013c-4.845-4.8-7.754-10.7-7.779-15.8a8.9 8.9 0 012.391-6.528l.027-.025a2.931 2.931 0 014.049.144c3.844 3.942 3.487 5.91 1.768 7.652l-2.611 2.6a1.017 1.017 0 00-.21 1.133 27.718 27.718 0 005.581 7.482l.185.183a28.023 28.023 0 007.567 5.519 1.046 1.046 0 001.145-.208l2.628-2.579c1.678-1.618 3.561-2.016 7.169 1.217a.977.977 0 001.05.163.952.952 0 00.272-1.56 12.119 12.119 0 00-4.344-2.856 5.488 5.488 0 00-5.589 1.58l-2.115 2.08-.248-.138a28.733 28.733 0 01-6.08-4.652l-.185-.183a28.649 28.649 0 01-4.7-6.013l-.14-.246 2.089-2.082a5.351 5.351 0 001.6-5.532 12.59 12.59 0 00-3.353-4.969 5.019 5.019 0 00-6.91-.246l-.04.036-.04.037a10.86 10.86 0 00-3.022 8.006c.028 5.625 3.167 12.071 8.4 17.241l.013.013c5.321 4.669 7.95 5.671 9.41 6.408a1.059 1.059 0 00.115.059 1.129 1.129 0 00.2.083 20.422 20.422 0 007.7 1.74h.077a13.055 13.055 0 001.387-.055A1.025 1.025 0 00756 78.2z"
          className="voz-ilimitada_svg__prefix__cls-2"
          data-name="Caminho 234"
        />
      </g>
    </svg>
  )
}

export default SvgVozIlimitada

import axios from "axios"
import ax from './axios-instance'

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL_SCRIPT_SPREADSHEET,
})

const axiosPublicIpInstance = axios.create({
  baseURL: "https://api.ipify.org",
})

const Leads = {
  leadsBot: params =>
    axiosInstance.get(process.env.SPREADSHEET_LINK, { params }),
  getPublicIP: () => axiosPublicIpInstance.get("?format=json"),
  postLeadInSheet: params => axiosInstance.get(process.env.SPREADSHEET_LINK_LEADS_DAY, { params }),
  getStates: stateCode => ax.get(`${process.env.BASE_URL}/geographic/localities?stateCode=${stateCode}&from=0&size=1000`),
}

export default Leads

import * as React from "react"

function SvgSolucaoIntegrada(props) {
  return (
    <svg
      width={52}
      height={52}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M26.87.625a3.183 3.183 0 013.179 3.179v42.392a3.183 3.183 0 01-3.18 3.179H3.555a3.183 3.183 0 01-3.179-3.179V3.804A3.183 3.183 0 013.554.625zm1.059 42.392H2.493v3.18c0 .583.477 1.058 1.06 1.058H26.87a1.06 1.06 0 001.059-1.059v-3.179zm-10.598 1.06v2.12h-4.24v-2.12h4.24zM27.929 6.984H2.493v33.913H27.93V6.984zM11.063 22.683a.681.681 0 011.165.48v2.578h11.749c.378 0 .682.307.682.683v4.094a.681.681 0 01-.682.681H12.228v2.578a.682.682 0 01-1.165.483l-5.307-5.307a.683.683 0 010-.965zm-.2 2.129L7.204 28.47l3.659 3.66v-1.612c0-.378.306-.682.683-.682h11.748v-2.73H11.546a.682.682 0 01-.683-.682v-1.612zm7.546-12.13a.686.686 0 01.744.146l5.305 5.306a.683.683 0 010 .965l-5.305 5.307a.683.683 0 01-1.165-.482v-2.578H6.239a.682.682 0 01-.683-.681v-4.094c0-.377.307-.683.683-.683h11.749v-2.576a.68.68 0 01.421-.63zm.944 2.277v1.612a.683.683 0 01-.683.682H6.922v2.73H18.67c.377 0 .683.304.683.682v1.612l3.658-3.658-3.658-3.66zM26.87 2.743H3.554c-.584 0-1.06.477-1.06 1.06v1.062h25.435V3.804c0-.584-.475-1.06-1.06-1.06z"
          id="solucao-integrada_svg__a"
        />
      </defs>
      <g transform="translate(11)" fill="none" fillRule="evenodd">
        <mask id="solucao-integrada_svg__b" fill="#fff">
          <use xlinkHref="#solucao-integrada_svg__a" />
        </mask>
        <use fill="#000" xlinkHref="#solucao-integrada_svg__a" />
        <g mask="url(#solucao-integrada_svg__b)" fill="#000">
          <path d="M-11-1h52v52h-52z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgSolucaoIntegrada

import { isDevelopmentMode } from "../../../utils/utils"

export const VIDEOCONFERENCIA = 'Videoconferência';
export const WHATSAPP = 'WhatsApp';
export const MELIGUE = 'Me ligue';
export const BUBBLE_MESSAGES_USER_CLASS = `sc-AxheI ${isDevelopmentMode() ? 'fkcGjN' : 'inbydY'} rsc-ts-bubble`
export const prospectTypes = ["PROSPECT_CORP", "PROSPECT_MPE"]
export const CLASSNAME_TEXT_FEEDBACK_MODAL = '_hj-f5b2a1eb-9b07_widget_title'
export const ID_TEXT_FOOTER_FEEDBACK_MODAL = '_hj-f5b2a1eb-9b07_hotjar_branding'
export const themeBOT = {
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#0D9A47",
  headerFontColor: "#fff !important",
  headerFontSize: "16px",
  botBubbleColor: "#0D9A47",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
}

import React from 'react';
import { Icons } from './Icons';

// interface Props {
//   name: string;
//   width?: number | number[];
//   height?: number | number[];
//   className?: string;
//   title?: string;
//   customId?: string;
//   dataValue?: string;
// }

// TODO: Configurar PropTypes do Icon

export const Icon = ({ name, ...props }) => {
  const IconComponent = Icons[name];
  if (!IconComponent) {
    return null;
  }
  return <IconComponent {...props} />;
};

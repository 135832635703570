import * as React from "react"

function SvgMessage(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37.937}
      height={28.835}
      viewBox="0 0 37.937 28.835"
      {...props}
    >
      <path
        d="M29.982 16.827V3.671a3.675 3.675 0 00-3.671-3.67H3.671A3.675 3.675 0 000 3.671v13.156a3.675 3.675 0 003.671 3.674h3.3l.07 3.315A1.225 1.225 0 009 24.764l5.656-4.263h11.655a3.676 3.676 0 003.671-3.674zm-15.736 1.224a1.224 1.224 0 00-.737.247L9.433 21.37l-.045-2.122a1.224 1.224 0 00-1.223-1.2H3.671a1.225 1.225 0 01-1.224-1.224V3.671a1.225 1.225 0 011.224-1.224h22.64a1.225 1.225 0 011.224 1.224v13.156a1.225 1.225 0 01-1.224 1.224H14.246zm23.691-6.731v10.1a3.675 3.675 0 01-3.671 3.671h-3.3l-.066 2.551a1.224 1.224 0 01-1.223 1.193c-.488 0-.216.085-6.337-3.748h-5.595a1.224 1.224 0 010-2.448c6.491 0 6.188-.069 6.6.186l4.168 2.609.041-1.6a1.224 1.224 0 011.223-1.193h4.494a1.225 1.225 0 001.224-1.224v-10.1a1.225 1.225 0 00-1.224-1.224 1.224 1.224 0 110-2.448 3.676 3.676 0 013.666 3.675zM22.64 8.031a1.224 1.224 0 01-1.224 1.224H8.566a1.224 1.224 0 010-2.448h12.85a1.224 1.224 0 011.224 1.224zm-2.218 5.354a1.224 1.224 0 01-1.222 1.224h-8.416a1.224 1.224 0 110-2.448H19.2a1.224 1.224 0 011.222 1.224z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgMessage

import * as React from "react"

function SvgEasyAccess(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <style>{".easy-access_svg__prefix__cls-3{fill:#00a750}"}</style>
      </defs>
      <g
        id="easy-access_svg__prefix__Facil_acesso"
        data-name="Facil acesso"
        opacity={0.904}
      >
        <g id="easy-access_svg__prefix__Group_44" data-name="Group 44">
          <g id="easy-access_svg__prefix__Group_43" data-name="Group 43">
            <path
              id="easy-access_svg__prefix__Path_207"
              d="M32 0a15.954 15.954 0 00-15.669 19.219l-1.791.891a1 1 0 00-.544.731.981.981 0 00.281.862L15.572 23l-.984 1H13a1 1 0 00-1 1v1.584L10.584 28H9a1 1 0 00-1 1v1.584l-1.419 1.422H5a1 1 0 00-1 1v1.584l-3.7 3.7A.993.993 0 000 39v8a1 1 0 001 1h8a.993.993 0 00.7-.291l15.294-15.29 1.294 1.294a.99.99 0 001.594-.263l.891-1.791A15.995 15.995 0 1032 0zm-3.509 29.541a1.014 1.014 0 00-1.144.525l-.628 1.247-1.019-1.022a1 1 0 00-1.416 0L8.588 46H2v-6.578l3.7-3.7a.993.993 0 00.291-.7v-1h1a.993.993 0 00.7-.291l2-2a.993.993 0 00.291-.7V30h1a.993.993 0 00.7-.291l2-2a.993.993 0 00.291-.7v-1H15a.993.993 0 00.7-.291l2-2a1 1 0 000-1.416l-1.013-1.031 1.247-.628a1 1 0 00.525-1.144 13.99 13.99 0 1110.032 10.042z"
              data-name="Path 207"
              fill="#fff"
            />
            <path
              id="easy-access_svg__prefix__Path_208"
              d="M346.4 64a5 5 0 105 5 5.007 5.007 0 00-5-5zm0 8.005a3 3 0 113-3 3.012 3.012 0 01-3 3z"
              className="easy-access_svg__prefix__cls-3"
              data-name="Path 208"
              transform="translate(-309.411 -57.994)"
            />
            <path
              id="easy-access_svg__prefix__Path_209"
              d="M78.267 277.646l-14.021 14.011a1 1 0 00.7 1.708.972.972 0 00.7-.291l14.012-14.012a1 1 0 000-1.417.975.975 0 00-1.391.001z"
              className="easy-access_svg__prefix__cls-3"
              data-name="Path 209"
              transform="translate(-57.948 -251.371)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgEasyAccess

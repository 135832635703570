import * as React from "react"

function SvgFlexibilidade(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={46}
      height={36}
      viewBox="0 0 46 36"
      {...props}
    >
      <defs>
        <style>{".flexibilidade_svg__prefix__cls-1{fill:#041a29}"}</style>
      </defs>
      <g
        id="flexibilidade_svg__prefix__Grupo_83"
        data-name="Grupo 83"
        transform="translate(-4.81 -107.121)"
      >
        <path
          id="flexibilidade_svg__prefix__Caminho_223"
          d="M50.966 130.256a23.061 23.061 0 00-6.732-16.356 22.877 22.877 0 00-32.5 0A23.061 23.061 0 005 130.256v.469a.451.451 0 00.449.452h17.986l.105.06a8.2 8.2 0 002.9.98 2.663 2.663 0 01-.106-4.208 8.325 8.325 0 00-2.866 1.245l-.117.08H6.845l.032-.491a21.175 21.175 0 012.129-7.985l.216-.441 2.284 1.327a.92.92 0 00.916-1.6l-2.282-1.326.273-.409a21.274 21.274 0 015.8-5.842l.407-.275 1.317 2.3a.914.914 0 001.677-.222.921.921 0 00-.091-.7l-1.319-2.3.438-.218a20.819 20.819 0 017.932-2.142l.488-.032v2.658a.916.916 0 101.831 0v-2.656l.488.032a20.82 20.82 0 017.932 2.142l.438.218-1.319 2.3a.925.925 0 00.335 1.259.912.912 0 001.251-.337l1.317-2.3.407.275a21.272 21.272 0 015.8 5.842l.273.409-2.282 1.326a.923.923 0 00.221 1.688.905.905 0 00.695-.092l2.284-1.327.216.441a21.172 21.172 0 012.13 7.984l.032.492h-.49l-7.94-.013-5.46-.012-1.8-.006h-.506a.7.7 0 01-.485-.117 8.116 8.116 0 00-2.851-1.211 2.662 2.662 0 01-.022 4.218 7.929 7.929 0 002.843-.943l.112-.071h18a.451.451 0 00.449-.452z"
          className="flexibilidade_svg__prefix__cls-1"
          data-name="Caminho 223"
          transform="translate(-.173)"
        />
        <path
          id="flexibilidade_svg__prefix__Caminho_224"
          d="M50.569 418.273l-3.055-3.035a.821.821 0 00-1.165 1.158l1.646 1.635H7.626l1.646-1.631a.821.821 0 00-1.165-1.158l-3.055 3.035a.815.815 0 000 1.158l3.011 2.992a.821.821 0 001.165-1.158l-1.607-1.6H48l-1.607 1.6a.821.821 0 001.166 1.158l3.011-2.992a.816.816 0 00-.001-1.162z"
          className="flexibilidade_svg__prefix__cls-1"
          data-name="Caminho 224"
          transform="translate(0 -279.542)"
        />
        <path
          id="flexibilidade_svg__prefix__Caminho_225"
          d="M217.334 253.463l.1-2.6a4.432 4.432 0 00-1.055-1.9l-.235-.249 3.4-5.762a.91.91 0 10-1.567-.925l-3.4 5.762-.331-.085a4.443 4.443 0 00-5.388 3.163l.1 2.6a4.435 4.435 0 008.006.794 4.479 4.479 0 00.37-.798zm-1.936-.131a2.6 2.6 0 01-1.6 1.2 2.641 2.641 0 01-.658.084 2.616 2.616 0 112.258-1.286z"
          data-name="Caminho 225"
          transform="translate(-185.326 -122.035)"
          fill="#00a750"
        />
      </g>
    </svg>
  )
}

export default SvgFlexibilidade

import * as React from "react"

function SvgSucess(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={56}
      height={62}
      viewBox="0 0 56 62"
      {...props}
    >
      <g fill="none">
        <path
          fill="#424242"
          d="M55.53 41.292a1.25 1.25 0 01-1.03 1.958h-37A5.256 5.256 0 0112.25 38c0-.983.282-1.924.8-2.75H2.75V60a1.25 1.25 0 11-2.5 0V2a1.25 1.25 0 112.5 0v1.75H23.5c.691 0 1.25.559 1.25 1.25v3.75H54.5a1.252 1.252 0 011.03 1.958L45.017 26 55.53 41.292z"
        />
        <path fill="#FFF" d="M2.5 6h20v27h-20z" />
        <path
          fill="#018942"
          d="M17.5 41c-1.654 0-3-1.346-3-3s1.346-3 3-3h6a1 1 0 001-1V11h28.099l-9.923 14.434a1.002 1.002 0 000 1.133L52.599 41H17.5z"
        />
      </g>
    </svg>
  )
}

export default SvgSucess

import React, { Component } from "react"
import Modal from "react-modal"
import classnames from "classnames"
import styles from "./CustomModal.module.css"
import ModalHeader from "./components/ModalHeader"
import PropTypes from "prop-types"

class CustomModal extends Component {
  onRequestClose = e => {
    this.props.onClose(e)
  }

  renderHeader = () => {
    return <ModalHeader onClose={this.onRequestClose} />
  }

  render() {
    const {
      isOpen,
      className,
      modalLabel,
      children,
      onTop,
      innerClass,
      innerOverflow,
      smallShowModal,
      isOnCheckout,
      onBottom,
      overlayClassName,
      ...props
    } = this.props

    const modalcls = classnames(
      {
        [`br2 shadow-1 bn pa0 bg-white outline-0 ${styles.content} ${styles.onTop}`]: onTop,
        [`br2 shadow-1 bn pa0 bg-white outline-0 ${styles.content}`]: !onTop,
        [`br2 shadow-1 bn pa0 bg-white outline-0 ${styles.content} ${styles.onBottom}`]: onBottom,
        [styles.smallModaltext]: smallShowModal,
      },
      className
    )

    const innercls = classnames(
      innerClass,
      innerOverflow,
      styles.innerHeight,
      styles.innerContent,
      {
        [styles.smallModaltext]: smallShowModal,
        [styles.innerOverflow]: !innerOverflow,
        "flex flex-column flex-nowrap justify-stretch items-stretch db relative": isOnCheckout,
      }
    )

    return (
      <Modal
        overlayClassName={overlayClassName}
        bodyOpenClassName="ReactModal__Body--open"
        htmlOpenClassName="ReactModal__Html--open"
        isOpen={isOpen}
        onRequestClose={this.onRequestClose}
        className={modalcls}
        contentLabel={modalLabel}
        ariaHideApp={false}
        {...props}
      >
        {this.renderHeader()}
        <div className={innercls}>{children}</div>
      </Modal>
    )
  }
}

CustomModal.propTypes = {
  overlayClassName: PropTypes.string,
  closeOnClickOut: PropTypes.bool,
}

CustomModal.defaultProps = {
  closeOnClickOut: false,
}

export default CustomModal

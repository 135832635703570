import * as React from "react"

function SvgConfiabilidade(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={42}
      viewBox="0 0 37 42"
      {...props}
    >
      <defs>
        <style>
          {
            ".confiabilidade_svg__prefix__cls-1{fill:#041a29}.confiabilidade_svg__prefix__cls-2{fill:#00a750}"
          }
        </style>
      </defs>
      <g
        id="confiabilidade_svg__prefix__Grupo_82"
        data-name="Grupo 82"
        transform="translate(-554.593 -241.939)"
      >
        <path
          id="confiabilidade_svg__prefix__Caminho_215"
          d="M628.414 325.353a1.887 1.887 0 00-1.628-.935h-5.426a.8.8 0 00-.8.8v13.023a.8.8 0 00.8.8h5.426a1.889 1.889 0 001.621-.923l.568.148a17.8 17.8 0 005.542.776h5.836a5.328 5.328 0 003.261-6.769 12.929 12.929 0 012.423-5.427 2.431 2.431 0 00-2.428-2.428h-3.681a13.989 13.989 0 00.72-4.93 6.735 6.735 0 00-.039-.637 2.428 2.428 0 00-4.855.073v.543c-.001 3.639-5.377 5.375-7.34 5.886zm8.14-.935a.8.8 0 100 1.6h7.054a.827.827 0 010 1.655h-2.713a.8.8 0 100 1.6h1.628a.827.827 0 110 1.655h-2.713a.8.8 0 000 1.6h1.628a.827.827 0 110 1.655h-2.713a.8.8 0 100 1.6h1.628a.827.827 0 110 1.655h-5.836a16.358 16.358 0 01-5.138-.724l-.706-.183v-9.592c2.283-.57 8.682-2.606 8.682-7.475v-.543a.827.827 0 011.655 0c0 .024 0 .072.006.142a11.887 11.887 0 01-.8 5.352zm-9.483 1.886v10.853a.285.285 0 01-.285.285h-4.626V326.02h4.626a.285.285 0 01.285.28z"
          className="confiabilidade_svg__prefix__cls-1"
          data-name="Caminho 215"
          transform="translate(-59.077 -66.844)"
        />
        <path
          id="confiabilidade_svg__prefix__Caminho_216"
          d="M591.125 250.538l-17.673-8.516a.824.824 0 00-.719 0l-17.673 8.516a.824.824 0 00-.467.788c.568 10.672 2.121 17.556 5.035 22.32 2.971 4.858 7.222 7.511 13.119 10.218a.828.828 0 00.691 0c5.9-2.707 10.147-5.36 13.119-10.218 2.914-4.764 4.467-11.648 5.035-22.32a.824.824 0 00-.467-.788zm-1.215 1.249c-.575 10.073-2.046 16.554-4.764 21s-6.626 6.9-12.053 9.419c-5.427-2.518-9.339-4.983-12.053-9.419s-4.189-10.924-4.764-21l16.817-8.1z"
          className="confiabilidade_svg__prefix__cls-1"
          data-name="Caminho 216"
        />
        <path
          id="confiabilidade_svg__prefix__Caminho_217"
          d="M705.1 546.088a25.151 25.151 0 01-6.758 4.21 40.81 40.81 0 01-2.82-1.427.825.825 0 00-.8 1.441 46.552 46.552 0 003.281 1.645.825.825 0 00.689 0 27.437 27.437 0 007.538-4.661.825.825 0 00-1.124-1.208z"
          className="confiabilidade_svg__prefix__cls-2"
          data-name="Caminho 217"
          transform="translate(-125.243 -272.521)"
        />
        <path
          id="confiabilidade_svg__prefix__Caminho_218"
          d="M606.39 284.951l-13.757 6.635a.823.823 0 00-.466.787 93.56 93.56 0 00.317 4.25.825.825 0 001.642-.163 90.174 90.174 0 01-.281-3.627l12.9-6.223 1.853.894a.825.825 0 00.717-1.487l-2.211-1.066a.821.821 0 00-.714 0z"
          className="confiabilidade_svg__prefix__cls-2"
          data-name="Caminho 218"
          transform="translate(-33.698 -38.502)"
        />
      </g>
    </svg>
  )
}

export default SvgConfiabilidade

import * as React from "react"

function SvgProdutividadeRelacionamento(props) {
  return (
    <svg
      width={52}
      height={52}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M15.583 14.911c.824 0 1.49.668 1.49 1.492v13.663h4.223v-7.652a1.49 1.49 0 012.982 0v7.652h4.272V18.191a1.491 1.491 0 012.982 0v11.875h4.274v-8.843a1.49 1.49 0 112.982 0v8.843h4.472a1.491 1.491 0 010 2.982H2.365a1.491 1.491 0 010-2.982h4.472V23.01a1.49 1.49 0 112.982 0v7.056h4.274V16.403a1.49 1.49 0 011.49-1.492zM29.457.751l.145.004 8.086.577c.494-.052 1.001.117 1.33.54.209.268.287.584.29.901.003.06.028.117.023.179l-.596 7.751a1.49 1.49 0 01-1.486 1.375c-.039 0-.076 0-.117-.003a1.49 1.49 0 01-1.371-1.6l.341-4.435-10.757 8.407c-.542.423-1.3.421-1.84-.003L15.63 8.24l-7.61 7.3a1.486 1.486 0 01-2.108-.043 1.493 1.493 0 01.044-2.11l8.546-8.197a1.491 1.491 0 011.955-.095l7.975 6.283 9.383-7.334-4.423-.317a1.49 1.49 0 01-1.381-1.592 1.48 1.48 0 011.592-1.38z"
          id="produtividade-relacionamento_svg__a"
        />
      </defs>
      <g transform="translate(4)" fill="none" fillRule="evenodd">
        <mask id="produtividade-relacionamento_svg__b" fill="#fff">
          <use xlinkHref="#produtividade-relacionamento_svg__a" />
        </mask>
        <use fill="#1A1413" xlinkHref="#produtividade-relacionamento_svg__a" />
        <g mask="url(#produtividade-relacionamento_svg__b)" fill="#000">
          <path d="M-4-9h52v52H-4z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgProdutividadeRelacionamento

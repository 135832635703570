import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import './FloatLabel.css';

class FloatLabel extends Component {
  state = {
    type: this.props.type || 'text',
    value: '',
    focus: false,
  };

  componentDidMount() {
    const { value, type, maskFunction } = this.props;
    this.setState({
      value: maskFunction(value),
      type: type,
    });
  }

  shouldComponentUpdate(nextProps) {
    const { value, maskFunction } = this.props;
    if (nextProps.value !== value) {
      this.setState(() => ({
        value: maskFunction(nextProps.value),
      }));
    }
    return true;
  }

  handleBlur = e => {
    const { onBlur } = this.props;
    e.persist();
    return this.setState(
      {
        focus: false,
      },
      () => onBlur(e)
    );
  };

  handleChange = e => {
    const { maskFunction, onInput, onChange } = this.props;
    e.persist();
    const newValue = maskFunction(e.target.value);
    if (onChange) onChange(e);
    this.setState({
      value: newValue,
    });
    return onInput(e, newValue);
  };

  handleFocus = e => {
    const { onFocus } = this.props;
    e.persist();
    this.setState(
      {
        focus: true,
      },
      () => onFocus(e)
    );
  };

  render() {
    const {
      style,
      className,
      containerClass,
      labelInputClass,
      name,
      label,
      max,
      min,
      maxLength,
      minLength,
      isDisabled,
      isDivergentCep,
      isRequired,
      isReadOnly,
      isAutocomplete,
      hasError,
      errorMessage,
      suffix,
      inputClass,
      classError,
      hasErrorClass,
      marginBottom,
      autoFocus,
      noFor,
      onKeyPress,
      dataTip,
      dataFor,
      id,
      noBreakError,
      labelFontSize,
      labelColor,
    } = this.props;

    const { focus, value, type } = this.state;

    const mainClass = classname('float-label', className, marginBottom, {
      'has-error': hasError && !hasErrorClass,
      [hasErrorClass]: hasError && hasErrorClass,
      'is-divergent-cep': hasError ? isDivergentCep : '',
    });

    const inputContainerClass = classname('float-label-container b--boulder', containerClass, {
      'has-error': hasError,
      'is-disabled': isDisabled,
      'has-focus': focus || value.length,
    });

    const labelClass = classname(
      'float-label-placeholder flex-row-reverse',
      labelInputClass,
      labelFontSize,
      labelColor,
      {
        f16: !labelFontSize,
        'te-papa-green': !labelColor,
        'has-error': hasError,
        'is-disabled': isDisabled,
        'has-focus-custom': labelFontSize && (focus || value.length),
        'has-focus': !labelFontSize && (focus || value.length),
      }
    );

    const inputClassFull = classname('float-label-input f16', inputClass, {
      'is-disabled': isDisabled,
    });

    return (
      <div className={mainClass} style={style}>
        <div className={inputContainerClass}>
          <div className="float-label-input-container" data-tip={dataTip} data-for={dataFor}>
            {label && (
              <label htmlFor={noFor ? '' : id || name} className={labelClass}>
                {label}
                {isRequired && (
                  <abbr className="abbreviation-no-decoration" title="Campo obrigatório">
                    *
                  </abbr>
                )}
              </label>
            )}
            <input
              className={inputClassFull}
              name={name}
              id={id || name}
              type={type}
              autoFocus={autoFocus}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              value={value}
              disabled={isDisabled}
              required={isRequired}
              aria-required={isRequired}
              max={max}
              maxLength={maxLength}
              min={min}
              minLength={minLength}
              readOnly={isReadOnly}
              onKeyPress={onKeyPress}
              autoComplete={isAutocomplete ? 'on' : 'off'}
              data-cy={`float-label-${name}`}
            />
          </div>

          {suffix && <div className="float-label-suffix ph10">{suffix}</div>}
        </div>

        <div
          className={`float-label-error-container ${classError} ${hasError ? 'active' : ''} ${noBreakError ? 'nowrap' : 'ws-normal'
            }`}
        >
          {errorMessage}
        </div>
      </div>
    );
  }
}

export default FloatLabel;

FloatLabel.defaultProps = {
  errorMessage: 'Valor declarado é inválido.',
  hasValidation: false,
  isAutocomplete: false,
  isDisabled: false,
  isRequired: true,
  label: 'Float Label Input',
  maskFunction: text => text,
  name: 'float-label',
  onBlur: () => { },
  onFocus: () => { },
  onInput: () => { },
  type: 'text',
  value: '',
  marginBottom: 'mb16',
  classError: '',
  autoFocus: false,
  id: null,
  noBreakError: false,
  labelFontSize: null,
};

FloatLabel.propTypes = {
  className: PropTypes.string,
  containerClass: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  hasValidation: PropTypes.bool,
  isAutocomplete: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelInputClass: PropTypes.string,
  maskFunction: PropTypes.func,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'url', 'tel', 'search', 'password', 'email']).isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  noBreakError: PropTypes.bool,
  labelFontSize: PropTypes.string,
  onChange: PropTypes.func,
};

import axios from "axios"

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem("tokenPortal")
  return config
}, null)

axiosInstance.interceptors.response.use(null, error => {
  const {
    config,
    response: { status },
  } = error

  let token = window.localStorage.getItem("tokenPortal")

  if (status !== 401 && token) {
    // Rejeita a promise com o erro original
    return Promise.reject(error)
  }

  return axiosInstance.post("/ip-token").then(res => {
    token = `Bearer ${res.data.access_token}`
    window.localStorage.setItem("tokenPortal", token)
    return axiosInstance(config)
  })
})

export default axiosInstance

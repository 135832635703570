import * as React from "react"

function SvgElastiscidade(props) {
  return (
    <svg
      width={52}
      height={52}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M21.104 19.208v1.896h-3.791a.949.949 0 00-.942.838l-.006.11v20.854c0 .486.366.887.837.942l.11.006h11.375a.949.949 0 00.942-.837l.006-.11V22.051a.949.949 0 00-.837-.941l-.11-.007h-3.792v-1.896h3.791a2.845 2.845 0 012.84 2.677l.004.167v20.854a2.845 2.845 0 01-2.676 2.84l-.168.004H17.313a2.845 2.845 0 01-2.838-2.677l-.005-.167V22.052a2.845 2.845 0 012.676-2.839l.168-.005h3.791zm2.844 21.802v1.896h-1.896V41.01h1.896zM23 12.18l3.514 3.514-1.34 1.34-1.227-1.225v13.433l1.226-1.225 1.341 1.34L23 32.873l-3.514-3.514 1.34-1.34 1.226 1.224V15.809l-1.225 1.226-1.341-1.34L23 12.18zM16.359.25a10.42 10.42 0 019.293 5.712l.06.124.191-.112a7.526 7.526 0 013.426-.98l.306-.005a7.583 7.583 0 017.58 7.347l.004.236-.002.06.276-.03c.112-.01.224-.017.336-.022l.338-.007a7.583 7.583 0 01.236 15.163l-.236.004h-4.74v-1.896h4.74a5.687 5.687 0 10-1.225-11.242 7.417 7.417 0 01-.643 1.593l-.165.287-1.623-.98a5.62 5.62 0 00.648-1.566l.034-.16.024-.12a5.687 5.687 0 00-5.581-6.772c-1.17 0-2.283.357-3.22 1.005.123.438.215.883.278 1.334l.049.41-1.887.187a8.455 8.455 0 00-.281-1.48l-.167-.472a8.53 8.53 0 00-8.05-5.722 8.533 8.533 0 00-8.318 10.43 7.55 7.55 0 011.679.233l.342.096-.556 1.813A5.7 5.7 0 007.7 14.47l-.12.006-.185.015A5.685 5.685 0 007.62 25.84l.213.004h4.74v1.896h-4.74a7.582 7.582 0 01-1.76-14.956l.07-.016-.02-.094a10.4 10.4 0 01-.184-1.591l-.008-.404C5.93 4.919 10.6.25 16.359.25z"
          id="elastiscidade_svg__a"
        />
      </defs>
      <g transform="translate(3)" fill="none" fillRule="evenodd">
        <mask id="elastiscidade_svg__b" fill="#fff">
          <use xlinkHref="#elastiscidade_svg__a" />
        </mask>
        <use
          fill="#505050"
          fillRule="nonzero"
          xlinkHref="#elastiscidade_svg__a"
        />
        <g mask="url(#elastiscidade_svg__b)" fill="#000">
          <path d="M-3-3h52v52H-3z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgElastiscidade

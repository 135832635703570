import * as React from "react"

function SvgChipElectronics(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      {...props}
    >
      <g opacity={0.895}>
        <path
          d="M47.2 0H.8a.8.8 0 00-.8.8v46.4a.8.8 0 00.8.8h46.4a.8.8 0 00.8-.8V.8a.8.8 0 00-.8-.8zm-8 1.6h1.6V4h-1.6zm-6.4 0h1.6V4h-1.6zm-6.4 0H28V4h-1.6zm-6.4 0h1.6V4H20zm-6.4 0h1.6V4h-1.6zm-6.4 0h1.6V4H7.2zM1.6 7.2H4v1.6H1.6zm0 6.4H4v1.6H1.6zm0 6.4H4v1.6H1.6zm0 6.4H4V28H1.6zm0 6.4H4v1.6H1.6zm0 6.4H4v1.6H1.6zm7.2 7.2H7.2V44h1.6zm6.4 0h-1.6V44h1.6zm6.4 0H20V44h1.6zm6.4 0h-1.6V44H28zm6.4 0h-1.6V44h1.6zm6.4 0h-1.6V44h1.6zm5.6-5.6H44v-1.6h2.4zm0-6.4H44v-1.6h2.4zm0-6.4H44v-1.6h2.4zm0-6.4H44V20h2.4zm0-6.4H44v-1.6h2.4zm0-6.4H44V7.2h2.4zm0-3.2h-3.2a.8.8 0 00-.8.8v3.2a.8.8 0 00.8.8h3.2V12h-3.2a.8.8 0 00-.8.8V16a.8.8 0 00.8.8h3.2v1.6h-3.2a.8.8 0 00-.8.8v3.2a.8.8 0 00.8.8h3.2v1.6h-3.2a.8.8 0 00-.8.8v3.2a.8.8 0 00.8.8h3.2v1.6h-3.2a.8.8 0 00-.8.8v3.2a.8.8 0 00.8.8h3.2v1.6h-3.2a.8.8 0 00-.8.8v3.2a.8.8 0 00.8.8h3.2v4h-4v-3.2a.8.8 0 00-.8-.8h-3.2a.8.8 0 00-.8.8v3.2H36v-3.2a.8.8 0 00-.8-.8H32a.8.8 0 00-.8.8v3.2h-1.6v-3.2a.8.8 0 00-.8-.8h-3.2a.8.8 0 00-.8.8v3.2h-1.6v-3.2a.8.8 0 00-.8-.8h-3.2a.8.8 0 00-.8.8v3.2h-1.6v-3.2a.8.8 0 00-.8-.8h-3.2a.8.8 0 00-.8.8v3.2h-1.6v-3.2a.8.8 0 00-.8-.8H6.4a.8.8 0 00-.8.8v3.2h-4v-4h3.2a.8.8 0 00.8-.8v-3.2a.8.8 0 00-.8-.8H1.6V36h3.2a.8.8 0 00.8-.8V32a.8.8 0 00-.8-.8H1.6v-1.6h3.2a.8.8 0 00.8-.8v-3.2a.8.8 0 00-.8-.8H1.6v-1.6h3.2a.8.8 0 00.8-.8v-3.2a.8.8 0 00-.8-.8H1.6v-1.6h3.2a.8.8 0 00.8-.8v-3.2a.8.8 0 00-.8-.8H1.6v-1.6h3.2a.8.8 0 00.8-.8V6.4a.8.8 0 00-.8-.8H1.6v-4h4v3.2a.8.8 0 00.8.8h3.2a.8.8 0 00.8-.8V1.6H12v3.2a.8.8 0 00.8.8H16a.8.8 0 00.8-.8V1.6h1.6v3.2a.8.8 0 00.8.8h3.2a.8.8 0 00.8-.8V1.6h1.6v3.2a.8.8 0 00.8.8h3.2a.8.8 0 00.8-.8V1.6h1.6v3.2a.8.8 0 00.8.8h3.2a.8.8 0 00.8-.8V1.6h1.6v3.2a.8.8 0 00.8.8h3.2a.8.8 0 00.8-.8V1.6h4z"
          data-name="Path 234"
          fill="#fff"
        />
        <path
          d="M29.905 17.727H18.096a.37.37 0 00-.369.369v8.488a.37.37 0 00.369.369.738.738 0 110 1.476.37.37 0 00-.369.369v1.107a.37.37 0 00.369.369h11.809a.37.37 0 00.369-.369V18.096a.37.37 0 00-.369-.369zm-.369 11.809H18.465v-.415a1.477 1.477 0 000-2.86v-7.8h11.071z"
          data-name="Path 235"
          fill="#00a750"
        />
      </g>
    </svg>
  )
}

export default SvgChipElectronics

import * as React from "react"

function SvgSeguranca(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51}
      height={34}
      viewBox="0 0 51 34"
      {...props}
    >
      <g data-name="Grupo 101">
        <g data-name="Grupo 90">
          <path
            d="M48.753 16.583a8.078 8.078 0 00-5.453-2.42l-.8-.034-.087-.782a15.371 15.371 0 00-28.859-5.092l-.307.6-.676-.105a11.159 11.159 0 00-1.72-.135 10.638 10.638 0 100 21.272h18.875a.8.8 0 00.815-.722.779.779 0 00-.735-.824H10.853a9.09 9.09 0 110-18.177 9.437 9.437 0 012.623.372h.014a.664.664 0 00.074.018.8.8 0 00.757-.266l.011-.014.029-.039.015-.021.015-.025.007-.012.017-.029.047-.1a13.806 13.806 0 0117.8-7.551 13.451 13.451 0 018.665 12.491.77.77 0 00.058.3l.018.036.031.059.033.052.011.014.047.057.1.093.052.038.029.017.015.009.018.011.032.015.014.007.023.011.038.014.024.009.019.007.044.01.042.01a.8.8 0 00.224.009.828.828 0 00.087-.013 3.281 3.281 0 01.791-.053h.26a6.38 6.38 0 016.486 6.268 6.284 6.284 0 01-3 5.384.76.76 0 00-.358.648.781.781 0 00.786.775.8.8 0 00.42-.118 7.747 7.747 0 002.5-10.808 7.859 7.859 0 00-1.015-1.268z"
            data-name="Caminho 229"
            fill="#0a151e"
          />
        </g>
        <path
          d="M44.92 21.347l-6.264-3.018a.715.715 0 00-.627 0l-6.264 3.019a.717.717 0 00-.408.687c.205 3.858.775 6.36 1.847 8.112a9.934 9.934 0 004.837 3.787.721.721 0 00.6 0 9.93 9.93 0 004.837-3.787c1.072-1.753 1.642-4.255 1.847-8.112a.717.717 0 00-.405-.688zm-1.06 1.088c-.211 3.335-.71 5.489-1.61 6.961a4.017 4.017 0 01-7.817 0c-.9-1.471-1.4-3.625-1.609-6.961l5.518-2.658z"
          data-name="Caminho 253"
          fill="#00a750"
        />
      </g>
    </svg>
  )
}

export default SvgSeguranca

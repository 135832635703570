import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Modal } from "antd"

import Form from "../LeadForm"
import "./styles.less"
import { Icon } from "components/Icons"
import { dataForm, paramsForm } from "src/utils/data"

const ModalForm = ({ openModal, closeModal }) => {
  const maskStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.68",
    backdropFilter: "blur(10px)",
  }
  let initialValues = paramsForm

  useEffect(() => {
    document
      .getElementsByClassName("ant-modal-close")[0]
      .setAttribute("aria-label", "Fechar");
  }, [])

  const CloseIcon = () => {
    return (
      <div className="close-icon" tabIndex="0">
        <span tabIndex="-1">
          <Icon name="Close" />
        </span>
      </div>
    )
  }

  return (
    <>
      <Modal
        visible={openModal}
        onCancel={closeModal}
        footer={null}
        closeIcon={<CloseIcon />}
        wrapClassName="custom-modal"
        className="customer-modal-mask"
        maskStyle={maskStyle}
        centered
      >
        <Form
          title={dataForm.title}
          textButton={dataForm.textButton}
          {...initialValues}
          isFormModal
          closeModal={closeModal}
          openModal={openModal}
        />
      </Modal>
    </>
  )
}
ModalForm.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
}
export default ModalForm

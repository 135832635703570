import * as React from "react"

function SvgConeEficiencia(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={80}
      height={72}
      viewBox="0 0 80 72"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0-4h80v80H0z" />
        <path
          d="M.962 71.668h3.333v-3.334H.962zm75 0h3.333v-3.334h-3.333zm-25-38.334v35.001h-3.335V48.334H30.961v20.001h-3.332V56.667H10.962v11.668H7.63v3.332h65v-3.332h-5V33.334H50.962zm3.334 35.001h9.998V36.667h-9.998v31.668zm-20.002 0h10V51.67h-10v16.666zm-20-.001h10V60h-10v8.334zM46.197 0l5.385 5.482L34.29 22.805 23.96 12.49 0 36.455l9.834 9.916 13.185-13.184 10.326 10.329 28.422-28.132 5.862 5.46V0H46.197zm10.075 5.5l-2.128-2.166h10.151v9.85l-2.596-2.42-28.34 28.048-10.34-10.338L9.844 41.647l-5.14-5.181L23.96 17.2l10.334 10.316L56.272 5.5z"
          fill="#1E333B"
        />
      </g>
    </svg>
  )
}

export default SvgConeEficiencia

import * as React from "react"

function SvgAdequacaoLgpd(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="adequacao-lgpd_svg__a"
          d="M25 25c3.43 0 6.139 2.708 6.139 6.139l-.001 4.152h.904c1.083 0 1.986.903 1.986 1.987v10.11c.18 1.084-.722 1.987-1.986 1.987H17.778a2.003 2.003 0 01-1.986-1.986V37.278c0-1.084.902-1.986 1.986-1.986l1.083-.001v-4.152C18.861 27.709 21.57 25 25 25zm7.222 12.278H17.958v10.11h14.264v-10.11zM49.375.625v42.611H35.111V41.25H47.39l-.001-32.501H2.611V41.25H14.89v1.986H.625V.625h48.75zM25 27.167c-2.167 0-3.972 1.805-3.972 3.972v3.972h7.944V31.14c0-2.167-1.805-3.972-3.972-3.972zm-7.042-15.348v12.278H5.681V11.82h12.277zm-2.166 1.987H7.667v8.125h8.125v-8.125zm20.402 6.138v1.987H21.931v-1.987h14.263zm7.042-3.972v1.986H21.931v-1.986h21.305zm0-4.153v1.987H21.931v-1.987h21.305zm4.153-9.208H2.61v4.152h44.777V2.611zM6.583 3.694v1.987H4.597V3.694h1.986zm4.153 0v1.987H8.75V3.694h1.986zm4.153 0v1.987h-1.986V3.694h1.986z"
        />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="adequacao-lgpd_svg__b" fill="#fff">
          <use xlinkHref="#adequacao-lgpd_svg__a" />
        </mask>
        <use
          fill="#1E333B"
          fillRule="nonzero"
          xlinkHref="#adequacao-lgpd_svg__a"
        />
        <g fill="#000" mask="url(#adequacao-lgpd_svg__b)">
          <path d="M-1-1h52v52H-1z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgAdequacaoLgpd

import React from "react"
import { Icon } from "../../../Icons"
import styles from "../../CustomModal.module.css"

const ModalHeader = ({ onClose }) => {
  return (
    <div>
      <div className={`absolute z-5 ${styles.closeButtonPositon}`}>
        <button
          aria-label="Sair"
          onClick={onClose}
          className="bg-transparent bn pointer flex items-center"
        >
          <Icon className={styles.icon} name="CloseModal" />
        </button>
      </div>
    </div>
  )
}

export default ModalHeader

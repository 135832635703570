import React from "react"
import classname from "classnames"

import { Button } from "antd"

const CustomButton = ({ color, text, className, to, gtmClass, ...rest }) => {
  const btnClasses = classname(`btn-${color}`, className, gtmClass)
  const dataValue = rest["data-value"]
  const dataTrackingId = rest["data-tracking-id"]
  const chatData = rest["chatdata"]
  const spanClasses = classname("f16 fw6 lh-title color-inherit", gtmClass)

  // const onClick = () => to && navigate(to)
  return (
    <Button
      className={btnClasses}
      type="primary"
      size="large"
      // onClick={onClick}
      {...rest}
    >
      <span
        className={spanClasses}
        data-value={dataValue}
        data-tracking-id={dataTrackingId}
        chatdata={chatData}
        tabIndex={-1}
      >
        {text}
      </span>
    </Button>
  )
}

export default CustomButton

import * as React from "react"

function SvgAcessoRemoto(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={42}
      viewBox="0 0 25 42"
      {...props}
    >
      <defs>
        <style>{".acesso-remoto_svg__prefix__cls-2{fill:#00a750}"}</style>
      </defs>
      <g
        id="acesso-remoto_svg__prefix__D4WPbN_tif"
        transform="translate(-987.135 -483.94)"
      >
        <g
          id="acesso-remoto_svg__prefix__Grupo_100"
          data-name="Grupo 100"
          transform="translate(987.135 483.94)"
        >
          <g id="acesso-remoto_svg__prefix__Grupo_99" data-name="Grupo 99">
            <path
              id="acesso-remoto_svg__prefix__Caminho_250"
              d="M1014.824 556.967c.965-1.425 1.983-2.9 2.88-4.2l.9-1.309a3.3 3.3 0 00.65-1.811l.011-.433c.026-.974.053-1.98.007-2.967a3.762 3.762 0 00-2.911-3.616l-1.131-.33c-1.6-.467-3.252-.949-4.882-1.4a1.04 1.04 0 01-.839-1.13c.016-2.89.017-5.1 0-7.17a3.144 3.144 0 00-3.676-3.229 3.134 3.134 0 00-2.634 3.049q-.011 3.926 0 7.852v5.165l-.964-1.139c-.128-.151-.248-.3-.363-.433l-.046-.056c-.247-.3-.46-.554-.688-.8a2.765 2.765 0 00-2.794-.915 2.9 2.9 0 00-2.012 1.55 2.939 2.939 0 00.065 2.589q1.1 2.222 2.211 4.442l.024.048q1.546 3.1 3.086 6.205a2.254 2.254 0 01.228.945v.111c.017 1.017.034 2.069-.007 3.108a1.156 1.156 0 00.512 1.192h11.542a1.132 1.132 0 00.5-1.157 50.495 50.495 0 010-3.138 1.976 1.976 0 01.331-1.023zm-1.852 3.855h-9.514v-2.8h9.514zm.786-4.884a1.187 1.187 0 01-1.06.561c-2.816-.01-5.783-.01-8.828 0a1.071 1.071 0 01-1.044-.627 2598.731 2598.731 0 00-5.1-10.241 1.443 1.443 0 01.08-1.586 1.3 1.3 0 011.454-.525 2.114 2.114 0 01.92.619c.41.469.815.954 1.206 1.422.323.388.644.771.969 1.149a1.206 1.206 0 001.37.411 1.239 1.239 0 00.921-1.349q.007-2.177 0-4.355v-3.708q0-2.473.005-4.945a2.71 2.71 0 01.158-.968 1.669 1.669 0 013.206.646q.008 2.9.006 5.791v2.684c0 .651.16.865.768 1.041q3.533 1.023 7.069 2.042a2.372 2.372 0 011.951 2.587c0 .218 0 .436.006.653v.088c.009.629.018 1.28-.019 1.933a2.894 2.894 0 01-.442 1.4c-.73 1.1-1.481 2.189-2.207 3.244l-.042.061-1.346 1.972z"
              data-name="Caminho 250"
              transform="translate(-994.299 -520.281)"
              fill="#041a29"
            />
            <path
              id="acesso-remoto_svg__prefix__Caminho_251"
              d="M1011.246 495.784a11.319 11.319 0 00-.491-3.27 12.068 12.068 0 00-23.6 2.888l-.016.337c-.022.536.246.894.681.912.659.028.782-.552.8-.9a13.857 13.857 0 01.173-1.731 10.358 10.358 0 014.6-6.883 10.674 10.674 0 015.83-1.741 10.212 10.212 0 012.453.3 10.6 10.6 0 018.127 10.123c.008.311.113.833.727.833s.716-.545.716-.868z"
              className="acesso-remoto_svg__prefix__cls-2"
              data-name="Caminho 251"
              transform="translate(-987.135 -483.94)"
            />
            <path
              id="acesso-remoto_svg__prefix__Caminho_252"
              d="M1025.056 514.033c-.013-.106-.023-.213-.035-.319v-.028a7.468 7.468 0 00-.1-.743 7.464 7.464 0 00-3.274-4.843 7.6 7.6 0 00-4.145-1.223 8.008 8.008 0 00-1.7.185 7.551 7.551 0 00-5.849 7.151.885.885 0 00.241.655.717.717 0 00.539.192c.168-.006.682-.022.719-.978a5.93 5.93 0 012.505-4.621 6.082 6.082 0 019.624 4.784.87.87 0 00.242.631.712.712 0 00.537.179c.173-.005.694-.024.696-1.022z"
              className="acesso-remoto_svg__prefix__cls-2"
              data-name="Caminho 252"
              transform="translate(-1005.445 -502.347)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgAcessoRemoto

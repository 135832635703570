import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classname from "classnames"
import { Link } from "gatsby"
import { Row, Col } from "antd"

import { Icon } from "../../Icons"
import Form from "../../LeadForm"
import { dataHeader, paramsForm, dataForm } from "src/utils/data"

import styles from "./styles.module.css"

const Default = ({ isFormErrored, setFormErrored }) => {
  const logoClasses = classname("", styles.logo)
  const titleClass = classname(
    "ma0 color-inherit f24 f32-m f32-l fw7 lh-title mb16 mb24-m mb24-l  mt32-m mt32-l w-90-ns",
    styles.title
  )
  const descritpionClass = classname(
    "f16 normal lh-copy mb32-m mb32-l w-80 w-auto white w-80-ns",
    styles.description
  )
  let initialValues = paramsForm
  const [error, setError] = useState(isFormErrored)

  useEffect(() => {
    setFormErrored(error)
  }, [error])

  return (
    <div className="mc center white">
      <Row align="top" gutter={24}>
        <Col span={24} md={12} lg={14} xl={12} style={{ paddingTop: '64px' }}>
          <h1 tabIndex="-1" className={styles.containerLogo}>
            <Link
              to="https://www.algartelecom.com.br/empresas"
              target="_blank"
              aria-label="Algar Telecom Empresas"
              className="link-focus"
            >
              <Icon name="LogoAlgarWhite" className={logoClasses} tabIndex="-1" />
            </Link>
          </h1>
          <h2
            className={titleClass}
            dangerouslySetInnerHTML={{ __html: dataHeader.title }}
          />
          <p
            className={descritpionClass}
            dangerouslySetInnerHTML={{ __html: dataHeader.description }}
          />
        </Col>
        <Col
          xs={24}
          md={12}
          lg={10}
          xl={12}
          className="pt72-ns pb48 pb0-m pb0-l"
        >
          <Form
            title={dataForm.title}
            textButton={dataForm.textButton}
            setFormErrored={setError}
            {...initialValues}
          />
        </Col>
      </Row>
    </div>
  )
}

Default.propTypes = {
  isFormErrored: PropTypes.bool,
  setFormErrored: PropTypes.func,
}

export default Default
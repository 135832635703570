import * as React from "react"

function SvgNoSeuRitmo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={52}
      height={52}
      viewBox="0 0 52 52"
      {...props}
    >
      <defs>
        <path
          id="no-seu-ritmo_svg__a"
          d="M20.03.927c9.491 0 17.189 7.637 17.189 17.062 0 3.394-.998 6.556-2.718 9.213L45.08 37.78a2.842 2.842 0 01.184 3.818l-.184.203-1.111 1.111a2.844 2.844 0 01-3.818.185l-.203-.185-10.59-10.59a17.197 17.197 0 01-9.328 2.729c-8.207 0-15.208-5.751-16.835-13.608l-.086-.447 1.866-.335c1.287 7.183 7.613 12.494 15.055 12.494 8.448 0 15.293-6.792 15.293-15.167 0-8.374-6.845-15.166-15.293-15.166-7.294 0-13.514 5.1-14.972 12.065l-.083.429-1.866-.335C4.558 6.897 11.669.927 20.03.927zm10.883 30.27l10.375 10.376a.95.95 0 001.21.11l.13-.11 1.111-1.111c.33-.33.366-.84.11-1.21l-.11-.13-10.37-10.37a17.305 17.305 0 01-2.456 2.445zM20.03 5.667c6.873 0 12.45 5.515 12.45 12.322 0 6.809-5.577 12.323-12.45 12.323-5.567 0-10.401-3.647-11.945-8.834l-.112-.402 1.835-.478c1.19 4.566 5.377 7.819 10.222 7.819 5.83 0 10.554-4.671 10.554-10.428 0-5.755-4.724-10.427-10.554-10.427-4.708 0-8.793 3.07-10.114 7.434l-.11.389-1.834-.478c1.407-5.402 6.346-9.24 12.058-9.24zm-6.368 6.453l2.843 6.636 2.616-4.359 2.603 3.471.722-2.163 2.262 1.13.949-.947 2.307 1.153h.724v1.896h-1.172l-1.485-.742-.948.948-1.53-.765-1.174 3.523-3.084-4.112-3.071 5.122-2.846-6.637-2.28 3.803-3.423-1.141-7.425.001v-1.895h7.583l.3.048 2.122.707 3.407-5.677zm32.088 4.922v1.895h-7.583v-1.895h7.583z"
        />
      </defs>
      <g transform="translate(3)" fill="none" fillRule="evenodd">
        <mask id="no-seu-ritmo_svg__b" fill="#fff">
          <use xlinkHref="#no-seu-ritmo_svg__a" />
        </mask>
        <use
          fill="#505050"
          fillRule="nonzero"
          xlinkHref="#no-seu-ritmo_svg__a"
        />
        <g fill="#000" mask="url(#no-seu-ritmo_svg__b)">
          <path d="M-3-3h52v52H-3z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgNoSeuRitmo

import { maskCPFCNPJ, maskCellPhone, validateCPFCNPJ } from '../utils/utils'
import * as Yup from 'yup';

export const dataForm = {
  title: 'Fale com um consultor',
  textButton: 'Enviar',
}

export const dataHeader = {
  title: 'Um parceiro, múltiplas soluções <span>para melhorar seu atendimento e gerar mais negócios.</span>',
  description: 'Além de conexões ultrarrápidas e seguras de internet, temos soluções de backup em nuvem,  de atendimento por telefone, monitoramento de rede e muito mais para modernizar seu negócio.<br><br><b>Preencha o formulário e fale com um consultor.'
}
export const dataFooter = {
  title: 'Não encontrou o que procura?',
  description: 'Temos soluções completas de TI e Telecom para o seu negócio.',
  textButton: 'Conheça outras soluções',
  textLoggedArea: 'Acessar área Logada de cliente Algar Telecom',
  textSAC: 'SAC - Serviço de Atendimento ao Consumidor',
}
export const paramsForm = {
  initialValues: {
    name: '',
    email: '',
    cpfCnpj: '',
    phoneFix: '',
  },
  Preset: {
    name: {
      name: 'name',
      label: 'Nome',
    },
    email: {
      name: 'email',
      label: 'E-mail',
    },
    cpfCnpj: {
      name: 'cpfCnpj',
      label: 'CPF/CNPJ',
      maxLength: 18,
      maskFunction: maskCPFCNPJ,
    },
    phoneFix: {
      name: 'phoneFix',
      label: 'Telefone',
      maxLength: 16,
      maskFunction: maskCellPhone,
    },
  },
  fields: ['name', 'email', 'cpfCnpj', 'phoneFix'],
  validateSchema: () => {
    const config = {
      name: Yup.string()
        .required('Campo obrigatório!')
        .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/, 'Digite um nome válido!')
        .min(2, 'Nome inválido!'),
      email: Yup.string()
        .required('Campo obrigatório!')
        .email('Digite um e-mail válido'),
      cpfCnpj: Yup.string()
        .required('Campo obrigatório!')
        .test('cpfCnpj', 'CPF/CNPJ inválido', validateCPFCNPJ),
      phoneFix: Yup.string()
        .required('Campo obrigatório!')
        .min(13, 'telefone inválido!'),
    };
    return Yup.object().shape({
      ...config,
    });
  },
};
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import classname from 'classnames'
import './FloatSelect.css'

const FloatSelect = ({
  options, 
  classNamePrefix,
  isSearchable,
  name,
  id,
  placeholder,
  ariaRequired,
  noOptionsMessage,
  hasError,
  errorMessage,
  setSelectedValue,
  selectedValue,
  isDisabled,
  autoFocus,
}) => {
  const customStyles = {
    control: base => ({
      ...base,
      height: 44,
      minHeight: 44,
      borderRadius: 4,
      background: 'bg-white-70',
      border: `1px solid ${hasError ? '#d14605' : '#3a3a3a' }`,
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #018942',
      },
      textAlign: 'left'
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: '#000',
      backgroundColor: isSelected ? '#018942' : isFocused ? 'rgba(1, 137, 66, 0.3)' : 'white',
      textAlign: 'left'
    }),
    placeholder: styles => ({
      ...styles,
      color: hasError ? '#d14605' : '#1E333B',
      fontSize: 16,
      textAlign: 'left'
    })
  };

  const mainClass = classname('float-select mb16 db relative', {
    'has-error': hasError,
  });

  const handleState = (e) => {
    if (setSelectedValue) {
      setSelectedValue(e.value);
    };
  };

  return (
    <>
      <div className={mainClass}>
        <div>
          <Select 
            value={options.filter(option => option.value === selectedValue)}
            styles={customStyles}
            onChange={handleState}
            options={options} 
            classNamePrefix={classNamePrefix}
            isSearchable={isSearchable}
            name={name}
            id={id}
            placeholder={placeholder}
            aria-required={ariaRequired}
            noOptionsMessage={() => noOptionsMessage}
            isDisabled={isDisabled}
            autoFocus={autoFocus}
          />
        </div>

        <div
          className={`float-select-error-container f12 absolute fw5 lh-copy ${hasError ? 'active' : ''}`}
        >
          {errorMessage}
        </div>
      </div>
    </>
  )
}

export default FloatSelect;

FloatSelect.defaultProps = {
  classNamePrefix: 'select',
  isSearchable: true,
  ariaRequired: true,
  isDisabled: false,
  autoFocus: false,
};

FloatSelect.propTypes = {
  options: PropTypes.array,
  classNamePrefix: PropTypes.string,
  isSearchable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  ariaRequired: PropTypes.bool,
  noOptionsMessage: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  setSelectedValue: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  selectedValue: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};